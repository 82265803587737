import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

const ISO_8601_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

// todo: check on upgrade
@Injectable()
export class UTCNativeDateAdapter extends NativeDateAdapter {
  // override deserialize to create a date in the utc zone
  override deserialize(value: any): Date | null {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const match = ISO_8601_DATE_REGEX.exec(value);
      if (match) {
        const date = new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
        if (this.isValid(date)) {
          return date;
        }
      }
    }

    return super.deserialize(value);
  }
}
