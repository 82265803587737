import { Component, OnDestroy, OnInit } from "@angular/core";
import { ResponseData, ResponseUpdateItem } from "@sondermind/utilities/models-flows";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { startWith, takeUntil } from "rxjs";
import { IGeoLocationForm, IGeoLocation } from "../basic-form/model";
import { IntakeStepComponent } from "../intake-step.component";

export type FormLocation = {
  name: string;
  lat: number;
  lng: number;
  address: string;
}

interface GeoLocationForm {
  name: FormControl<string>;
  lat: FormControl<number>;
  lng: FormControl<number>;
  address: FormControl<string>;
}

@Component({
  selector: 'flows-intake-geolocation',
  templateUrl: './intake-geolocation.component.html',
  styleUrls: ['./intake-geolocation.component.scss']
})
export class IntakeGeoLocation extends IntakeStepComponent<IGeoLocation> implements OnInit, OnDestroy {
  formGroup: FormGroup<GeoLocationForm> = new FormGroup<GeoLocationForm>({
    name: new FormControl(null),
    lat: new FormControl(null, Validators.required),
    lng: new FormControl(null, Validators.required),
    address: new FormControl(null, Validators.required),
  });

  addressFormControl = new FormControl(null, Validators.required);
  placeholder: string = '';
  formItem: IGeoLocationForm;
  value: ResponseData;

  ngOnInit(): void {
    [this.formItem] = this.config.form;
    this.value = { ...this.data };
    this.initFormValues();
    this.subscribeToFormChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  subscribeToFormChanges(): void {
    this.addressFormControl.valueChanges.pipe(
      startWith(this.addressFormControl.value),
      takeUntil(this.destroyed$)
    ).subscribe((newAddress) => {
      // if current value and saved form address don't match, clear out value from manual address change
      const isManualAddressChange = this.formGroup.valid && newAddress !== this.formGroup.value?.address
      if (isManualAddressChange) {
        this.formGroup.reset();
        this.valid.next(false);
      }
      else {
        this.valid.next(this.formGroup.valid);
      }
    });
  }

  initFormValues(): void {
    const location: FormLocation = this.value[this.formItem?.name];
    if (!location) return;

    this.formGroup.patchValue({ ...location });
    this.addressFormControl.patchValue(location.address);
  }

  locationSelected(selectedAddress: google.maps.places.PlaceResult): void {
    if (selectedAddress && selectedAddress.geometry && selectedAddress.formatted_address) {
      const location: FormLocation = {
        name: selectedAddress.name,
        lat: selectedAddress.geometry.location.lat(),
        lng: selectedAddress.geometry.location.lng(),
        address: selectedAddress.formatted_address
      }
      this.formGroup.patchValue({ ...location });
      this.addressFormControl.patchValue(location.address);
    }
  }

  onSubmit(): ResponseUpdateItem[] {
    return [
      {
        human: this.formItem.label || this.formItem.name,
        key: this.formItem.name,
        value: this.formGroup.value
      }
    ]
  }
}
