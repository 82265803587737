<div class="buttonContainer">
  <iris-button-container [alignment]="irisButtonContainerAlignments.END" [ngClass]="['container-from-' + stepName]">
    <iris-button
      *ngIf="exit"
      [ngClass]="['exitButton', 'exit-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="exitClicked.emit()"
    >
      Exit
    </iris-button>
    <iris-button
      *ngIf="showBackButton"
      [ngClass]="['backButton', 'back-from-' + stepName, confirmButtonGtmClass ?? '']"
      [type]="stepName === 'complete-pending' ? irisButtonTypes.SECONDARY : irisButtonTypes.PRIMARY"
      [fxHide]="resetArmed"
      [disabled]="!!busy"
      (click)="backClicked.emit()"
    >
      {{ backButtonName || "Back" }}
    </iris-button>
    <iris-button
      *ngIf="exitAndSave"
      [ngClass]="['exitButton', 'exit-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="exitAndSaveClicked.emit()"
    >
      {{ nextButtonName || "Exit" }}
    </iris-button>
    <iris-button
      *ngIf="showNextButton && !done"
      [ngClass]="['nextButton', 'next-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="nextClicked.emit()"
      data-test="next-submit-button"
    >
      {{ nextButtonName || "Next" }}
    </iris-button>
    <iris-button
      *ngIf="dialNumber"
      [ngClass]="['callButton', 'call-from-' + stepName]"
      [fxHide]="resetArmed"
      linkHref="tel:{{ dialNumber }}"
    >
      Call
    </iris-button>
    <iris-button
      *ngIf="done"
      [ngClass]="['completeButton', 'complete-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="nextClicked.emit()"
    >
      {{ done }}
    </iris-button>
  </iris-button-container>
</div>

<div class="buttonContainer resetContainer" *ngIf="!busy && resetArmed">
  <iris-button
    [ngClass]="['cancelButton', 'cancel-from-' + stepName]"
    [type]="irisButtonTypes.TERTIARY"
    (click)="disarm()"
  >
    Cancel
  </iris-button>
</div>
