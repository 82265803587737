<div class="sondermind-design-system therapy-reason-wrapper">
  <h3 *ngIf="maxAllowedInfoText" class="topics-limit-text">
    {{ maxAllowedInfoText }}
  </h3>
  <h3 class="therapy-reason-header" data-test="therapy-reason-header-text">GENERAL TOPICS</h3>
  <div class="therapy-reason-container">
    <div class="select-checkbox-wrapper-mobile" *ngIf="isMobile">
      <ng-container *ngFor="let option of generalTopicList">
        <sonder-option-checkbox
          [label]="option.label"
          [ngClass]="{'disabled': limitReached && !option.selectValue}"
        >
          <mat-checkbox
            matInput
            color="primary"
            [(ngModel)]="option.selectValue"
            [name]="option.value"
            (ngModelChange)="handleChange(option.value, option.selectValue)"
            [disabled]="limitReached && !option.selectValue"
            [attr.data-test]="'select-general-topics-therapy-reasons-' + option.value"
          ></mat-checkbox>
        </sonder-option-checkbox>
      </ng-container>
    </div>
    <ng-container *ngIf="!isMobile">
      <div class="select-checkbox-wrapper-desktop">
        <ng-container *ngFor="let option of generalTopicList | slice:0:generalHalfLength">
          <sonder-option-checkbox
            [label]="option.label"
            [ngClass]="{'disabled': limitReached && !option.selectValue}"
          >
            <mat-checkbox
              matInput
              color="primary"
              [(ngModel)]="option.selectValue"
              [name]="option.value"
              (ngModelChange)="handleChange(option.value, option.selectValue)"
              [disabled]="limitReached && !option.selectValue"
              [attr.data-test]="'select-general-topics-therapy-reasons-' + option.value"
            ></mat-checkbox>
          </sonder-option-checkbox>
        </ng-container>
      </div>
      <div class="select-checkbox-wrapper-desktop">
        <ng-container *ngFor="let option of generalTopicList | slice:generalHalfLength">
          <sonder-option-checkbox
            [label]="option.label"
            [ngClass]="{'disabled': limitReached && !option.selectValue}"
          >
            <mat-checkbox
              matInput
              color="primary"
              [(ngModel)]="option.selectValue"
              [name]="option.value"
              (ngModelChange)="handleChange(option.value, option.selectValue)"
              [disabled]="limitReached && !option.selectValue"
              [attr.data-test]="'select-general-topics-therapy-reasons-' + option.value"
            ></mat-checkbox>
          </sonder-option-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="psychiatricTopicList?.length > 0">
    <hr *ngIf="!isMobile">
    <h3 class="therapy-reason-header">PSYCHIATRY</h3>
    <div class="therapy-reason-container">
      <div class="select-checkbox-wrapper-mobile">
        <ng-container *ngFor="let option of psychiatricTopicList">
          <sonder-option-checkbox
            [label]="option.label"
            [ngClass]="{'disabled': limitReached && !option.selectValue}"
          >
            <mat-checkbox
              matInput
              color="primary"
              [(ngModel)]="option.selectValue"
              [name]="option.value"
              (ngModelChange)="handleChange(option.value, option.selectValue)"
              [disabled]="limitReached && !option.selectValue"
              [attr.data-test]="'select-psychiatry-therapy-reasons-' + option.value"
            ></mat-checkbox>
          </sonder-option-checkbox>
          <p
            *ngIf="option.helperText?.length > 0 && option.selectValue"
            class="reason-helper-text"
          >
            {{ option.helperText }}
          </p>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <hr *ngIf="!isMobile">
  <h3 class="therapy-reason-header">MORE TOPICS</h3>
  <div class="therapy-reason-container">
    <div class="select-checkbox-wrapper-mobile" *ngIf="isMobile">
      <sonder-option-checkbox
        *ngFor="let option of moreTopicList"
        [label]="option.label"
        [ngClass]="{'disabled': limitReached && !option.selectValue}"
      >
        <mat-checkbox
          matInput
          color="primary"
          [(ngModel)]="option.selectValue"
          [name]="option.value"
          (ngModelChange)="handleChange(option.value, option.selectValue)"
          [disabled]="limitReached && !option.selectValue"
          [attr.data-test]="'select-more-topics-therapy-reasons-' + option.value"
        ></mat-checkbox>
      </sonder-option-checkbox>
    </div>
    <ng-container *ngIf="!isMobile">
      <div class="select-checkbox-wrapper-desktop">
        <sonder-option-checkbox
          *ngFor="let option of moreTopicList | slice:0:moreHalfLength"
          [label]="option.label"
          [ngClass]="{'disabled': limitReached && !option.selectValue}"
        >
          <mat-checkbox
            matInput
            color="primary"
            [(ngModel)]="option.selectValue"
            [name]="option.value"
            (ngModelChange)="handleChange(option.value, option.selectValue)"
            [disabled]="limitReached && !option.selectValue"
            [attr.data-test]="'select-more-topics-therapy-reasons-' + option.value"
          ></mat-checkbox>
        </sonder-option-checkbox>
      </div>
      <div class="select-checkbox-wrapper-desktop">
        <sonder-option-checkbox
          *ngFor="let option of moreTopicList | slice:moreHalfLength"
          [label]="option.label"
          [ngClass]="{'disabled': limitReached && !option.selectValue}"
        >
          <mat-checkbox
            matInput
            color="primary"
            [(ngModel)]="option.selectValue"
            [name]="option.value"
            (ngModelChange)="handleChange(option.value, option.selectValue)"
            [disabled]="limitReached && !option.selectValue"
            [attr.data-test]="'select-more-topics-therapy-reasons-' + option.value"
          ></mat-checkbox>
        </sonder-option-checkbox>
      </div>
    </ng-container>
  </div>
</div>
