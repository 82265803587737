<form class="decline-theme formContainer" sonderMediaqueryClass="mq">
<div class="intake-basic-form-radio-group" data-test="form-radio-group">
  <mat-radio-group
    matInput
    [(ngModel)]="selection"
    [ngModelOptions]="{standalone:true}"
    [required]="true"
    (ngModelChange)="handleChange()"
  >
    <ng-container *ngFor="let option of imageItems">
      <mat-radio-button
        color="primary"
        [value]="option.value"
        [attr.data-test]="'select-images-option' + (option.value)"
      >
        {{ option.label }}
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>
