/* eslint-disable max-len */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';

import { CookieService } from 'ngx-cookie-service';
import { TextMaskModule } from 'angular2-text-mask';

import { FormlibModule } from '@sondermind/formlib';
import { ButtonsModule } from '@sondermind/features/design/buttons';
import { JourneyModule } from '@sondermind/features/design/journey-header';
import { DirectiveModule } from '@sondermind/utilities/directives';
import { PipesModule } from '@sondermind/utilities/pipes';
import { MatchingUtilitiesModule } from '@sondermind/features/matching-utilities';

import {
  IrisButtonModule,
  IrisCheckboxModule,
  IrisDropdownModule,
  IrisFormFieldModule,
  IrisIconButtonModule,
  IrisIconModule,
  IrisTextAreaModule,
  IrisTextInputModule
} from '@sondermindorg/iris-design-system-angular';
import { INTAKE_COMPONENT_MAP } from './component.models';
import { FlowsIntakeHostProgressBarComponent } from './components/flows-intake-host/flows-intake-host-progress-bar/flows-intake-host-progress-bar.component'
import { IntakeHeroComponent } from './components/steps/help-and-hero/hero/intake-hero.component';
import { IntakeHelptextComponent } from './components/steps/help-and-hero/helptext/intake-helptext.component';
import { IntakeHeaderComponent } from './components/flows-intake-host/header/intake-header.component';
import { IntakeStepHostComponent } from './components/steps/intake-step-host.component';
import { IntakeStepButtonsComponent } from './components/steps/step-buttons/intake-step-buttons.component';
import { IntakeFooterComponent } from './components/flows-intake-host/footer/intake-footer.component';
import { IntakeSelectImagesComponent } from './components/steps/select-images/intake-select-images.component';
import { IntakeCompleteComponent } from './components/steps/complete/intake-complete.component';
import { IntakeResendComponent } from './components/steps/resend/intake-resend.component';
import { IntakePslResendComponent } from './components/steps/psl-resend/intake-psl-resend.component';
import { IntakeStaticContentComponent } from './components/steps/static-content/intake-static-content.component';
import { IntakePauseComponent } from './components/steps/pause/intake-pause.component';
import { IntakeStepNoticeComponent } from './components/steps/step-notice/intake-step-notice.component';
import { CostEstimateComponent } from './components/steps/cost-estimate/cost-estimate.component';
import { IndependentIntakeResendComponent } from './components/steps/resend/independent/independent-intake-resend.component';
import { EmbeddedIntakeResendComponent } from './components/steps/resend/embedded/embedded-intake-resend.component';

import { IntakeHelpAndHeroComponent } from './components/steps/help-and-hero/help-and-hero.component';
import { BirthDatePipe } from './components/steps/basic-form/pipes/birth-date.pipe';
import { ReadinessDescriptionComponent } from './components/steps/readiness-description/readiness-description.component';
import { ContemplationPageComponent } from './components/steps/contemplation-page/contemplation-page.component';
import { IntakeDropdown } from './components/steps/dropdown/intake-flow-dropdown.component';
import { IntakeGeoLocation } from './components/steps/geolocation/intake-geolocation.component';
import { IntakeChipsComponent } from './components/steps/intake-chips/intake-chips.component';
import { IntakeMedicationGuidanceComponent } from './components/steps/medication-guidance/intake-medication-guidance.component';
import { IntakeHeardFromComponent } from './components/steps/heard-from/heard-from.component';
import { MultiSectionCheckboxComponent } from './components/steps/multi-section-checkbox/multi-section-checkbox.component';
import { IntakeBasicFormComponent } from './components/steps/basic-form/intake-basic-form.component';
import { IntakeSplashScreenComponent } from './components/flows-intake-host/splash-screen/splash-screen.component';
import { IntakeCallComponent } from './components/flows-intake-host/call-component/call.component';

const INTAKE_STEP_TYPES = [
  ContemplationPageComponent,
  IntakeCompleteComponent,
  IntakeDropdown,
  IntakeGeoLocation,
  MultiSectionCheckboxComponent,
  IntakeBasicFormComponent,
  IntakeHeardFromComponent,
  IntakeMedicationGuidanceComponent,
  IntakePauseComponent,
  IntakePslResendComponent,
  IntakeResendComponent,
  IntakeSelectImagesComponent,
  IntakeStaticContentComponent,

  CostEstimateComponent,
  IndependentIntakeResendComponent,
  EmbeddedIntakeResendComponent,
  ReadinessDescriptionComponent
];

const INTAKE_STEP_VALUES = {
  BasicForm: IntakeBasicFormComponent,
  GeoLocation: IntakeGeoLocation,
  MedicationGuidance: IntakeMedicationGuidanceComponent,
  Contemplation: ContemplationPageComponent,
  Complete: IntakeCompleteComponent,
  CostEstimate: CostEstimateComponent,
  Dropdown: IntakeDropdown,
  Chips: IntakeChipsComponent,
  MultiSectionCheckbox: MultiSectionCheckboxComponent,
  HeardFrom: IntakeHeardFromComponent,
  Pause: IntakePauseComponent,
  PslResend: IntakePslResendComponent,
  ReadinessDescription: ReadinessDescriptionComponent,
  Resend: IntakeResendComponent,
  SelectImages: IntakeSelectImagesComponent,
  StaticContent: IntakeStaticContentComponent,
};

@NgModule({
  imports: [
    A11yModule,
    ButtonsModule,
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    FormlibModule,
    JourneyModule,
    MatDatepickerModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
    MatLegacyChipsModule,
    MatLegacyInputModule,
    MatLegacyProgressBarModule,
    MatLegacyRadioModule,
    MatLegacySelectModule,
    MatLegacySliderModule,
    MatLegacyTabsModule,
    MatchingUtilitiesModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    TextMaskModule,
    DirectiveModule,
    PipesModule,
    IrisButtonModule,
    IrisIconModule,
    IrisIconButtonModule,
    IrisDropdownModule,
    IrisFormFieldModule,
    IrisTextInputModule,
    IrisTextAreaModule,
    IrisCheckboxModule
  ],
  declarations: [
    IntakeHeroComponent,
    FlowsIntakeHostProgressBarComponent,
    IntakeChipsComponent,
    IntakeHelptextComponent,
    IntakeHelpAndHeroComponent,
    IntakeHeaderComponent,
    IntakeStepHostComponent,
    IntakeStepButtonsComponent,
    IntakeFooterComponent,
    ...INTAKE_STEP_TYPES,
    IntakeSplashScreenComponent,
    BirthDatePipe,
    IntakeStepNoticeComponent,
    IntakeCallComponent
  ],
  exports: [
    IntakeHeroComponent,
    FlowsIntakeHostProgressBarComponent,
    IntakeHelptextComponent,
    IntakeHelpAndHeroComponent,
    IntakeHeaderComponent,
    IntakeStepHostComponent,
    IntakeFooterComponent,
    IntakeStepNoticeComponent,
    IntakeSplashScreenComponent,
    IntakeCallComponent
  ],
  providers: [
    TitleCasePipe,
    BirthDatePipe
  ]
})
export class V8HostModule {
  static forRoot(): ModuleWithProviders<V8HostModule> {
    return {
      ngModule: V8HostModule,
      providers: [
        CookieService,
        { provide: INTAKE_COMPONENT_MAP, useValue: INTAKE_STEP_VALUES },
      ]
    };
  }
}
