<header
  sondermindMediaQueryAttribute
>
  <div class="header-container">
    <img
      fxHide
      fxShow.gt-sm
      [src]="urlBase + '/assets/sm-logos/logotype-lake.svg'"
      alt="SonderMind Logo Lake Color"
    />
    <img
      fxHide
      fxShow.lt-md
      [src]="urlBase + '/assets/sm-logos/logobug-lake.svg'"
      alt="SonderMind Logo Lake Color"
    />
  </div>

  <ng-container>
    <div
      class="header-text-container"
      *ngIf="data$ | async as data"
      data-test="header-text"
    >
      {{ data.component.config.heroText | bindTemplate: data.response.data }}
    </div>
  </ng-container>
  <div fxFlex fxFlex.lt-md="1 0 72px" fxFlexAlign.lt-md="start" class="header-right">&nbsp;</div>
</header>
