<div>
  <form
    *ngIf="options.length >= minOptionsForDropdown"
    [formGroup]="selectFormGroup"
  >
    <iris-dropdown
      data-test="intake-dropdown"
      [multiple]="isMulti"
      [options]="$any(options)"
      [placeholder]="placeholder"
      formControlName="select"
      [maxOptions]="config.maxSelections"
    >
    </iris-dropdown>

    <div
      *ngIf="hasMaxSelectionsError"
      class="dropdown-error-message"
      data-test="dropdown-error-message"
    >
      {{ config.maxSelectionsErrorMessage }}
    </div>
  </form>

  <div
    *ngIf="options.length < minOptionsForDropdown"
    class="checkbox-container"
  >
    <ng-container
      *ngFor="let option of options"
    >
      <mat-checkbox
        matInput
        class="checkbox-option"
        color="primary"
        [(ngModel)]="option.selectValue"
        (ngModelChange)="handleCheckboxChange(option.value, option.selectValue)"
        [name]="option.value"
      >
        {{ option.label }}
      </mat-checkbox>
    </ng-container>
  </div>
</div>
