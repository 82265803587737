import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';
import { BreakpointsService } from '@sondermind/formlib';

import { WizardService } from '../../../services/wizard.service';
import { StepComponent } from '../step.component';

export interface MultiSelectImageItem {
  label: string;
  value: string;
  src?: string;
  selectValue?: boolean;
  name?: string;
  width?: string;
}

export interface MultiSelectImageConfig extends StepItemConfig {
  name: string;
  cssClass: string;
  options: MultiSelectImageItem[];
}

/**
 * @deprecated use BasicForm multiSelectImages type instead
 */
@Component({
  selector: 'flows-multi-select-images',
  templateUrl: './multi-select-images.component.html',
  styleUrls: ['./multi-select-images.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
  ]
})
export class MultiSelectImagesComponent extends StepComponent<MultiSelectImageConfig> implements OnInit {
  selections: { [key: string]: boolean; } = {};
  imageItems: MultiSelectImageItem[];

  get isMobile$(): Observable<boolean> {
    return this.breakpointService.isMobile$;
  }

  constructor(
    private breakpointService: BreakpointsService,
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit() {
    this.initImageItems();
    this.initSelections();
  }

  get hasContext(): boolean {
    return !!this.wizard.context;
  }

  initImageItems(): void {
    this.imageItems = [];

    if (this.hasContext) {
      this.imageItems = this.imageItems.concat(this.wizard.context);
    }

    if (this.config.options) {
      this.imageItems = this.imageItems.concat(this.config.options);
    }
  }

  initSelections(): void {
    let options = [];

    if (this.hasContext) {
      options = options.concat(this.wizard.context);
    }

    if (this.config.options) {
      options = options.concat(this.config.options);
    }

    options.forEach((s) => {
      this.selections[s.value] = false;
    });

    if (this.response?.data && this.response?.data[this.config.name]) {
      this.response.data[this.config.name].forEach((s) => {
        this.selections[s] = true;
      });
    }

    this.imageItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.selectValue = this.selections[item.value];
    });

    this.valid.next(this.validate());
  }

  handleSelectionChange(item: MultiSelectImageItem): void {
    this.selections[item.value] = !this.selections[item.value];

    this.valid.next(this.validate());
  }

  validate(): boolean {
    const keys = Object.keys(this.selections);
    const values = keys.filter((k) => this.selections[k]);

    if (values.length === 0) {
      return false;
    }

    return true;
  }

  onSubmit(): ResponseUpdateItem[] {
    const values: string[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.selections) {
      if (this.selections[key] === true) {
        values.push(key);
      }
    }

    const updates: ResponseUpdateItem[] = [];

    updates.push({ human: this.config.name, key: this.config.name, value: values });

    return updates;
  }
}
