<p
  *ngIf="!!component.config.headerHelperText"
  class="header-helper-text iris-mb-02"
>
  {{component.config.headerHelperText}}
</p>
<h2
  *ngIf="!!component.config.heroText"
  class="hero-text-header"
  [ngClass]="{'no-bottom-margin': !component.config.helpText}"
  data-test="hero-header-text"
>
  {{ component.config.heroText | bindTemplate: response.data }}
</h2>
<p
  *ngIf="showOptionalTextSlugs.has(component.slug)"
  class="hero-text-optional"
  data-test="hero-header-text-optional"
>
  (optional)
</p>
