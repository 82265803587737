<span #scrollAnchor></span>
<div [ngClass]="component?.config?.styleClasses">
  <flows-intake-help-and-hero
    [component]="component"
    [response]="response"
  >
  </flows-intake-help-and-hero>
  <div class="flows-intake-step-host-container" data-test="flows-intake-step-host-container">
      <ng-template #container></ng-template>
  </div>
  <p *ngIf="(showError$ | async)" class="error-message">{{ errorMessage }}</p>
  <div class="intake-step-body-suffix">
    <flows-intake-step-notice
      *ngIf="component.config.stepNoticeText"
      [component]="component"
      [response]="response">
    </flows-intake-step-notice>
    <flows-intake-step-buttons
        *ngIf="showStepButtons"
        [back]="wizard.canBack()"
        [stepName]="component.slug"
        [exit]="component.config.exit"
        [exitAndSave]="component.config.saveExit"
        [done]="component.done"
        [dialNumber]="component.config.dialNumber"
        [nextButtonName]="component.config.nextButtonName"
        [showNextButton]="!!component.next && showChildFlowNextButton(component.next)"
        [confirmButtonGtmClass]="component.config.confirmButtonGtmClass"
        [showBackButton]="component.config.showBackButton"
        [backButtonName]="component.config.backButtonName"
        [busy]="busy"
        [disabled]="disabled"
        [reset]="resetable"
        (backClicked)="handleBack()"
        (nextClicked)="handleNext()"
        (exitClicked)="handleExit()"
        (exitAndSaveClicked)="handleExitAndSave()"
        (resetClicked)="handleReset()"
        data-test="button-parent-element"
    ></flows-intake-step-buttons>
  </div>
</div>
