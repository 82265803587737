import { Component, OnInit } from "@angular/core";
import { ResponseUpdateItem } from "@sondermind/utilities/models-flows";
import { FormBuilder, FormGroup } from "@angular/forms";
import { WizardService } from "@sondermind/flows-host";
import { IMultiSectionCheckboxConfig } from "../basic-form/model";
import { IntakeStepComponent } from "../intake-step.component";

@Component({
  selector: 'multi-section-checkbox',
  templateUrl: './multi-section-checkbox.component.html',
  styleUrls: ['./multi-section-checkbox.component.scss']
})
export class MultiSectionCheckboxComponent extends IntakeStepComponent<IMultiSectionCheckboxConfig> implements OnInit {
  multiSectionCheckboxForm: FormGroup = this.fb.group({});

  constructor(
    private fb: FormBuilder,
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initForm(): void {
    this.config.form.forEach((item) => {
      const sectionFormGroup = this.fb.group({});

      item.sections.forEach((section) => {
        section.options.forEach((option) => {
          const hasValue = (this.data[item.name] as string[] ?? []).includes(option.value);
          sectionFormGroup.addControl(option.value, this.fb.control(hasValue));
        });
      });
      this.multiSectionCheckboxForm.addControl(item.name, sectionFormGroup);
    });
  }

  onSubmit(): ResponseUpdateItem[] {
    return this.config.form.map((item) => {
      const formValues: Record<string, boolean> = this.multiSectionCheckboxForm.value[item.name];

      return {
        human: item.name,
        key: item.name,
        value: Object.keys(formValues).filter((key) => formValues[key])
      };
    });
  }
}
