/**
 * Provides a simple method for applying a styleable class
 * when the app is running in the native SonderMind app.
 *
 * **Usage:**
 *
 * [nativeAppClass] will add the 'in-native-app' class to
 * the element by default, which can be used for styling.
 * nativeAppStyling="example-class" will add the specified
 * string as a class.
 */

import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { NativeAppContextHelpers } from '../services/native-app-context.helpers';

@Directive({
  selector: '[nativeAppClass]'
})
export class NativeAppClassDirective implements OnInit {
  @Input() nativeAppClass: string = 'in-native-app';

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const isHostedInNativeApp = NativeAppContextHelpers.isHostedInNativeApp();

    if (isHostedInNativeApp) {
      this.renderer.addClass(this.hostElement.nativeElement, this.nativeAppClass);
    }
  }
}
