<mat-chip-list
  selectable
  class="mat-chip-list-stacked"
>
  <mat-chip
    class="chip"
    *ngFor="let option of options$ | async"
    (click)="handleSingleOptionClick(option)"
    [selected]="selectedOption === option"
    [attr.data-test]="'chip-option-' + (formItem.type === 'refdata-chipgroup' ? option.label : option.value)"
  >
    <span class="chip-text">{{ option.label }}</span>
  </mat-chip>
</mat-chip-list>
<div
  *ngIf="component.slug === 'paymentPref' && (options$ | async).length === 1"
  class="alert"
>
  <mat-icon>info</mat-icon>
  <div>
    <p><b>Check back soon for more payment options</b></p>
    <p>
      We're working to quickly expand payment options in your state, including to major insurance
      and Medicare and Medicare Advantage plans.
    </p>
  </div>
</div>
