import { PipeTransform, Pipe } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'birthDate'
})
export class BirthDatePipe implements PipeTransform {
  transform(item: string): string {
    // if item is longer than 'mm/dd/yyyy' we convert the string into a date and back into the correct format
    if (!item) return item;
    if (item?.length <= 10) return item;

    const parsedDate = Date.parse(item);
    // if invalid, return undefined to clear out the field
    if (Number.isNaN(parsedDate)) return undefined;

    return format(parsedDate, 'MM/dd/yyyy');
  }
}
