<form class="decline-theme" fxLayout="column" fxLayoutGap="32px">
  <div class="optionContainer" fxLayout="row wrap" >
    <sonder-option-disc class="optionDisc" *ngFor="let option of config.options" [label]="option.label | bindTemplate:response.data">
      <input type="checkbox" tabindex="0" [(ngModel)]="selections[option.value]" [name]="option.value" [value]="true" (ngModelChange)="handleSelectionChange(option)">
    </sonder-option-disc>
    <span class="spacer" *ngFor="let i of missing"></span>
  </div>
  <sonder-form-field *ngIf="config.other" [label]="config.other.label | bindTemplate:response.data">
    <input matInput type="text" [(ngModel)]="other" [name]="config.other.name" [placeholder]="config.other.placeholder" (ngModelChange)="handleOtherChange()">
  </sonder-form-field>
</form>
