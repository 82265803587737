import { Component, Input } from '@angular/core';
import { IconUsageTypes, IconColors } from '@sondermindorg/iris-design-system-angular';

@Component({
  selector: 'flows-intake-host-progress-bar',
  templateUrl: './flows-intake-host-progress-bar.component.html',
  styleUrls: ['./flows-intake-host-progress-bar.component.scss']
})
export class FlowsIntakeHostProgressBarComponent {
  @Input() progressBarValue: number = 0;

  iconUsageTypes = IconUsageTypes;
  iconColors = IconColors;
}
