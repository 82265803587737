import {
  Directive, ElementRef, Input, Renderer2, OnDestroy
} from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, pairwise, startWith } from 'rxjs/operators';
import { BreakpointsService } from '../services/breakpoints.service';

@Directive({
  selector: '[sonderMediaqueryClass]'
})
export class MediaqueryClassDirective implements OnDestroy {
  private prefix$ = new ReplaySubject<string>();
  private subscription = combineLatest([this.prefix$, this.breakpoints.suffixes$])
    .pipe(
      map(this.combineClassnames.bind(this)),
      startWith(null),
      pairwise(),
    )
    .subscribe(this.applyChanges.bind(this));

  @Input() set sonderMediaqueryClass(val: string) {
    this.prefix$.next(val);
  }

  constructor(
    private breakpoints: BreakpointsService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private combineClassnames([prefix, suffixes]: [string, string[]]) {
    return suffixes.map((s) => `${prefix}-${s}`);
  }

  private applyChanges([last, current]: [string[], string[]]) {
    if (last) {
      last.forEach((l) => this.renderer.removeClass(this.el.nativeElement, l));
    }

    if (current) {
      current.forEach((c) => this.renderer.addClass(this.el.nativeElement, c));
    }
  }
}
