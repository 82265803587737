import { Component, OnInit } from "@angular/core";
import { ResponseUpdateItem } from "@sondermind/utilities/models-flows";
import { IntakeStepComponent } from "../intake-step.component";
import { IMedicationGuidance } from "../basic-form/model";

@Component({
  selector: 'flows-intake-medication-guidance',
  templateUrl: './intake-medication-guidance.component.html',
  styleUrls: ['./intake-medication-guidance.component.scss']
})
export class IntakeMedicationGuidanceComponent extends IntakeStepComponent<IMedicationGuidance> {
  ngOnInit(): void {
    this.valid.next(true);
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
