import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { DirectiveModule } from '@sondermind/utilities/directives';
import { FlowsLaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';

import { MatIconModule } from '@angular/material/icon';

import {
  IrisButtonModule,
  IrisIconButtonModule,
  IrisIconModule
} from '@sondermindorg/iris-design-system-angular';

import { FlowsIntakeHostComponent } from './components/flows-intake-host/flows-intake-host.component';
import { V8HostModule } from './v8-host.module';

@NgModule({
  imports: [
    CommonModule,
    MatLegacyProgressSpinnerModule,
    V8HostModule.forRoot(),
    DirectiveModule,
    MatIconModule,
    IrisButtonModule,
    IrisIconButtonModule,
    IrisIconModule
  ],
  declarations: [
    FlowsIntakeHostComponent
  ],
  exports: [
    FlowsIntakeHostComponent
  ],
  providers: [
    // make sure we get appropriate service when flows-intake is embedded in client-portal
    {
      provide: LAUNCH_DARKLY_SERVICE, useClass: FlowsLaunchDarklyService
    }
  ]
})
export class FlowsIntakeModule { }
