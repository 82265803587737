import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  map, skip, take
} from 'rxjs/operators';

import {
  ReferralState,
  ClientReferral,
  IClientReferral,
  IClientReferralApiResponse,
  IClientReferralUTMData
} from '@sondermind/data-access/client-referral';
import { HttpService } from '@sondermind/http';
import {
  IAPIListResponse,
  IAPIListRequestOptions,
  IAPIFetchResponse
} from '@sondermind/utilities/models-http';
import { camelCaseTransformer } from '@sondermind/utilities/tools';
import { ConfigurationService } from '@sondermind/configuration';

const outstandingReferralPred = (r: IClientReferral): boolean => r.referralState === ReferralState.created
      || r.referralState === ReferralState.manualMatchRequired
      || r.referralState === ReferralState.processing
      || r.referralState === ReferralState.requiresRematch;

const activeReferralFilterPred = (r: IClientReferral): boolean => r.referralState === ReferralState.processing
      || r.referralState === ReferralState.paused
      || r.referralState === ReferralState.requiresRematch
      || r.referralState === ReferralState.scheduled;

const referralAvailability = (r: IClientReferral): boolean => r.referralState === ReferralState.created
      || r.referralState === ReferralState.manualMatchRequired
      || r.referralState === ReferralState.processing
      || r.referralState === ReferralState.requiresRematch
      || r.referralState === ReferralState.paused;
@Injectable({
  providedIn: 'root'
})
export class ClientReferralHttpService implements OnDestroy {
  private store = new BehaviorSubject<IClientReferral[]>([]);
  destroy$ = new EventEmitter<void>();
  store$ = this.store.asObservable();
  outstanding$ = this.store$.pipe(map((rs) => rs.find(outstandingReferralPred)));
  availabilityReferralData$ = this.store$.pipe(map((rs) => rs.find(referralAvailability)));
  activeReferral$ = this.store$.pipe(map((rs) => rs.find(activeReferralFilterPred)));
  isClientReferralLoading$ = new BehaviorSubject(true);

  mostRecentReferral$ = this.store$.pipe(
    map((rs) => {
      if (!rs) return null;
      if (rs.length === 0) return null;

      const sortedByDate = rs.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      return sortedByDate[0];
    })
  );

  mostRecentReferralPaused$ = this.mostRecentReferral$.pipe(
    map((val) => val?.referralState === ReferralState.paused));

  totalClientReferrals$ = this.store$.pipe(
    map((res) => res.length)
  );

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  refresh(): Observable<ClientReferral[]> {
    this.fetch().subscribe((crs) => {
      this.store.next(crs);
      this.isClientReferralLoading$.next(false);
    });
    return this.store$.pipe(skip(1), take(1));
  }

  fetch(opts: Partial<IAPIListRequestOptions> = {}): Observable<ClientReferral[]> {
    const params = this.http.generateListParams(opts);
    const url = `${this.configurationService.env.svcBase}/referrals/client_referrals`;

    return this.http
      .get<IAPIListResponse<IClientReferralApiResponse>>(url, params)
      .pipe(
        map((resp) => resp.data.map((t) => new ClientReferral(t))),
        this.http.handleHttpError(opts.error)
      );
  }

  getClientReferralUTMData$(opts: Partial<IAPIListRequestOptions> = {}): Observable<IClientReferralUTMData> {
    const url = `${this.configurationService.env.svcBase}/referrals/client_referrals/utm`;

    return this.http
      .get<IAPIFetchResponse<any>>(url)
      .pipe(
        map((resp) => camelCaseTransformer<IClientReferralUTMData>(resp?.data)),
        this.http.handleHttpError(opts.error)
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
