import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConfigurationService } from '@sondermind/configuration';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'flows-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() backClicked = new EventEmitter<void>();
  @Input() back: boolean;
  @Input() fullscreen: boolean;
  data$ = combineLatest([
    this.wizard.config$,
    this.wizard.step$,
    this.wizard.response$
  ]).pipe(
    filter((xs) => !xs.some((x) => x == null)),
    // though it's not immediately obvious, removing the unused `step` argument will break things downstream
    // so, I'm leaving this note, and adding eslint directive
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([config, step, response]) => ({ config, component: this.wizard.component, response }))
  );

  get urlBase(): string {
    return this.configurationService.env.urlBase;
  }

  constructor(
    public configurationService: ConfigurationService,
    public wizard: WizardService
  ) {}
}
