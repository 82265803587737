<div>
  <form [formGroup]="multiSelectFormGroup">
    <iris-form-field>
      <iris-dropdown
        data-test="intake-dropdown"
        [multiple]="isMulti"
        [options]="$any(options)"
        [placeholder]="placeholder"
        formControlName="multiSelect"
      >
      </iris-dropdown>
    </iris-form-field>
  </form>
</div>
