import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

import { CookieService } from 'ngx-cookie-service';
import { TextMaskModule } from 'angular2-text-mask';

import { FormlibModule } from '@sondermind/formlib';
import { PipesModule } from '@sondermind/utilities/pipes';
import { DirectiveModule } from '@sondermind/utilities/directives';
import { JourneyModule } from '@sondermind/features/design/journey-header';
import { ButtonsModule } from '@sondermind/features/design/buttons';
import { NativeAppInterfaceModule } from '@sondermind/native-app-interface';
import {
  IrisButtonModule, IrisDropdownModule, IrisFormFieldModule, IrisIconButtonModule, IrisIconModule
} from '@sondermindorg/iris-design-system-angular';

import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { COMPONENT_MAP } from './models/component-map.interface';
import { HeroComponent } from './components/host/hero/hero.component';
import { HelptextComponent } from './components/host/helptext/helptext.component';
import { HeaderComponent } from './components/host/header/header.component';
import { StepHostComponent } from './components/steps/step-host.component';
import { StepButtonsComponent } from './components/steps/step-buttons/step-buttons.component';
import { StepFooterComponent } from './components/steps/step-footer/step-footer.component';

import { BasicFormComponent, BasicFormComponents } from './components/steps/basic-form/basic-form.component';
import { RequiredFieldPipe } from './components/steps/basic-form/pipes/required-field.pipe';
import { CompleteComponent } from './components/steps/complete/complete.component';
import { ConfirmationComponent } from './components/steps/confirmation/confirmation.component';
import { ResendComponent } from './components/steps/resend/resend.component';
import { SelectFancyComponent } from './components/steps/select-fancy/select-fancy.component';
import { SelectPillsComponent } from './components/steps/select-pills/select-pills.component';
import { SelectImagesComponent } from './components/steps/select-images/select-images.component';
import { MultiSelectImagesComponent } from './components/steps/multi-select-images/multi-select-images.component';
import { SelectDayTimesComponent } from './components/steps/select-day-times/select-day-times.component';
import { SMSOptinComponent } from './components/steps/sms-optin/sms-optin.component';

import { StaticContentComponent } from './components/steps/static-content/static-content.component';
import { UserInputReviewComponent } from './components/steps/user-input-review/user-input-review.component';
import { MultiStepReviewComponent } from './components/steps/multi-step-review/multi-step-review.component';
import { PauseComponent } from './components/steps/pause/pause.component';
import { HostComponent } from './components/host/host.component';
import { WizardService } from './services/wizard.service';
import { FlowDropdown } from './components/steps/dropdown/flow-dropdown.component';

const STEP_TYPES = [
  BasicFormComponent,
  CompleteComponent,
  ConfirmationComponent,
  FlowDropdown,
  ResendComponent,
  SelectFancyComponent,
  SelectPillsComponent,
  SelectImagesComponent,
  MultiSelectImagesComponent,
  SelectDayTimesComponent,
  SMSOptinComponent,
  StaticContentComponent,
  UserInputReviewComponent,
  MultiStepReviewComponent,
  PauseComponent
];

const STEP_VALUES = {
  BasicForm: BasicFormComponent,
  Complete: CompleteComponent,
  Confirmation: ConfirmationComponent,
  Dropdown: FlowDropdown,
  Resend: ResendComponent,
  SelectFancy: SelectFancyComponent,
  SelectPills: SelectPillsComponent,
  SelectImages: SelectImagesComponent,
  MultiSelectImages: MultiSelectImagesComponent,
  SelectDayTimes: SelectDayTimesComponent,
  SMSOptin: SMSOptinComponent,
  StaticContent: StaticContentComponent,
  UserInputReview: UserInputReviewComponent,
  MultiStepReview: MultiStepReviewComponent,
  Pause: PauseComponent
};

@NgModule({
  imports: [
    A11yModule,
    ButtonsModule,
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    FormlibModule,
    JourneyModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
    MatLegacyChipsModule,
    MatLegacyInputModule,
    MatLegacyRadioModule,
    MatLegacyTabsModule,
    MatLegacySelectModule,
    MatLegacySliderModule,
    ReactiveFormsModule,
    TextMaskModule,
    DirectiveModule,
    PipesModule,
    IrisButtonModule,
    IrisDropdownModule,
    IrisFormFieldModule,
    IrisIconModule,
    IrisIconButtonModule,
    NativeAppInterfaceModule,
    MatLegacyProgressSpinnerModule
  ],
  declarations: [
    HeroComponent,
    HelptextComponent,
    HeaderComponent,
    HostComponent,
    StepHostComponent,
    StepButtonsComponent,
    StepFooterComponent,
    ...STEP_TYPES,
    ...BasicFormComponents,
    RequiredFieldPipe
  ],
  exports: [
    HeroComponent,
    HelptextComponent,
    HeaderComponent,
    HostComponent,
    StepHostComponent
  ],
  providers: [
    TitleCasePipe,
  ]
})
export class FlowsHostModule {
  static forRoot(): ModuleWithProviders<FlowsHostModule> {
    return {
      ngModule: FlowsHostModule,
      providers: [
        CookieService,
        { provide: COMPONENT_MAP, useValue: STEP_VALUES },
        WizardService
      ]
    };
  }
}
