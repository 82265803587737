import {
  AfterViewInit, Component, Inject, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { MatLegacyChip } from '@angular/material/legacy-chips';

import {
  Observable,
  takeUntil,
  map,
  shareReplay
} from 'rxjs';

// eslint-disable-next-line import/no-extraneous-dependencies
import { conformToMask } from 'text-mask-core';
import { equals } from 'ramda';

import { IBasicFormConfig, IBasicFormItem } from '@sondermind/utilities/models-flows';
import { BreakpointsService } from '@sondermind/formlib';
import { GtmDataLayerService } from '@sondermind/google-tag-manager';
import { TextMasks, SharedMasks } from '@sondermind/utilities/text-masks';
import { FlowsLaunchDarklyFeatureFlags, LAUNCH_DARKLY_SERVICE, ILaunchDarklyService } from '@sondermind/launch-darkly';
import { ReferenceDataHttpService } from '@sondermind/data-access/reference-data';

import { IconColors, IconSizes, IconUsageTypes } from '@sondermindorg/iris-design-system-angular';
import { MultiSelectImageItem } from '../multi-select-images/multi-select-images.component';
import { SelectImageItem } from '../select-images/select-images.component';
import { WizardService } from '../../../services/wizard.service';
import {
  PHONE_FIELD_OPTS, StepComponent, ZIP_FIELD_OPTS, EMAIL_FIELD_OPTS, BIRTHDAY_FIELD_OPTS
} from '../step.component';
import { NPSScoreComponent } from './components/nps-score/nps-score.component';
import { TherapyReasonComponent } from './components/therapy-reason/therapy-reason.component';
import {
  MultiSectionCheckboxesComponent
} from './components/multi-section-checkboxes/multi-section-checkboxes.component';

import { signalFlowTermination } from '../tb-communication';

@Component({
  selector: 'flows-basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.scss'],
})
export class BasicFormComponent extends StepComponent<IBasicFormConfig> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('form')
    form: NgForm;
  @ViewChild('selectField') selectField;
  value: { [key: string]: any; };
  dateToday = new Date();
  maxDate: string = `${this.dateToday.getFullYear()}-${((this.dateToday.getMonth() + 1) < 10 ? '0' : '') + (this.dateToday.getMonth() + 1)}-${(this.dateToday.getDate() < 10 ? '0' : '') + this.dateToday.getDate()}`;
  // eslint-disable-next-line no-mixed-operators
  dateStart = new Date(this.dateToday.getFullYear() - this.dateToday.getFullYear() % 24 - 24, 1, 1);
  hiddenItems: IBasicFormItem[] = [];
  mobileOptin: boolean = false;
  emailOptin: boolean = false;
  masks: TextMasks = SharedMasks;
  isMobileOrTablet$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  // Geo Address related variables
  location: any;

  imageItems: MultiSelectImageItem[];

  multiSelectSubTypes: string[] = ['selectBasic', 'selectFancy', 'checkbox', 'therapyReason', 'selectPills', 'multiSelectImages'];
  maxItemsAllowed: number;
  minItemsAllowed: number;
  limitReached: boolean = false;

  iconColors = IconColors;
  IconSizes = IconSizes;
  IconUsageTypes = IconUsageTypes;
  constructor(
    private titleCasePipe: TitleCasePipe,
    public refdataHttp: ReferenceDataHttpService,
    private breakpoint: BreakpointsService,
    private gtmDataLayerService: GtmDataLayerService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: ILaunchDarklyService,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  refdataObservables = {};

  ngOnInit() {
    this.isMobile$ = this.breakpoint.isMobile$;
    this.isMobileOrTablet$ = this.breakpoint.isMobileOrTablet$;
    this.value = { ...this.data };
    // have to preprocess phone numbers to get validation passing
    this.config.form.forEach((item, index) => {
      // In case we have options received from the server
      // Combine config options + server based options
      if (this.hasContextBasedOptions) {
        // Mark all server based options as serverSide: true
        const contextOptions = this.wizard.context.options.map((opt) => {
          // eslint-disable-next-line no-param-reassign
          opt['serverSide'] = true;
          return opt;
        });

        // Server side options remain in the list even user go back and update the data
        // So just to avoid this case we did filter around config based options
        if (item.options) {
          const fullList = item.options.filter((opt) => !opt.serverSide).concat(contextOptions);
          const uniqueList = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const option of fullList) {
            if (!uniqueList.some((opt) => opt?.value === option.value && opt?.label === option.label)) {
              uniqueList.push(option);
            }
          }

          // eslint-disable-next-line no-param-reassign
          item.options = uniqueList;
        }
      }

      if (this.wizard.context?.itemsToHide?.includes(item.name)) {
        // eslint-disable-next-line no-param-reassign
        item['hiddenUntil'] = { name: 'complete', value: ['other'] };
        this.hiddenItems.push(item);
      }

      if (item.type === 'selectBasic' && item.name === 'urgentQuestions' && item.options.length > 1) {
        item.options.pop();
      }

      if (item.type === 'smsOptin') {
        this.mobileOptin = true;
      }

      if (item.type === 'emailOptin') {
        this.emailOptin = true;
        this.value[item.name] = 'opt-in';
      }

      if ((item.type === 'phone' || item.type === 'smsOptin') && this.value[item.name] && this.value[item.name] !== '') {
        const res = conformToMask(this.value[item.name], this.phoneFieldOptions.mask, this.phoneFieldOptions);
        this.value[item.name] = res.conformedValue;
      }

      if ((item.type === 'geolocation' || item.type === 'geolocationStreetAddr') && this.value[item.name] && this.value[item.name] !== '') {
        this.initGeolocation(item);
      }

      if (item.type === 'multiSelectImages' || item.type === 'selectImages') {
        this.initImageItems(item.options);
      }

      if (item.type === 'multiSelectImages') {
        this.initImageSelections(item.name, item.options);
      }

      if (item.type === 'radiogroup' || item.type === 'complex-radiogroup') {
        // Mark selected only when selected option present in the list
        if (item.options.findIndex((opt) => opt?.value === this.value[item.name]) === -1) {
          this.value[item.name] = undefined;
        }
      }

      if (item.type === 'complexCheckbox') {
        const selections = {};

        // when used on comspref step, sms and phone are pre-selected by default.
        // As per PE-25251, 'ncc' should also be pre-selected
        item.options.forEach((option) => {
          // eslint-disable-next-line no-param-reassign
          option.selectValue = ['sms', 'phone', 'ncc'].includes(option.value);
          selections[option.value] = option.selectValue;
        });

        // Pre-select any options that have already been manually selected
        if (this.value[item.name] && this.value[item.name] !== '') {
          this.value[item.name].forEach((selectedOption) => {
            // Mark selected only when selected option present in the list
            if (item.options.some((option) => option?.value === selectedOption)) {
              selections[selectedOption] = true;
            }
          });
        }

        this.value[item.name] = selections;
      }

      if (item.type === 'multiSectionCheckboxes') {
        const selections = {};

        // preselect everything as false unless already manually selected
        item.sections.forEach((section) => {
          section.options.forEach((option) => {
            selections[option.value] = option.selectValue;
          });
        });

        if (this.value[item.name] && this.value[item.name] !== '') {
          this.value[item.name].forEach((s) => {
            // Mark selected only when selected option present in the list
            item.sections.forEach((section) => {
              if (section.options.some((opt) => opt?.value === s)) {
                selections[s] = true;
              }
            });
          });
        }

        this.value[item.name] = selections;
      }

      if (this.multiSelectSubTypes.includes(item.type)) {
        const selections = {};
        this.maxItemsAllowed = item.max;
        const minRequired = item.min ?? 1;
        this.minItemsAllowed = item.required ? minRequired : 0;

        // preselect everything as false unless already manually selected
        item.options.forEach((s) => {
          selections[s.value] = s.selectValue;
        });

        if (this.value[item.name] && this.value[item.name] !== '') {
          this.value[item.name].forEach((s) => {
            // Mark selected only when selected option present in the list
            if (item.options.some((opt) => opt?.value === s)) {
              selections[s] = true;
            }
          });
        }

        this.value[item.name] = selections;
      }

      if (this.component.slug === 'heardFrom') {
        const completedHeardFrom = this.value['referralSource'] || this.value['referralSourceText'];
        // eslint-disable-next-line no-param-reassign
        item.required = completedHeardFrom ? false : item.required;
      }

      // iterates to search for hidden property and if found, tracks items to be hidden
      if (item.hiddenUntil) {
        this.hiddenItems.push(item);
      }
    });

    // MER-138
    signalFlowTermination(this.wizard);
  }

  override ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * This will check either we have options received from the server or not
   */
  get hasContextBasedOptions(): boolean {
    return !!this.wizard?.context?.options;
  }

  get hasContext(): boolean {
    return !!this.wizard.context;
  }

  initImageItems(options: MultiSelectImageItem[] | SelectImageItem[]): void {
    this.imageItems = [];

    if (this.hasContext) {
      this.imageItems = this.imageItems.concat(this.wizard.context);
    }

    if (options) {
      this.imageItems = this.imageItems.concat(options);
    }
  }

  initImageSelections(name: string, configOptions: MultiSelectImageItem[]): void {
    let options = [];

    if (this.hasContext) {
      options = options.concat(this.wizard.context);
    }

    if (configOptions) {
      options = options.concat(configOptions);
    }

    options.forEach((s) => {
      this.value[s.value] = false;
    });

    if (this.response?.data && this.response?.data[name]) {
      this.response.data[name].forEach((s) => {
        this.value[s] = true;
      });
    }

    this.imageItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.selectValue = this.value[item.value];
    });
  }

  initGeolocation(item: IBasicFormItem): void {
    const location = this.value[item.name];
    if (location && location.name) {
      this.value[item.name] = location.address;
    }
    this.location = location;
  }

  refdataList(type, skipOptions: any) {
    // Options which we want to skip in form elements like radio and select
    // eslint-disable-next-line no-param-reassign
    skipOptions = (skipOptions || []).map((o) => o.toLowerCase());

    if (this.refdataObservables[type]) {
      return this.refdataObservables[type];
    }

    const obs = this.refdataHttp.list(type, { filter: [{ key: 'hidden', value: 'false' }], sort: [{ key: 'order_number', asc: true }] }).pipe(
      map((e) => e.data.filter((o) => !skipOptions.includes(o.title.toLowerCase()))),
      shareReplay(1)
    );

    // eslint-disable-next-line no-return-assign
    return this.refdataObservables[type] = obs;
  }

  locationSelected(selectedAddress: any) {
    if (selectedAddress && selectedAddress.geometry && selectedAddress.formatted_address) {
      this.location = {
        name: selectedAddress.name, lat: selectedAddress.geometry.location.lat(), lng: selectedAddress.geometry.location.lng(), address: selectedAddress.formatted_address
      };

      setTimeout(() => this.selectField && this.selectField.focus && this.selectField.focus(), 10);
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.form.controls) {
        this.form.controls[key].updateValueAndValidity();
      }
    }
  }

  invalidateFormForManualAddressChange(): void {
    this.location = null;

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in this.form.controls) {
      this.form.controls[key].updateValueAndValidity();
    }
  }

  ngAfterViewInit() {
    this.form.control.setValidators((c) => this.validate(c.value));

    this.form.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.valid.next(this.form.valid));

    // validate initially
    // BDB: This doesn't run, there are no form controls at this point
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in this.form.controls) {
      this.form.controls[key].updateValueAndValidity();
    }
    // BDB: The setTimeout is used here to avoid a race condition where this functionality
    //      was executed before the form controls exist.  Will test to see if this can
    //      be resolved by moving to reactive forms.
    setTimeout(() => {
      const conditionalDisables: { [key: string]: any; } = this.formConfig.filter((item: IBasicFormItem) => item.disableWhen);
      conditionalDisables.forEach((item) => this.disableWhen(item));
      this.form.valueChanges.subscribe((change: SimpleChanges) => {
        const effected = this.getFieldsAffectedByChange(change, conditionalDisables);
        effected.forEach((item) => this.disableWhen(item));
      });
    }, 150);
  }

  shouldShowItem(name: string) {
    // set conditions to render forms unless the trigger value is fired
    if (!this.form) {
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const hiddenItem = this.hiddenItems.find((hiddenItem) => hiddenItem.name === name);
    if (!hiddenItem) {
      return true;
    }

    // find form responsible for triggering value to render hidden item
    const referenceControl = this.form.controls[hiddenItem.hiddenUntil.name];
    if (hiddenItem.hiddenUntil.name === 'insuranceNotFound') {
      return referenceControl ? referenceControl.value : false;
    }

    if (!referenceControl) {
      return true;
    }

    // Check for the hidden value in the refControl or see if
    // the hidden value has been selected in a multi-select object array
    const hiddenItemValue = hiddenItem.hiddenUntil.value;
    return referenceControl.value === undefined ? false
      : hiddenItemValue.includes(referenceControl.value)
      || referenceControl.value[hiddenItemValue[0]];
  }

  getFieldsAffectedByChange(
    change: SimpleChanges,
    conditionalDisables: { [key: string]: any; }
  ): IBasicFormItem[] {
    // BDB: Filter out any empty string keys
    const changed = Object.keys(change).filter((key) => key !== '');
    let affected: IBasicFormItem[] = [];
    changed.forEach((changedFieldKey) => {
      const candidates = conditionalDisables.filter((candidate: IBasicFormItem) => Object.keys(candidate.disableWhen).includes(changedFieldKey));
      affected = affected.concat(candidates);
    });

    // Removes duplicates
    affected = [...new Set(affected)];
    return affected;
  }

  get formConfig() {
    return this.config.form;
  }

  get phoneFieldOptions() {
    return PHONE_FIELD_OPTS;
  }

  get emailFieldOptions() {
    return EMAIL_FIELD_OPTS;
  }

  get zipFieldOptions() {
    return ZIP_FIELD_OPTS;
  }

  get birthdayFieldOptions() {
    return BIRTHDAY_FIELD_OPTS;
  }

  flex(item: IBasicFormItem): string {
    return `1 1 calc(${item.width} - 32px)`;
  }

  placeholder(item: IBasicFormItem): string {
    switch (item.placeholder) {
      case true:
        return null;
      case false:
        return null;
      default:
        return item.placeholder;
    }
  }

  disableWhen(item: IBasicFormItem): void {
    if (item.disableWhen) {
      const disableCriteriaFieldNames: string[] = Object.keys(item.disableWhen);
      const formControl: AbstractControl = this.form.controls[item.name];
      if (!formControl) {
        return;
      }

      const shouldDisable: boolean = disableCriteriaFieldNames
        .some((fieldName: string) => this.requireDisable(item.disableWhen, fieldName));

      const args = { onlySelf: true, emitEvent: false };

      if (shouldDisable) {
        formControl.disable(args);
      } else {
        formControl.enable(args);
      }
    }
  }

  private requireDisable(disableCriteria: { [key: string]: any; }, disableWhenControlName: string): boolean {
    const disableWhenControlValue = this.form.controls[disableWhenControlName]
      ? this.form.controls[disableWhenControlName].value || ''
      : '';

    // BDB: This is the config for the field named in the `disableWhen` property
    const config = this.formConfig.find((obj) => obj.name === disableWhenControlName);

    let shouldDisable = false;

    // BDB: If we're disabling based on the selections in a SelectFancy control
    if (config.type === 'selectFancy') {
      const disableCriteriaControlNames = Object.keys(disableCriteria);
      shouldDisable = disableCriteriaControlNames.every((controlName) => this.valueMatchesDisableCondition(
        controlName,
        disableCriteria[controlName],
        disableWhenControlValue
      ));
    } else {
      shouldDisable = equals(disableWhenControlValue, disableCriteria[disableWhenControlName]);
    }
    return shouldDisable;
  }

  private valueMatchesDisableCondition(
    controlName: string,
    controlValue: any,
    disableCondition: any
  ): boolean {
    const disableWhenValue = disableCondition[this.titleCasePipe.transform(controlName)];
    return controlValue === disableWhenValue;
  }

  handleFancySelectionChange(item: IBasicFormItem, option: { label: string; value: string; }) {
    const selections = this.value[item.name];

    // unselect the others when it's a single select
    if (item.select !== 'multi') {
      item.options.forEach((opt) => {
        if (opt.value !== option.value) {
          selections[opt.value] = false;
        }
      });
    }
    this.value[item.name] = selections;
  }

  handlePillSelectionChange(item: IBasicFormItem, option: { label: string; value: string; }, chip: MatLegacyChip) {
    const selections = this.value[item.name];
    chip.toggleSelected();

    // unselect the others when it's a single select
    if (item.select !== 'multi') {
      item.options.forEach((opt) => {
        if (opt.value !== option.value) {
          selections[opt.value] = false;
        } else {
          selections[opt.value] = true;
        }
      });
    } else {
      selections[option.value] = true;
    }
    this.value[item.name] = selections;
  }

  handleMultiImageSelectionChange(item: IBasicFormItem, option: MultiSelectImageItem): void {
    const selections = this.value[item.name];
    selections[option.value] = option.selectValue;

    this.value[item.name] = selections;
  }

  validate(value: any) {
    // see if there's any values that are listed in 'invalid' for the control
    // eslint-disable-next-line array-callback-return, consistent-return
    const invalid = this.formConfig.some((field) => {
      if (field.type === 'geolocation' || field.type === 'geolocationStreetAddr') {
        return !(this.location && this.location.lat && this.location.lng);
      }

      if (field.type === 'birthdate') {
        const validateDate = new Date(value[field.name]);
        // eslint-disable-next-line no-restricted-globals
        return isNaN(validateDate.getTime());
      }

      // if buffer is true, we're inserting a 'buffer' before the min value
      if (field.type === 'slider' && field.buffer) {
        if (value[field.name] < field.min) {
          return true;
        }
      }

      if (field.type === 'selectPills' || field.type === 'multiSelectImages') {
        return !Object.values(value).includes(true);
      }

      if (this.multiSelectSubTypes.includes(field.type) && field.required) {
        const options = value[field.name];
        if (options) {
          const keys = Object.keys(options);
          const values = keys.filter((k) => options[k]);
          this.limitReached = values.length === this.maxItemsAllowed;

          if (values.length < this.minItemsAllowed) {
            return true;
          }
        }
      }

      if (field.invalid == null) {
        return false;
      }

      if (field.invalid.includes(value[field.name])) {
        return true;
      }
    });

    return invalid ? { invalid: true } : null;
  }

  removeStepValidation(stepName) {
    const formGroup = this.form.form;
    const step = formGroup.get(stepName);
    step.setErrors(null);
    step.clearValidators();
    formGroup.updateValueAndValidity();
  }

  onTextareaChange(event: Event) {
    const textAreaValue = (event.target as any).value;
    if (this.wizard.component.slug === 'reasonDetailOpenText') {
      const nextButtonText = textAreaValue.length > 0 ? 'Next' : 'Skip';
      this.wizard.component.config.nextButtonName = ` ${nextButtonText}  \n`;
    } else if (this.wizard.component.slug === 'heardFrom') {
      if (textAreaValue.trim() !== '') {
        this.removeStepValidation('referralSource');
      }
    }
  }

  onRadioSelect(element) {
    if (element.name === 'referralSource') {
      this.removeStepValidation('referralSourceText');
    }
  }

  isItemHidden(item): boolean {
    const foundItem = this.hiddenItems.find((hiddenItem) => hiddenItem.name === item.name);
    return Boolean(foundItem);
  }

  // pull values that map to the form configuration
  // - strip non-digits from phone numbers
  // - convert dates to strings
  onSubmit() {
    return this.formConfig.reduce((updates, item) => {
      let value = this.value[item.name];

      if (value) {
        if (item.type === 'radiogroup' || item.type === 'complex-radiogroup') {
          const chosenOptionGTM = item.options.find((opt) => opt?.value === value)?.gtm;
          if (chosenOptionGTM) {
            this.gtmDataLayerService.sendUserTherapyReadiness(chosenOptionGTM.type, chosenOptionGTM.label);
          }
        }

        if (item.type === 'phone' || item.type === 'smsOptin') {
          value = value.replace(/[^0-9]/g, '');
        }

        if (item.type === 'smsOptin') {
          return [
            ...updates,
            { human: item.label || item.name, key: item.name, value },
            { human: 'SMS Phone', key: item.name, value },
            { human: 'SMS Optin', key: 'contactSMSAllowed', value: this.mobileOptin },
          ];
        }

        if (item.type === 'emailOptin') {
          value = this.emailOptin ? 'opt-in' : 'opt-out';
          this.value[item.name] = value;
        }

        if (item.type === 'calendar' && value instanceof Date) {
          value = value.toISOString();
        }

        if (item.type === 'birthdate') {
          value = new Date(value);
        }

        if ((item.type === 'geolocation' || item.type === 'geolocationStreetAddr') && value) {
          value = this.location;
        }

        if (item.type === 'multiSectionCheckboxes' || item.type === 'complexCheckbox') {
          const values: string[] = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const key in value) {
            if (value[key] === true) {
              values.push(key);
            }
          }
          value = values;
        }

        if (this.multiSelectSubTypes.includes(item.type)) {
          const values: string[] = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const key in value) {
            if (value[key] === true) {
              values.push(key);
            }
          }
          value = values;
        }
      }

      return [...updates, { human: item.label || item.name, key: item.name, value }];
    }, []);
  }
}

export const BasicFormComponents = [
  NPSScoreComponent,
  TherapyReasonComponent,
  MultiSectionCheckboxesComponent
];
