import { Component, OnInit } from '@angular/core';

import { ConfigurationService } from '@sondermind/configuration';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { WizardService } from '@sondermind/flows-host';
import { IntakeStepComponent } from '../intake-step.component';

@Component({
  selector: 'intake-readiness-description',
  templateUrl: './readiness-description.component.html',
  styleUrls: ['./readiness-description.component.scss']
})
export class ReadinessDescriptionComponent extends IntakeStepComponent<StepItemConfig> implements OnInit {
  title: string = '';
  imageName: string = '';
  mainText: string = '';
  bodyHelpText: string = '';

  constructor(
    public configurationService: ConfigurationService,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.title = this.config.titleText;
    this.imageName = this.config.imagePath;
    this.mainText = this.config.mainText;
    this.bodyHelpText = this.config.bodyHelpText;

    this.valid.next(true);
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
