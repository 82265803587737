import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';

import { MultiSelectImageItem } from '@sondermind/flows-host';
import { IDropdownConfig, ResponseUpdateItem } from '@sondermind/utilities/models-flows';

import { StepComponent } from '../step.component';

@Component({
  selector: 'flows-intake-dropdown',
  templateUrl: './flow-dropdown.component.html',
  styleUrls: ['./flow-dropdown.component.scss']
})
export class FlowDropdown extends StepComponent<IDropdownConfig> implements OnInit, OnDestroy {
  options: MultiSelectImageItem[] = [];

  placeholder: string = '';
  isMulti: boolean = false;

  multiSelectFormGroup: FormGroup = new FormGroup({
    multiSelect: new FormControl([], Validators.required)
  });

  ngOnInit(): void {
    this.initOptions();

    this.isMulti = this.config.isMulti;
    this.placeholder = this.config.placeholder;

    this.multiSelectFormGroup.controls.multiSelect.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (newSelectValues: string[]) => {
        this.valid.next(newSelectValues.length > 0);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initOptions(): void {
    this.options = [
        ...this.wizard.context,
        ...this.config.additionalOptions || []
    ].map((x) => ({ ...x, name: x.label }));
  }

  onSubmit(): ResponseUpdateItem[] {
    return [{
      human: this.config.name,
      key: this.config.name,
      value: this.multiSelectFormGroup.controls.multiSelect.value
    }];
  }
}
