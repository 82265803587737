export enum BrowserButtonEvent {
  FORWARD = 'browser-forward-clicked',
  BACK = 'browser-back-clicked'
}

export enum NavigationDirection {
  NEXT = 'next',
  BACK = 'back'
}

export enum StateFieldNames {
  STEP_SLUG = 'stepSlug',
  /**
   * used as a unique identifier for a state object
   */
  TIMESTAMP = 'ts'
}
