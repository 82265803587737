import {
  Component, Input, OnInit
} from '@angular/core';
import {
  trigger, transition, group, animate, style, query, state,
} from '@angular/animations';
import {
  BehaviorSubject, Observable,
  take, tap, timer, interval, finalize, takeWhile
} from 'rxjs';

@Component({
  selector: 'matching-progress',
  templateUrl: './matching-progress.component.html',
  styleUrls: ['./matching-progress.component.scss'],
  animations: [
    trigger('progress', [
      state('true', style({
        opacity: 1
      })),
      state('false', style({
        opacity: 0
      })),
      transition('false <=> true', animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)'))
    ]),
    trigger('did-you-know-container', [
      transition(':enter', [
        style({
          transform: 'translateY(100%)'
        }),
        animate(500, style({
          transform: 'translateY(0)'
        }))
      ])
    ]),
    trigger('did-you-know', [
      transition(`:increment`, group([
        query(':enter', [
          style({
            transform: 'translateX(100%)'
          }),
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
        ]),
        query(':leave', [
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)', style({
            opacity: 0,
            transform: 'translateX(-100%)'
          }))
        ])
      ]))
    ])
  ]
})
export class MatchingProgressComponent implements OnInit {
  @Input() showDidYouKnow = true;
  @Input() svgMaxContainerHeight: number = 300;
  @Input() isDone: boolean = false;
  @Input() progressStatusHeaderClass: string = 'iris-h2-responsive';
  @Input() progressStatusSubHeaderClass: string = 'iris-body-responsive';

  isMobile$: Observable<boolean>;

  progressStatusIndex$ = new BehaviorSubject<number>(0);
  didYouKnowIndex$ = new BehaviorSubject<number>(-1);

  progressStatusItems = [
    { heading: 'Submitting your answers...' },
    { heading: 'Analyzing your preferences...' },
    {
      heading: 'Searching our therapists...',
      sub: 'Hold tight, this process can take up to a minute!'
    },
  ];

  didYouKnowBlurbs = [
    'Most people see results from therapy within 5 sessions.',
    'Taking a 15 minute walk in the middle of the day can help reduce stress.',
    'SonderMind therapists who are accepting new clients will see you within one week.',
    'SonderMind accepts insurance from most major insurance companies, as well as cash pay.',
    'SonderMind also provides psychiatric care in Colorado, Georgia, and Ohio.'
  ];

  ngOnInit(): void {
    this.startProgressStatusTicker();
  }

  private startProgressStatusTicker(): void {
    // emit 2 times every 4 seconds
    interval(4000).pipe(
      takeWhile(() => this.isDone !== true),
      take(2),
      tap((index) => {
        // offset by 1 since we start at zero
        this.progressStatusIndex$.next(index + 1);
      }),
      finalize(() => {
        this.startPauseTicker();
      })
    ).subscribe();
  }

  private startPauseTicker(): void {
    // emit once after 5 seconds
    timer(5000).pipe(
      takeWhile(() => this.isDone !== true),
      tap(() => {
        this.startDidYouKnowTicker();
      })
    ).subscribe();
  }

  private startDidYouKnowTicker(): void {
    // emit every 7 seconds
    interval(7000).pipe(
      takeWhile(() => this.isDone !== true),
      tap((index) => {
        this.didYouKnowIndex$.next(index % 5);
      })
    ).subscribe();
  }
}
