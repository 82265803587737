export enum FlowsEndpointType {
  INTAKE = 'intake',
  MONOLITH = 'default'
}

export interface IFlowsEndpoint {
  /**
   * @returns the type of the endpoint, passed to backend
   */
  type: FlowsEndpointType;

  /**
   * @returns the endpoint for fetching flows by slug or version.
   */
  flows: string;

  /**
   * @returns the endpoint for fetching responses, by slug or version.
   */
  response: string;
}
