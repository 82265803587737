import {
  Component, Inject, OnDestroy, OnInit
} from '@angular/core';

import { CurrentUser } from '@sondermind/data-access/client-user';

import { WizardService, signalFlowTermination } from '@sondermind/flows-host';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';
import { FlowsLaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';

import { IntakeStepComponent } from '../intake-step.component';

export interface IResendConfig extends StepItemConfig {
  headerTitle: string;
}

@Component({
  selector: 'flows-intake-resend',
  templateUrl: './intake-resend.component.html',
  styleUrls: ['./intake-resend.component.scss']
})
export class IntakeResendComponent extends IntakeStepComponent<IResendConfig> implements OnInit, OnDestroy {
  isExistingUser: boolean = false;

  constructor(
    wizard: WizardService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: FlowsLaunchDarklyService,
    currentUser: CurrentUser,
  ) {
    super(wizard);
    this.isExistingUser = !!currentUser.user;
  }

  ngOnInit(): void {
    this.launchDarklyService.sendMatchFlowSubmitEvent();

    // MER-138
    signalFlowTermination(this.wizard);
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from resend email!');
  }
}
