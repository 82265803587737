import {
  Component, EventEmitter, forwardRef, Input, Output
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'flows-basic-form-nps-score',
  templateUrl: './nps-score.component.html',
  styleUrls: ['./nps-score.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NPSScoreComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NPSScoreComponent), multi: true }
  ]
})
export class NPSScoreComponent implements ControlValueAccessor {
  @Input() required = false;
  @Input() disabled = false;

  @Input() name: string;
  @Input() value: number;
  @Output() valueChanged = new EventEmitter<number>();

  // iteratable options
  options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  handleChange() {
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
  }

  // control value accessor handlers
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(_c: AbstractControl): ValidationErrors | null {
    const errors: any = {};
    if (this.required && this.value === undefined) {
      errors.required = true;
    }

    return errors;
  }
}
