/* eslint-disable no-underscore-dangle */
import { Directive, HostBinding, Attribute } from '@angular/core';

@Directive({
  selector: 'a[href]:not([routerLink]):not([mat-button]):not([mat-flat-button]):not([disable-external-link-directive]))'
})
export class ExternalLinkDirective {
  private _linkRel: string = 'noopener';
  private _target: string = 'blank';

  @HostBinding('class.sonder-external-link')
    addClass = true;

  @HostBinding('rel')
  @Attribute('rel')
  set linkRel(val: string) {
    this._linkRel = val;
  }
  get linkRel() {
    return this._linkRel;
  }

  @HostBinding('target')
  @Attribute('target')
  set target(val: string) {
    this._target = val;
  }
  get target(): string {
    return this._target;
  }
}
