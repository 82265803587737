<span #scrollAnchor></span>
<div [ngClass]="component?.config?.styleClasses">
    <div class="flows-step-host-container" data-test="flows-step-host-container">
        <ng-template #container></ng-template>
    </div>
    <p *ngIf="(showError$ | async)" class="error-message">{{ errorMessage }}</p>
    <flows-step-buttons
        *ngIf="!component.config.fullscreen && disabled != null"
        [back]="wizard.canBack()"
        [stepName]="component.slug"
        [exit]="component.config.exit"
        [exitAndSave]="component.config.saveExit"
        [done]="component.done"
        [dialNumber]="$any(component.config.dialNumber)"
        [nextButtonName]="component.config.nextButtonName"
        [showNextButton]="!!component.next && showChildFlowNextButton(component.next)"
        [confirmButtonGtmClass]="component.config.confirmButtonGtmClass"
        [busy]="busy"
        [disabled]="disabled"
        [reset]="resetable"
        (backClicked)="handleBack()"
        (nextClicked)="handleNext()"
        (exitClicked)="handleExit()"
        (exitAndSaveClicked)="handleExitAndSave()"
        (resetClicked)="handleReset()"
    ></flows-step-buttons>

    <flows-step-footer
        *ngIf="!!component.config.footer"
        [footer]="component.config.footer"
        sondermindMediaQueryAttribute
    ></flows-step-footer>
</div>
