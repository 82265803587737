import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import { WizardService } from '@sondermind/flows-host';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { GtmDataLayerService } from '@sondermind/google-tag-manager';
import { ReferenceDataHttpService, ReferenceDatum } from '@sondermind/data-access/reference-data';
import {
  BehaviorSubject, Observable, map, shareReplay, take, takeUntil, tap
} from 'rxjs';
import { IntakeStepComponent } from '../intake-step.component';
import { BasicFormItem, BasicFormOptions, IChipsConfig } from '../basic-form/model';

@Component({
  selector: 'flows-intake-chips',
  templateUrl: './intake-chips.component.html',
  styleUrls: ['./intake-chips.component.scss']
})
export class IntakeChipsComponent extends IntakeStepComponent<IChipsConfig> implements OnInit, OnDestroy {
  options$ = new BehaviorSubject<BasicFormOptions[]>([]);
  selectedOption: BasicFormOptions = null;
  formItem: BasicFormItem;

  constructor(
    public refDataHttp: ReferenceDataHttpService,
    private gtmDataLayerService: GtmDataLayerService,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.valid.next(true);

    [this.formItem] = this.config.form;

    if (this.formItem.type === 'refdata-chipgroup') this.initOptionsFromRefData();
    else if (this.formItem.type === 'chipgroup') this.initOptionsFromForm();

    this.setSelectedItem();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initOptionsFromForm(): void {
    this.options$.next(
      [
        ...this.formItem.options ?? [],
        ...this.wizard?.context?.options ?? []
      ].filter((formOptions, index, self) => index === self.findIndex((val) => val.value === formOptions.value))
    );
  }

  initOptionsFromRefData(): void {
    if (this.formItem.nullOptionLabel) {
      const nullOption: BasicFormOptions = {
        label: this.formItem.nullOptionLabel,
        value: null
      }
      this.options$.next([nullOption]);
    }

    this.getRefDataObservable().pipe(
      take(1),
      map((data) => {
        const mappedOptions: BasicFormOptions[] = data.map((value) => ({
          label: value.title,
          value: value.id as any
        }));

        this.options$.next([...this.options$.value, ...mappedOptions]);
      })
    ).subscribe();
  }

  getRefDataObservable(): Observable<ReferenceDatum[]> {
    // Options which we want to skip in form elements like radio and select
    const skipOptions = (this.formItem?.skipOptions || []).map((o) => o.toLowerCase());

    return this.refDataHttp.list(this.formItem.refdataType, { filter: [{ key: 'hidden', value: 'false' }], sort: [{ key: 'order_number', asc: true }] }).pipe(
      map((e) => e.data.filter((o) => !skipOptions.includes(o.title.toLowerCase()))),
      shareReplay(1)
    );
  }

  setSelectedItem(): void {
    this.options$.pipe(
      takeUntil(this.destroyed$),
      tap((options) => {
        this.selectedOption = options.find((x) => x.value === this.data[this.formItem?.name]);
      })
    ).subscribe();
  }

  handleSingleOptionClick(value: BasicFormOptions): void {
    this.selectedOption = value;
    this.valid.next(true);
    this.submit.emit();
  }

  onSubmit(): ResponseUpdateItem[] {
    const chosenOptionGTM = this.selectedOption?.gtm;
    if (chosenOptionGTM) {
      this.gtmDataLayerService.sendUserTherapyReadiness(chosenOptionGTM.type, chosenOptionGTM.label);
    }

    return [
      <ResponseUpdateItem>{
        human: this.formItem.name,
        key: this.formItem.name,
        value: this.selectedOption?.value
      }
    ];
  }
}
