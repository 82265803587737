import {
  Component, EventEmitter, Inject, OnDestroy, OnInit
} from '@angular/core';
import {
  Observable,
  takeUntil
} from 'rxjs';

import { ConfigurationService } from '@sondermind/configuration';
import { COMMON } from '@sondermind/constants';
import { BreakpointsService } from '@sondermind/utilities/services';
import { LAUNCH_DARKLY_SERVICE, ILaunchDarklyService } from '@sondermind/launch-darkly';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { WizardService } from '../../../services/wizard.service';
import { StepComponent } from '../step.component';

import { signalFlowTermination } from '../tb-communication';

export interface ResendConfig extends StepItemConfig {
  headerTitle: string;
}

@Component({
  selector: 'flows-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss']
})
export class ResendComponent extends StepComponent<ResendConfig> implements OnInit, OnDestroy {
  supportPhoneLink = COMMON.SUPPORT_PHONE_NO_MATCH_LINK;
  supportPhoneDisplay = COMMON.SUPPORT_PHONE_NO_MATCH_DISPLAY;
  supportEmail = COMMON.SUPPORT_EMAIL;

  disabled: boolean = false;
  showConfirmation: boolean = false;
  isMobile$: Observable<boolean>;
  urlBase: string;

  override destroyed$ = new EventEmitter<void>();

  constructor(
    private configurationService: ConfigurationService,
    wizard: WizardService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: ILaunchDarklyService,
    private breakpointService: BreakpointsService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.urlBase = this.configurationService.env.urlBase;
    this.isMobile$ = this.breakpointService.isMobile$;
    this.launchDarklyService.sendMatchFlowSubmitEvent();

    // MER-138
    signalFlowTermination(this.wizard);
  }

  override ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from resend email!');
  }

  /**
   * Make the API call to resend the confirmation email
   * and show "email sent" message no matter the API response.
   * We only want to send one request per user.
   */
  handleResend(): void {
      if (this.showConfirmation) {
        return;
      }
      this.disabled = true;
      this.wizard.resendEmail().pipe(takeUntil(this.destroyed$)).subscribe();
      this.showConfirmation = true;
  }
}
