import { Pipe, PipeTransform } from '@angular/core';
import { IMatchHighlight, MatchHighlightKey } from '@sondermind/utilities/models-therapist';

@Pipe({ name: 'sortMatchHighlights' })
export class SortMatchHighlightsPipe implements PipeTransform {
  private nonPrioritizedKeys: Set<string> = new Set([
    MatchHighlightKey.AVAILABILITY,
    MatchHighlightKey.GENDER,
    MatchHighlightKey.NETWORK,
    MatchHighlightKey.VIDEO
  ]);

  transform(
    data: Record<MatchHighlightKey, IMatchHighlight[]>,
    options: { removeDuplicateTags?: boolean; hideVideoAndGenderTags?: boolean; } = {
      removeDuplicateTags: false,
      hideVideoAndGenderTags: false
    }
  ): IMatchHighlight[] {
    if (data === null || data === undefined) return [];
    if (Object.keys(data).length === 0) return [];

    const orderedData: IMatchHighlight[] = [];
    const endOfListSpecialties: IMatchHighlight[] = [];

    Object.entries(data).forEach(([key, value]) => {
      if (this.nonPrioritizedKeys.has(key)) return;

      if (key === MatchHighlightKey.SPECIALTY) {
        value.forEach((matchHighlight) => {
          this.isEndOfListSpecialty(matchHighlight)
            ? endOfListSpecialties.push(matchHighlight)
            : orderedData.push(matchHighlight);
        });
        return;
      }

      orderedData.push(...value);
    });

    const availability = data[MatchHighlightKey.AVAILABILITY];
    if (availability) {
      orderedData.push(...availability);
    }

    const gender = data[MatchHighlightKey.GENDER];
    if (gender && !options.hideVideoAndGenderTags) {
      orderedData.push(...gender);
    }

    const insurance = data[MatchHighlightKey.NETWORK];
    if (insurance) {
      // first, we remove the string 'In-network with' in all the titles
      const cleanedInsuranceHighlights = insurance.map(
        (x) => ({ ...x, title: x.title.replace('In-network with', '') })
      );
      orderedData.push(...cleanedInsuranceHighlights);
    }

    const video = data[MatchHighlightKey.VIDEO];
    if (video && !options.hideVideoAndGenderTags) {
      orderedData.push(...video);
    }

    if (endOfListSpecialties.length > 0) {
      orderedData.push(...endOfListSpecialties);
    }

    if (!options.removeDuplicateTags) return orderedData;

    // it's possible we have two tags where the "title" is different but the "tag" is the same
    return orderedData.filter(
      (obj, index) => orderedData.findIndex((item) => item.tag === obj.tag) === index);
  }

  // if specialty is anxiety, depression, or trauma, we display at end of list
  isEndOfListSpecialty(specialty: IMatchHighlight): boolean {
    const tag = specialty.tag.toLowerCase();
    return tag === 'anxiety' || tag === 'depression' || tag === 'trauma';
  }
}
