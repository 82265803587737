import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

import { JourneyHeaderComponent } from './components/journey-header/journey-header.component';
import { JourneyItemComponent } from './components/journey-item/journey-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
  ],
  declarations: [
    JourneyHeaderComponent,
    JourneyItemComponent,
  ],
  exports: [
    JourneyHeaderComponent,
    JourneyItemComponent,
  ],
})

export class JourneyModule {}
