import { Component, Input, ContentChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MatLegacyInput } from '@angular/material/legacy-input';

@Component({
  selector: 'sonder-option-disc',
  templateUrl: './option-disc.component.html',
  styleUrls: ['./option-disc.component.scss']
})
export class OptionDiscComponent {
  @Input() label: SafeHtml;
  @ContentChild(MatLegacyInput) input: MatLegacyInput;

  get labelName(): string {
    return this.input && this.input.id;
  }
}
