import {
  animate, query, style, transition, trigger
} from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component, ElementRef, Inject, AfterViewInit, Optional, ViewChild, Renderer2, OnInit
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import {
  filter, map, tap
} from 'rxjs/operators';

import { GtmDataLayerService, GtmCustomEvents } from '@sondermind/google-tag-manager';
import { FlowsLaunchDarklyFeatureFlags, ILaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';
import { ResizeObserverDirective } from '@sondermind/utilities/directives';
import {
  FlowResponse,
  StepConfig,
  StepItem,
  StepItemConfig
} from '@sondermind/utilities/models-flows';

import { HOST_OVERLAY } from '../../models/host-overlay.interface';
import { WizardService } from '../../services/wizard.service';

const TIMING_IN = '450ms ease';
const TIMING_OUT = '250ms ease';

@Component({
  selector: 'flows-host',
  templateUrl: './host.component.html',
  styleUrls: [
    './host.component.scss',
    './override-styles/override.scss'
  ],
  animations: [
    trigger('fade', [
      transition('* => true', [
        style({ background: 'rgba(255,255,255,0)' }),
        query('.flows-host-child', style({ opacity: 0 }), { optional: true }),

        animate(TIMING_IN, style({ background: 'rgba(255,255,255,1)' })),
        query('.flows-host-child', animate(TIMING_IN, style({ opacity: 1 })), { optional: true })
      ]),

      transition('* => false', [
        style({ background: 'rgba(255,255,255,1)' }),
        query('.flows-host-child', style({ opacity: 1 }), { optional: true }),

        query('.flows-host-child', animate(TIMING_OUT, style({ opacity: 0 })), { optional: true }),
        animate(TIMING_OUT, style({ background: 'rgba(255,255,255,0)' })),
      ]),
    ]),
    trigger('fadeHeader', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate(TIMING_IN, style({ opacity: 1 })),
      ]),
      transition('* => false', [
        style({ opacity: 1 }),
        // Extra delay necessary to reduce flicker on the header component while waiting to be destroyed when detaching the overlay
        animate('500ms', style({ opacity: 0 })),
      ])
    ])
  ]
})
export class HostComponent implements AfterViewInit, OnInit {
  @ViewChild('scrollContainer')
    scrollContainerElement: ElementRef<HTMLElement>;
  @ViewChild('flowContainer')
    flowContainerElement: ElementRef<HTMLElement>;
  @ViewChild(ResizeObserverDirective)
    headerResizeObserver: ResizeObserverDirective;

  faded = false;
  data$: Observable<{
    config: StepConfig;
    component: StepItem<StepItemConfig>;
    response: FlowResponse;
  }>;

  // NOTE: see ./override-styles/override.scss for details
  overrideClass: string;
  fullScreen: boolean = false;
  busy: string;
  constructor(
    public wizard: WizardService,
    @Inject(HOST_OVERLAY)
    @Optional()
    public overlayRef: OverlayRef,
    private gtmDataLayerService: GtmDataLayerService,

    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: ILaunchDarklyService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // fetch data together, into a bundle to avoid multiple subscriptions
    this.data$ = combineLatest([
      this.wizard.config$,
      this.wizard.step$,
      this.wizard.response$
    ]).pipe(
      filter((xs) => !xs.some((x) => x == null)),
      map(([config, step, response]) => ({ config, component: this.wizard.component, response })),
      tap((resp) => {
        // Only set overrideClass if it was not previously set, and if class exists on config
        if (this.overrideClass == null && resp.config?.stylingOverrideClass) {
          this.overrideClass = resp.config.stylingOverrideClass;
        }
        if (resp.component.config.fullscreen) {
          this.fullScreen = true;
        }
      })
    );
  }

  /**
   * On step change events, push GTM dataLayer variables to trigger custom events for GA.
   *
   * Scroll to the top when the step changes
   *
   * @memberof HostComponent
   */
  ngAfterViewInit(): void {
    this.wizard.setFlowContainer();
    this.wizard.step$
      .pipe(
        tap(() => {
          this.scrollContainerElement.nativeElement.scrollTop = 0;
        }),
        filter((step) => step !== null)
      )
      .subscribe((step) => {
        this.gtmDataLayerService.dataLayer = {
          event: GtmCustomEvents.MATCH_FLOW,
          category: GtmCustomEvents.MATCH_FLOW,
          action: step,
          label: this.wizard.response.slug,
          matchFlowSlug: this.wizard.response.slug,
          matchFlowStep: step,
          formWizardKey: this.wizard.response.key
        };
      });

    this.handleHeaderResize();
  }

  fadeComplete(): void {
    if (!this.wizard.running && this.overlayRef) {
      this.overlayRef.detach();
    }

    this.faded = true;
  }

  /**
   * Starts a subscription on the resize observer directive that is applied to the
   * header component so that the top position of the scroll container and the bottom
   * margin of the flows container can be adjusted if the height of the header dynamically
   * grows to accomodate text per step transition.
   *
   *
   * @private
   * @memberof HostComponent
   */
  private handleHeaderResize(): void {
    combineLatest([this.wizard.flowContainerRendered$, this.headerResizeObserver.height$])
      .subscribe((val: [boolean, number]) => {
        const height = val[1];
        this.renderer.setStyle(this.scrollContainerElement.nativeElement, 'top', `${height}px`);
        this.renderer.setStyle(this.flowContainerElement.nativeElement, 'margin-bottom', `${height}px`);
      });
  }

  handleBack() {
    if (this.busy) {
      return;
    }
    this.busy = 'back';
    this.wizard.back().subscribe(() => {
      this.busy = null;
    });
  }
}
