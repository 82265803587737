<form [formGroup]="multiSectionCheckboxForm">
  <ng-container *ngFor="let item of config.form">
    <ng-container *ngFor="let section of item.sections">
      <iris-form-field
        [label]="section.label"
        [showOptionalTag]="false"
        [attr.data-test]="section.label + '-label'"
      >
        <div [formGroupName]="item.name">
          <iris-checkbox
            [formControlName]="option.value"
            [attr.data-test]="'session-time-selection-' + option.value"
            *ngFor="let option of section.options"
          >
            {{option.label}}
          </iris-checkbox>
        </div>
      </iris-form-field>
    </ng-container>
  </ng-container>
</form>
