import { Component, OnInit } from '@angular/core';

import { ConfigurationService } from '@sondermind/configuration';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';

import { StepComponent } from '../step.component';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'flows-pause',
  templateUrl: './pause.component.html',
  styleUrls: ['./pause.component.scss']
})
export class PauseComponent extends StepComponent implements OnInit {
  dynamicBodyText: string[] = [];
  dynamicHeaderText: string;
  infoText: string;
  imageUrl: string;
  imageAlignment: string;

  constructor(
    private configurationService: ConfigurationService,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.initImageVars();
    this.initPageText(
      this.wizard?.context?.dynamicHeroText,
      this.wizard?.context?.dynamicHelpTexts,
    );
  }

  initImageVars(): void {
    this.imageUrl = this.configurationService.env.urlBase + this.config.imagePath;
    this.imageAlignment = this.config.imageAlignment ?? 'start';
  }

  initPageText(dynamicHeroText: string, dynamicHelpText: string[]): void {
    this.dynamicBodyText = dynamicHelpText;
    this.dynamicHeaderText = dynamicHeroText;
    this.infoText = this.config.infoText;
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
