<div data-test="flows-readiness">
  <div class="title-text">
    <span><b>{{ title }}</b></span>
  </div>
  <img
    class="readiness-image"
    alt="readiness image"
    [src]="configurationService.env.urlBase + config.imagePath"
  >
  <div
    class="main-text"
    [attr.style]="wizard.component.slug === 'contemplation-page-2' ? 'text-align: left' : null"
    [innerHTML]="mainText"
  >
  </div>
  <div
    class="help-text"
    [attr.style]="wizard.component.slug === 'contemplation-page-2' ? 'text-align: left' : null"
    [innerHTML]="bodyHelpText"
  >
  </div>
</div>
