import {
  Component, OnInit, Inject, OnDestroy
} from '@angular/core';
import {
  Subject, mergeMap, catchError,
  takeUntil,
  Observable
} from 'rxjs';

import { GtmDataLayerService, GtmCustomEvents } from '@sondermind/google-tag-manager';
import { UserSystemInfoService } from '@sondermind/utilities/services';

import {
  buildIntakeEndpoint,
  WizardService,
  INTAKE_FLOW_SLUGS,
  MAIN_INTAKE_FLOW_SLUG
} from '@sondermind/flows-host';
import { FlowInfo } from '@sondermind/utilities/models-flows';

import { FlowsLaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';
import { BrowserNavButtonsService } from '@sondermind/utilities/browser-nav-buttons';
import { ConfigurationService } from '@sondermind/configuration';

@Component({
  selector: 'sonder-flow-boot',
  templateUrl: './app-boot.component.html',
  styleUrls: ['./app-boot.component.scss'],
})
export class AppBootComponent implements OnInit, OnDestroy {
  slug: string = MAIN_INTAKE_FLOW_SLUG;

  // slug that comes from LaunchDarkly
  ldSlugLoaded: boolean = false;
  // needed to compare and prevent non-default flows from being overriden by LaunchDarkly tests
  defaultMatchFlow: string = MAIN_INTAKE_FLOW_SLUG;
  canOverrideBaseFlow: boolean = false;
  private flowsSupportingNavButtons: string[] = [
    MAIN_INTAKE_FLOW_SLUG,
    'matching-flow-base-short',
    'directory-matching-flow'
  ];

  isIntakeHost: boolean = true;
  private matchFlowLoadedEventSubject: Subject<FlowInfo> = new Subject();
  private destroyedSubj: Subject<void> = new Subject<void>();
  destroyed$: Observable<void> = this.destroyedSubj.asObservable();

  constructor(
    public wizardSvc: WizardService,
    private gtmDataLayerService: GtmDataLayerService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: FlowsLaunchDarklyService,
    private userSystemInfoService: UserSystemInfoService,
    private browserNavButtonsService: BrowserNavButtonsService,
    private configurationService: ConfigurationService
  ) {
    this.slug = this.defaultMatchFlow;
  }

  ngOnInit(): void {
    this.wizardSvc.response$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (resp) => {
        this.isIntakeHost = INTAKE_FLOW_SLUGS.includes(resp?.slug);
      }
    );

    this.canOverrideBaseFlow = this.slug === this.defaultMatchFlow;

    this.wizardSvc.toggleRunning(true);
    this.initializeMatchFlow();

    if (this.flowsSupportingNavButtons.includes(this.slug)) {
      this.browserNavButtonsService.init();
      this.wizardSvc.attachBrowserButtonService(this.browserNavButtonsService);
    }
  }

  ngOnDestroy(): void {
    this.launchDarklyService.closeClient();
    // even-though browserNavButtonsService init() is called conditionally,
    //  it is safe to unconditionally call destroy()
    this.browserNavButtonsService.destroy();
    this.wizardSvc.detachBrowserButtonService();

    this.destroyedSubj.next();
    this.destroyedSubj.complete();
  }

  private getFlowInfoFromQueryParameters(): FlowInfo {
    const urlParams = new URLSearchParams(window.location.search);

    return new FlowInfo(
      urlParams.get('slug'),
      urlParams.get('hash'),
      urlParams.get('strict') === 'yes',
    );
  }

  /**
   * Initializing the match flow using either
   * the default slug (supplied via "slug" attribute of "sonder-flow-boot" element)
   * or an override value from LaunchDarkly
   *
   * @private
   * @memberof AppBootComponent
   */
  private initializeMatchFlow(): void {
    this.setMatchFlowDefaultOnDataLayer();
    const matchFlowInitAttempt = this.matchFlowLoadedEventSubject.asObservable().pipe(
      mergeMap((flowInfo) => this.wizardSvc.initialize(
        flowInfo.slug,
        { endpoint_builder: buildIntakeEndpoint },
        flowInfo.hash,
        flowInfo.shouldLoadStrict
      )),
      catchError(() => this.wizardSvc.initialize(this.slug, { endpoint_builder: buildIntakeEndpoint }))
    );

    // set up wizard initialization subscription
    //   so can react and 'start' a flow
    matchFlowInitAttempt.subscribe(() => {
      if (this.wizardSvc.started) {
        this.wizardSvc.start().subscribe();
      }

      // NB: launchDarklyService.initClient() will be called before
      //     reaching this block of code
      const matchFlowInitData = [
        ...this.launchDarklyService.getInitData(),
        { human: 'Matchflow Domain', key: 'domain', value: window.location.hostname },
        { human: 'Document Referrer', key: 'referrer', value: document.referrer },
        { human: 'Path Name', key: 'pathname', value: window.location.pathname },
      ];
      const gaId = this.getGoogleAnalyticsClientId();
      if (gaId) {
        matchFlowInitData.push({ human: 'GA Client ID', key: 'ga_id', value: gaId });
      }

      this.wizardSvc.start(matchFlowInitData).subscribe(() => {
        this.wizardSvc.toggleRunning(true);
      });
    });

    if (!this.configurationService.env.useLDInMatchFlow) {
      const flowInfo = this.getFlowInfoFromQueryParameters();

      if (!flowInfo.slug) {
        flowInfo.slug = this.defaultMatchFlow;
      }

      this.matchFlowLoadedEventSubject.next(flowInfo);
      this.launchDarklyService.initClient();

      return;
    }

    this.launchDarklyService.initClient().subscribe(() => {
      const flowInfo = this.getFlowInfoFromQueryParameters();

      // If there is the slug query string parameter passed in, skip checking LaunchDarkly
      if (!flowInfo.slug &&
        this.canOverrideBaseFlow && this.launchDarklyService.ldSlug?.length > 0 && !this.ldSlugLoaded
      ) {
        this.ldSlugLoaded = true;

        // NOTE: if taking slug from LD, ignore the version hash from URL
        flowInfo.slug = this.launchDarklyService.ldSlug;
        flowInfo.hash = null;
      }

      if (!flowInfo.slug) {
        flowInfo.slug = this.slug;
      }

      // at this point, we should have a slug
      // if don't have version hash, check LaunchDarkly
      if (!flowInfo.hash) {
        const flowVersionFlagName = `flow-version-${flowInfo.slug}`;
        flowInfo.hash = this.launchDarklyService.client?.variation(flowVersionFlagName, '') as string;
      }

      // trigger initialization from slug attribute
      this.matchFlowLoadedEventSubject.next(flowInfo);
    });
  }

  /**
   * Method for setting the match flow default custom event with user system metadata.
   *
   * @private
   * @memberof AppBootComponent
   */
  private setMatchFlowDefaultOnDataLayer(): void {
    const browserInfo = this.userSystemInfoService.userSystemInfo.browser;
    const platformInfo = this.userSystemInfoService.userSystemInfo.platform;
    /* eslint-disable no-extra-boolean-cast */
    const browser = !!browserInfo ? `${browserInfo.name} ${browserInfo.version}` : '';
    const platform = !!platformInfo ? `${platformInfo.type} ${platformInfo.vendor}` : '';
    /* eslint-enable no-extra-boolean-cast */

    this.gtmDataLayerService.dataLayer = {
      event: GtmCustomEvents.MATCH_FLOW_DEFAULT,
      category: GtmCustomEvents.MATCH_FLOW_DEFAULT,
      action: browser,
      label: platform
    };
  }

  private getGoogleAnalyticsClientId(): string {
    // Regex to get the _ga cookie value from the document.cookie string
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    const raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    return match ? match[1] : null;
  }
}
