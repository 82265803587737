import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { IAPIFetchResponse } from '@sondermind/utilities/models-http';
import { IFlowsEndpoint, FlowResponse, ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { CurrentUser } from '@sondermind/data-access/client-user';
import { ConfigurationService } from '@sondermind/configuration';

interface UpdateResponse {
  data: {
    response: FlowResponse;
    status: string;
    context: any;
  };
}

export interface FetchOptions {
  autocreate: boolean;
  pristine: boolean;
  slug_unique: string;
}

@Injectable({
  providedIn: 'root'
})

export class ResponseApiClient {
  endpoint: IFlowsEndpoint;
  inClientPortal: boolean = false;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private configurationService: ConfigurationService,
    currentUser: CurrentUser,
  ) {
    this.inClientPortal = !!currentUser.user;
  }

  setEndpoint(endpoint: IFlowsEndpoint): void {
    this.endpoint = endpoint;
  }

  private storageKey(slug: string, version: string): string {
    return `flows-response|${slug}|${version}`;
  }

  private getResponseKey(slug: string, version: string): string {
    return localStorage.getItem(this.storageKey(slug, version));
  }

  private setResponseKey(slug: string, version: string, val: string) {
    localStorage.setItem(this.storageKey(slug, version), val);
  }

  private deleteResponseKey(slug: string, version: string) {
    localStorage.removeItem(this.storageKey(slug, version));
  }

  private responseUri(response: FlowResponse) {
    return `${this.endpoint.response}/${response.slug}/${response.key}`;
  }

  /**
   * checks to see if there's a response object for the given flow, possibly
   * checking the server to ensure the response is still valid.
   */
  exists(slug: string, version: string): Observable<boolean> {
    const key = this.getResponseKey(slug, version);
    if (key === null) {
      return of(false);
    }

    return this.check(slug, key);
  }

  /**
   * Returns a response data object for the given flow. If a response key
   * is stored in localstorage, attempts to fetch the response with that key.
   * If the response doesn't exist (or has timed out), creates a new response
   * and returns that.
   *
   * @param slug the flow slug to fetch a response for
   * @param options the options for the fetch
   * @param slug_uniq A uniqifier for the slug, so we can respond to the same form twice
   */
  fetch(
    slug: string,
    version: string,
    options: Partial<FetchOptions> = { autocreate: true, slug_unique: '' }
  ): Observable<FlowResponse> {
    // Pass on default options if not set in the partial
    // eslint-disable-next-line no-param-reassign
    options = { autocreate: true, slug_unique: '', ...options };
    const fetch_slug = options.slug_unique ? slug + options.slug_unique : slug;
    const key = this.getResponseKey(fetch_slug, version);
    if (key == null && !options.autocreate && !options.pristine) {
      return of(null);
    }

    // eslint-disable-next-line no-nested-ternary
    const request = options.pristine
      ? this.create(slug, options.slug_unique)
      : key
        ? this.find(slug, key)
        : this.create(slug, options.slug_unique);

    return request
      .pipe(
        catchError((err: HttpErrorResponse, caught) => {
          // handle missing (probably timed out) by creating a new one
          if (err.status === 404) {
            if (options.autocreate) {
              return this.create(slug, options.slug_unique);
            }

            return of(null);
          }

          throw err;
        }),
        tap((res) => {
          if (res) {
            this.setResponseKey(fetch_slug, version, res.key);
          }
        })
      );
  }

  enter(response: FlowResponse, stepName: string, items: ResponseUpdateItem[]): Observable<UpdateResponse> {
    return this.http.patch<UpdateResponse>(this.responseUri(response), {
      transition: 'enter', slug: stepName, updates: items, backend_type: this.endpoint.type
    });
  }

  leave(response: FlowResponse, stepName: string, items: ResponseUpdateItem[]): Observable<UpdateResponse> {
    return this.http.patch<UpdateResponse>(this.responseUri(response), {
      transition: 'leave', slug: stepName, updates: items, backend_type: this.endpoint.type
    });
  }

  back(response: FlowResponse): Observable<UpdateResponse> {
    return this.http.patch<UpdateResponse>(this.responseUri(response), {
      transition: 'back', backend_type: this.endpoint.type
    });
  }

  reset(slug: string, version: string): void {
    this.deleteResponseKey(slug, version);
  }

  resend(key: string): Observable<FlowResponse> {
    return this.http
      .post<IAPIFetchResponse<FlowResponse>>(`${this.endpoint.response}/responses/${key}/resend_invitation`, {})
      .pipe(
        map((res) => res.data)
      );
  }

  private create(slug: string, idempotencyToken?: string | null): Observable<FlowResponse> {
    const searchParams = new URLSearchParams(window.location.search);
    // passing url params and document as arguments to facilitate testing
    let params = this.gatherReferralParams(searchParams, document);

    if (idempotencyToken) {
      params = params.append('idempotency_token', idempotencyToken);
    }

    params = params.append('backend_type', this.endpoint.type);
    return this.http
      .post<IAPIFetchResponse<FlowResponse>>(`${this.endpoint.response}/${slug}/`, params)
      .pipe(
        map((res) => res.data)
      );
  }

  getEnvironmentDomain(): string {
    if (!window.location.hostname.includes('localhost')) {
      return `sondermind.${window.location.hostname.slice(-3)}`;
    }

    return 'localhost';
  }

  getAndClearCookie(cookieName: string): string {
    const cookieValue = this.cookieService.get(cookieName);
    this.cookieService.delete(cookieName, '/', this.getEnvironmentDomain());
    return cookieValue;
  }

  gatherReferralParams(searchParams: URLSearchParams, doc: Document, date: Date = new Date()): HttpParams {
    let params = new HttpParams();

    // referral_source
    const referralSource = this.getAndClearCookie('referral_source') || searchParams.get('referral');
    if (referralSource) {
      params = params.append('referral_source', referralSource);
    }

    let smClientPortalUTM;

    try {
      const storedData = localStorage.getItem('sm_client_portal_utm');
      smClientPortalUTM = storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      // Handle SyntaxError
      smClientPortalUTM = null;
    }

    // EXTERNAL_REFERRAL
    if (this.cookieService.get('EXTERNAL_REFERRAL')) {
      params = params.append('external_referral', this.getAndClearCookie('EXTERNAL_REFERRAL'));
    } else if (this.inClientPortal && smClientPortalUTM) {
      params = params.append('external_referral', smClientPortalUTM['source']);
    } else if (searchParams.get('utm_referrer')) {
      params = params.append('external_referral', searchParams.get('utm_referrer'));
    } else if (doc.referrer) {
      const marketingUrl = this.configurationService.env.marketingUrl || 'https://www.sondermind.com';
      const marketingRoomDomain = marketingUrl.split('.').slice(1).join('.');
      const referrerHostname = new URL(doc.referrer).hostname;
      if (!referrerHostname.endsWith(marketingRoomDomain)) {
        params = params.append('external_referral', referrerHostname);
      }
    }

    // EXTERNAL_REFERRAL_SEARCH
    if (this.cookieService.get('EXTERNAL_REFERRAL_SEARCH')) {
      params = params.append('external_referral_search', this.getAndClearCookie('EXTERNAL_REFERRAL_SEARCH'));
    } else if (this.inClientPortal && smClientPortalUTM) {
      params = params.append('external_referral_search', smClientPortalUTM['parameters']);
    } else {
      const utmParams = new URLSearchParams();

      searchParams.forEach((value, name) => {
        if (name.startsWith('utm_')) {
          utmParams.append(name, value);
        }
      });

      const utmParamString = utmParams.toString();
      if (utmParamString) {
        params = params.append('external_referral_search', utmParamString);
      }
    }

    // EXTERNAL_REFERRAL_DATE
    if (this.cookieService.get('EXTERNAL_REFERRAL_DATE')) {
      params = params.append('external_referral_date', this.getAndClearCookie('EXTERNAL_REFERRAL_DATE'));
    } else if (this.inClientPortal && smClientPortalUTM) {
      params = params.append('external_referral_date', smClientPortalUTM['timestamp']);
    } else if (params.get('external_referral') || params.get('external_referral_search')) {
      // set 'external_referral_date' only if have either 'external_referral' or 'external_referral_search' populated
      params = params.append('external_referral_date', date.toISOString());
    }

    localStorage.removeItem('sm_client_portal_utm');

    return params;
  }

  private find(slug: string, key: string): Observable<FlowResponse> {
    return this.http
      .get<IAPIFetchResponse<FlowResponse>>(
        `${this.endpoint.response}/${slug}/${key}`,
        { params: { backend_type: this.endpoint.type } })
      .pipe(
        map((res) => res.data)
      );
  }

  private check(slug: string, key: string): Observable<boolean> {
    return this.http
      .head(
        `${this.endpoint.response}/${slug}/${key}?backend_type=${this.endpoint.type}`,
        { observe: 'response', params: { backend_type: this.endpoint.type } })
      .pipe(
        map((res) => res.status === 200),
        catchError(() => of(false))
      );
  }
}
