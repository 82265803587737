import { NgModule } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { ButtonRowDirective } from './button-row.directive';
import { ButtonDirective } from './button.directive';

/**
 * @deprecated This should not be used. Instead, consider using IrisButtonModule
 */
@NgModule({
  imports: [
    MatLegacyButtonModule,
    MatIconModule,
  ],
  declarations: [
    ButtonDirective,
    ButtonRowDirective,
  ],
  exports: [
    ButtonDirective,
    ButtonRowDirective,
  ]
})
export class ButtonsModule {
}
