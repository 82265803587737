import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy, OnInit,
  Output,
  SimpleChanges,
  Inject,
  ViewChild, ViewContainerRef,
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import {
  FlowResponse,
  StepItem,
  StepNext,
  ResponseUpdateItem
} from '@sondermind/utilities/models-flows';

import { StepComponent } from './step.component';
import { WizardService, GENERIC_FLOWS_ERROR_MESSAGE } from '../../services/wizard.service';
import { COMPONENT_MAP } from '../../models/component-map.interface';

@Component({
  selector: 'flows-step-host',
  templateUrl: './step-host.component.html',
  styleUrls: [
    './step-host.component.scss',
    '../../styles/flows-style-classes.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepHostComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('scrollAnchor') scrollAnchor: ElementRef;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  private child: ComponentRef<StepComponent>;
  private subs: Subscription[] = [];

  @Input() busy: string;
  disabled: boolean;
  showError$: Observable<HttpErrorResponse>;
  errorMessage: string = '';

  @Input() response: FlowResponse;
  @Input() component: StepItem;
  @Input() resetable: boolean;

  @Output() stepBack = new EventEmitter<void>();
  @Output() stepNext = new EventEmitter<ResponseUpdateItem[]>();
  @Output() stepExit = new EventEmitter<void>();
  @Output() stepReset = new EventEmitter<void>();

  constructor(
    public wizard: WizardService,
    @Inject(COMPONENT_MAP)
    private componentMap: { [key: string]: any; },
    private cdRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
  ) {
  }

  ngOnInit() {
    this.showError$ = this.wizard.showError$;
    this.showError$.subscribe((error: HttpErrorResponse) => {
      if (error?.error?.errors?.base != null) {
        this.busy = null;
        this.errorMessage = error?.error.errors.base[0].replace('<br>', '');
      } else {
        this.errorMessage = GENERIC_FLOWS_ERROR_MESSAGE;
      }
    });
  }

  ngOnDestroy() {
    if (this.container) {
      this.container.clear();
    }
  }

  ngAfterViewInit() {
    this.attachNewChild(this.component);
  }

  ngOnChanges(c: SimpleChanges) {
    // if response has changed, marking dirty
    // don't bother if there's a new component, we'll set it when we insert the new child
    if (c.response != null && this.child) {
      this.child.changeDetectorRef.markForCheck();
    }

    // if the component changed, clean out and attach a new child
    if (c.component != null) {
      this.attachNewChild(this.component);
      this.cdRef.markForCheck();
    }
  }

  handleNext() {
    if (this.busy) { return }
    if (this.disabled) { return }
    const data = this.child.instance.onSubmit();

    this.busy = 'next';
    this.wizard.next(data).subscribe(() => {
      this.busy = null;
      this.scrollToTop();
    });
  }

  handleBack() {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.back().subscribe(() => {
      this.busy = null;
      this.scrollToTop();
    });
  }

  handleExit() {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.exit().subscribe(() => {
      this.busy = null;
    });
  }

  handleExitAndSave() {
    if (this.busy) { return }
    const data = this.child.instance.onSubmit();

    this.busy = 'back';
    this.wizard.exitAndSave(data).subscribe(() => {
      this.busy = null;
      this.stepExit?.emit();
    });
  }

  handleReset() {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.reset().subscribe(() => {
      this.busy = null;
    });
  }

  // Catches instances where a child flow's next config setting is empty and the next button should be hidden
  showChildFlowNextButton(next: StepNext): boolean {
    if (typeof next === 'string') {
      return next.substr(-1) !== '/';
    }
    return true;
  }

  private scrollToTop() {
    const containers = this.scrollDispatcher.getAncestorScrollContainers(this.scrollAnchor);
    const container = containers.find((x) => x.measureScrollOffset('top') > 0);
    const scrollMe = container?.getElementRef().nativeElement ?? window;
    scrollMe.scrollTo(0, 0);
  }

  private attachNewChild(c: StepItem) {
    if (this.container == null) {
      return;
    }

    this.container.clear();
    this.child = null;

    let sub = this.subs.shift();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.shift();
    }

    if (c) {
      const ctype = this.componentMap[c.config.component];

      this.child = this.container.createComponent<StepComponent>(ctype);

      // set @Inputs
      this.child.instance.component = c;
      this.child.instance.response = this.response;

      // subscribe to @Outputs
      this.subs.push(
        this.child.instance.validityChange.subscribe((v) => {
          this.disabled = !v;
          this.cdRef.detectChanges();
        })
      );

      this.subs.push(
        this.child.instance.submit.subscribe(() => {
          this.handleNext();
        })
      );

      this.child.changeDetectorRef.detectChanges();
    }
  }
}
