import {
  AfterContentInit, ContentChild, Directive, Host, Optional, ElementRef, HostBinding, OnDestroy, EventEmitter
} from '@angular/core';

import { MatIcon } from '@angular/material/icon';
import { MatLegacyButton, MatLegacyAnchor } from '@angular/material/legacy-button';
import { takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@sondermind/utilities/services';
import { IButtonConfig } from './button-config.interface';

const ButtonConfigMap: { [key: string]: Partial<IButtonConfig>; } = {
  'sondermind-primary': {
    classes: [
      'sondermind-button',
      'sondermind-button-primary',
      'mat-flat-button'
    ],
    color: 'accent',
    iconColor: 'primary',
    iconInline: false,
  },

  'sondermind-secondary': {
    classes: [
      'sondermind-button',
      'sondermind-button-secondary',
      'mat-stroked-button'
    ],
    color: 'primary',
    iconColor: 'primary',
    iconInline: false,
  },

  'sondermind-tertiary': {
    classes: [
      'sondermind-button',
      'sondermind-button-tertiary',
      'mat-button'
    ],
    color: 'primary',
    iconColor: 'accent',
    iconInline: true,
    disableRipple: true
  },

  'sondermind-icon': {
    classes: [
      'sondermind-button-icon',
      'mat-icon-button'
    ],
    color: 'primary',
    iconColor: 'primary',
    iconInline: false
  },

  'sondermind-nav': {
    classes: [
      'sondermind-button-nav'
    ]
  }
};

/**
 * @deprecated This should not be used. Instead, consider using IrisButton and IrisButtonContainer where appropriate.
 */
@Directive({
  selector: `
    button[mat-button][sondermind-primary],
    button[mat-button][sondermind-secondary],
    button[mat-button][sondermind-tertiary],
    button[mat-icon-button][sondermind-icon],
    a[mat-button][sondermind-primary],
    a[mat-button][sondermind-secondary],
    a[mat-button][sondermind-tertiary]
  `
})
export class ButtonDirective implements AfterContentInit, OnDestroy {
  // reference to the optional icon
  @ContentChild(MatIcon)
    icon: MatIcon = null;

  // the button configs we're working with
  private button: MatLegacyButton;
  private buttonConfigs: Array<Partial<IButtonConfig>> = [];

  // destroyer for mobile subscription
  private destroyed$ = new EventEmitter<void>();

  // are we mobile?
  @HostBinding('attr.data-mobile')
  private mobile: boolean;

  constructor(
  @Optional()
  @Host() button: MatLegacyButton,

    @Optional()
    @Host() anchor: MatLegacyAnchor,

    private elementRef: ElementRef,
    private breakpointSvc: BreakpointsService
  ) {
    if (button) {
      this.button = button;
    } else if (anchor) {
      this.button = anchor;
    } else {
      throw new Error('no provider for MatButton or MatAnchor!');
    }

    this.parseConfigs();

    // track whether we're mobile or not
    this.breakpointSvc.isMobile$
      .pipe(takeUntil(this.destroyed$))
      // eslint-disable-next-line no-return-assign
      .subscribe((m) => this.mobile = m);
  }

  ngAfterContentInit(): void {
    if (this.button && this.buttonConfigs.length) {
      this.buttonConfigs.forEach((config) => {
        this.applyConfig(config);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private get hostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  private hostHasAttribute(attribute: string): boolean {
    return this.hostElement.hasAttribute(attribute);
  }

  private parseConfigs(): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in ButtonConfigMap) {
      if (this.hostHasAttribute(attr)) {
        this.buttonConfigs.push(ButtonConfigMap[attr]);
      }
    }
  }

  private applyConfig(config: Partial<IButtonConfig>) {
    if (config.classes) {
      config.classes.forEach((c) => {
        this.hostElement.classList.add(c);
      });
    }

    if (config.color) {
      this.button.color = config.color;
    }

    if (config.disableRipple !== undefined) {
      this.button.disableRipple = config.disableRipple;
    }

    if (this.icon) {
      if (config.iconColor) {
        this.icon.color = config.iconColor;
      }

      if (config.iconInline !== undefined) {
        this.icon.inline = config.iconInline;
      }
    }
  }
}
