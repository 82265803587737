import { Component, OnDestroy, OnInit } from '@angular/core';

import { WizardService, signalFlowTermination } from '@sondermind/flows-host';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';
import { IrisButtonTypes } from '@sondermindorg/iris-design-system-angular';
import { IntakeStepComponent } from '../intake-step.component';

export interface ImageItem {
  url: string;
  afterText: string;
  beforeText: string;
  width?: string;
}

export interface CompleteConfig extends StepItemConfig {
  infoText?: string;
  images?: ImageItem[];
}

@Component({
  selector: 'flows-intake-complete',
  templateUrl: './intake-complete.component.html',
  styleUrls: ['./intake-complete.component.scss']
})
export class IntakeCompleteComponent extends IntakeStepComponent<CompleteConfig> implements OnInit, OnDestroy {
  private clinicalQuestionnaireClass = 'clinical-questionnaire';
  helpTexts: string[] = [];
  heroText: string;
  isClinicalQuestionnaire = false;
  irisButtonTypes = IrisButtonTypes;

  constructor(
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    if (this.wizard.context && 'helpText' in this.wizard.context) {
      this.helpTexts = Array.isArray(this.wizard.context.helpText)
        ? this.wizard.context.helpText
        : [this.wizard.context.helpText];
    } else {
      this.helpTexts = Array.isArray(this.config.helpText)
        ? this.config.helpText
        : [this.config.helpText];
    }

    if (this.wizard.context && 'heroText' in this.wizard.context) {
      this.heroText = this.wizard.context.heroText;
    } else {
      this.heroText = this.config.heroText;
    }

    this.isClinicalQuestionnaire = this.config.styleClasses
      ? this.config.styleClasses.includes(this.clinicalQuestionnaireClass)
      : false;

    // MER-138
    signalFlowTermination(this.wizard);
  }

  override ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from complete!');
  }

  handleExit(): void {
    this.wizard.complete().subscribe(() => {
      if (this.config.url) {
        const url = this.buildAbsoluteUrl(this.config.url);
        window.location.assign(url);
      }
    });
  }

  flex(image: ImageItem): string {
    const extraFlexSize = '32px';

    if (image.width) {
      return `0 0 calc(${image.width} - ${extraFlexSize})`;
    }

    return `0 0 calc(50% - ${extraFlexSize})`;
  }

  private buildAbsoluteUrl(href: string): string {
    const link = document.createElement('a');
    link.href = href;

    return link.href;
  }
}
