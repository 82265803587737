import { PipeTransform, Pipe } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';

@Pipe({
  name: 'conformToMask',
})
export class ConformToMaskPipe implements PipeTransform {
  transform(value: string, mask: Array<string | RegExp>) {
    if (value) {
      const result = conformToMask(value, mask, {});
      if (!result.meta.someCharsRejected) {
        return result.conformedValue;
      }
    }
    return value;
  }
}
