import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';

import {
  Observable, map, shareReplay, startWith, takeUntil
} from 'rxjs';
import { conformToMask } from 'angular2-text-mask';

import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { WizardService } from '@sondermind/flows-host';
import { SharedMasks } from '@sondermind/utilities/text-masks';
import { ReferenceDataHttpService } from '@sondermind/data-access/reference-data';
import { DropdownOption, TextareaSizes } from '@sondermindorg/iris-design-system-angular';
import { CurrentUser } from '@sondermind/data-access/client-user';

import { BasicFormConfig, BasicFormItem } from './model';
import {
  EMAIL_FIELD_OPTS, IntakeStepComponent, PHONE_FIELD_OPTS, BIRTHDAY_FIELD_OPTS
} from '../intake-step.component';
import { BirthDatePipe } from './pipes/birth-date.pipe';

@Component({
  selector: 'intake-basic-form',
  templateUrl: './intake-basic-form.component.html',
  styleUrls: ['./intake-basic-form.component.scss'],
})
export class IntakeBasicFormComponent extends IntakeStepComponent<BasicFormConfig> implements OnInit, OnDestroy {
  basicInfoForm: FormGroup = new FormGroup({});
  isExistingUser: boolean = false;
  masks = {
    birthdayMask: SharedMasks.birthdayMask,
    phoneMask: PHONE_FIELD_OPTS
  };

  patterns = {
    birthday: BIRTHDAY_FIELD_OPTS.pattern,
    phone: PHONE_FIELD_OPTS.pattern,
    email: EMAIL_FIELD_OPTS.pattern
  };

  formTypes = {
    birthday: 'tel',
    phone: 'tel',
    email: 'email'
  };

  textareaSizes = TextareaSizes;
  options: Record<string, Observable<Array<DropdownOption<number>>>> = {};

  constructor(
    private fb: FormBuilder,
    private refDataHttp: ReferenceDataHttpService,
    private birthDatePipe: BirthDatePipe,
    private currentUser: CurrentUser,
    wizard: WizardService
  ) {
    super(wizard);
    this.isExistingUser = !!this.currentUser.user;
  }

  ngOnInit(): void {
    this.getRefData();
    this.initForm();
    this.subscribeToForm();
  }

  getRefData(): void {
    this.config.form.forEach((item) => {
      if (item.type !== 'refdata-select') return;

      // Options which we want to skip in form elements like radio and select
      const skipOptions = (item?.skipOptions || []).map((o) => o.toLowerCase());

      this.options[item.name] = this.refDataHttp.list(item.refdataType,
        { filter: [{ key: 'hidden', value: 'false' }], sort: [{ key: 'order_number', asc: true }] }
      ).pipe(
        map((e) => e.data.filter((o) => !skipOptions.includes(o.title.toLowerCase()))),
        map((referenceData) => referenceData.map((value) => ({
          name: value.title,
          value: value.id
        }))),
        shareReplay(1)
      );
    });
  }

  initForm(): void {
    this.config.form.forEach((item) => {
      const formComponent = this.setupFormControl(item);

      if (item.required) {
        formComponent.addValidators(Validators.required);
        formComponent.updateValueAndValidity();
      }

      if (item.readonlyIfAuthenticated && this.isExistingUser) {
        formComponent.disable();
      }

      this.basicInfoForm.addControl(item.name, formComponent);
    });
  }

  setupFormControl(item: BasicFormItem): FormGroup | FormControl {
    const initialValue = item.name in this.data
      ? this.formatInitialValue(item, this.data[item.name])
      : this.getInitialValue(item);

    // commsPrefs has multiple options and needs to be a nested form
    if (item.name === 'commsPrefs') {
      const fg = this.fb.group({});
      item.options.forEach((option) => {
        fg.addControl(option.value, this.fb.control(initialValue[option.value]));
      });
      return fg;
    }

    return this.fb.control(initialValue);
  }

  subscribeToForm(): void {
    this.basicInfoForm.valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith(this.basicInfoForm.value)
    ).subscribe(() => {
      this.valid.next(this.basicInfoForm.valid);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onSubmit(): ResponseUpdateItem[] {
    return this.config.form.map((item) => ({
      human: this.formatHumanFieldOnSubmit(item),
      key: item.name,
      value: this.formatValueFieldOnSubmit(item, this.basicInfoForm.getRawValue()[item.name])
    }));
  }

  formatInitialValue(
    item: BasicFormItem, existingValue: string | string[]
  ): string | boolean | Record<string, boolean> {
    switch (item.name) {
      case 'contactBirthDate':
        return this.birthDatePipe.transform(existingValue as string);
      case 'newsletterOptStatus':
        return existingValue === 'opt-in';
      case 'contactPhoneNumber':
        return conformToMask(existingValue, PHONE_FIELD_OPTS.mask, PHONE_FIELD_OPTS).conformedValue as string;
      case 'commsPrefs':
        return item.options
          .reduce((prev, option) => ({ ...prev, [option.value]: existingValue.includes(option.value) }), {});
      default:
        return existingValue as string | boolean;
    }
  }

  getInitialValue(item: BasicFormItem): boolean | Record<string, boolean> {
    switch (item.name) {
      case 'contactSMSAllowed':
      case 'newsletterOptStatus':
        return true;
      case 'commsPrefs':
        return item.options.reduce((prev, option) => ({ ...prev, [option.value]: true }), {});
      default:
        return null;
    }
  }

  formatHumanFieldOnSubmit(item: BasicFormItem): string {
    switch (item.name) {
      case 'contactPhoneNumber':
        return 'SMS Phone';
      default:
        return item.label || item.name;
    }
  }

  formatValueFieldOnSubmit(
    item: BasicFormItem, value: string | boolean | Record<string, boolean>
  ): string | string[] | Date {
    switch (item.name) {
      case 'contactBirthDate':
        return new Date(value as string);
      case 'newsletterOptStatus':
        return value ? 'opt-in' : 'opt-out';
      case 'contactPhoneNumber':
        return (value as string).replace(/[^0-9]/g, '');
      case 'commsPrefs':
        return Object.keys(value).filter((key) => value[key]);
      default:
        return value as string;
    }
  }
}
