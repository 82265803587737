import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ConfigurationService } from '@sondermind/configuration';
import { FormlibFileUploadService } from '@sondermind/data-access/formlib';

@Injectable({
  providedIn: 'root'
})
export class UploadDataService implements FormlibFileUploadService {
  constructor(
    private configurationService: ConfigurationService,
    private http: HttpClient,
  ) { }

  upload(path: string, file: Blob | File): Observable<string> {
    const url = `${this.configurationService.env.apiBase}/v2/uploads`;
    const body = { path };

    return this.http
      .post<{ upload_to: string; download_from: string; }>(url, body, { observe: 'body' })
      .pipe(
        switchMap((x) => this.http
          .put(x.upload_to, file)
          .pipe(
            map(() => x.download_from)
          )),
        catchError((err, _caught) => {
          if (err instanceof HttpErrorResponse) {
            const realError = new Error(`Error while performing upload! (${err.status} ${err.statusText}) ${err.error}`);
            return throwError(() => realError);
          }

          return throwError(err);
        })
      );
  }
}
