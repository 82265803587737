import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectiveModule } from '@sondermind/utilities/directives';
import { MatIconModule } from '@angular/material/icon';
import { MatchingProgressComponent } from './components/matching-progress/matching-progress.component';

@NgModule({
  imports: [
    CommonModule,
    DirectiveModule,
    MatIconModule
  ],
  declarations: [
    MatchingProgressComponent,
  ],
  exports: [
    MatchingProgressComponent,
  ]
})
export class MatchingUtilitiesModule {}
