import { WebcamInitError } from 'ngx-webcam';
import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { FormlibFileUploadService, FILE_UPLOAD_SERVICE } from '@sondermind/data-access/formlib';

import { SupportChecks } from './support-checks';

export enum Mode {
  Pristine,
  Working,
  Uploading,
}

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  errors: string[] = [];
  camErrorSubj = new BehaviorSubject<boolean>(false);

  // support for checking if we're allowed to webcam
  supportChecks = new SupportChecks();

  pathName: string;

  constructor(
    @Inject(FILE_UPLOAD_SERVICE)
    private uploader: FormlibFileUploadService
  ) {}

  handleCamError(err: WebcamInitError): void {
    this.camErrorSubj.next(true);
    this.errors.push("Couldn't connect to the webcam.");
  }

  uploadImage(body: Blob | File): Observable<string> {
    const name = this.generateImageName(body.type);
    return this.uploader.upload(name, body);
  }

  generateImageName(type: string): string {
    const timestamp = new Date().valueOf();
    const slug = Math.random().toString(36).substring(2, 15);
    const extension = type.split('/')[1];

    return `${timestamp}_${slug}.${extension}`;
  }

  generateBlobFromUri(uri: string): Blob {
    const uriParts = uri.split(',');
    const mimeString = uriParts[0].split(':')[1].split(';')[0];
    const byteString = atob(uriParts[1]);

    const buffer = new ArrayBuffer(byteString.length);
    const byteArray = new Uint8Array(buffer);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeString });
  }

  clearErrors(): void {
    this.errors = [];
  }
}
