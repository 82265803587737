import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NativeAppClassDirective } from "./directives/native-app-class.directive";
import { NativeAppHiddenDirective } from "./directives/native-app-hidden.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    NativeAppClassDirective,
    NativeAppHiddenDirective
  ],
  exports: [
    NativeAppClassDirective,
    NativeAppHiddenDirective
  ]
})
export class NativeAppInterfaceModule {}
