import {
  Component, OnInit, NgZone, ChangeDetectorRef
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  trigger, state, style, transition, animate
} from '@angular/animations';

import { BreakpointsService } from '../../services/breakpoints.service';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'sonder-checkbox-multi',
  templateUrl: './checkbox-multi.component.html',
  styleUrls: ['./checkbox-multi.component.scss'],
  animations: [
    trigger('slideUp', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':enter', [
        style({ transform: 'translateY(1em)', opacity: 0 }),
        animate('100ms ease')
      ])
    ])
  ]
})
export class CheckboxMultiComponent extends FormFieldComponent implements OnInit {
  constructor(
    form: NgForm,
    breakpoint: BreakpointsService,
    zone: NgZone,
    cdr: ChangeDetectorRef
  ) {
    super(form, cdr, zone, breakpoint);
  }

  override ngOnInit() {
  }
}
