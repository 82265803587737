<div data-test="flows-contemplation-page">
  <div class="link-square">

    <div class="row">
      <a class="card" [href]="infoLinks.CLIENT_WHO_CAN_BENEFIT" target="_blank">
        <p class="title">
          Do I Need Therapy? 12 Signs and Benefits To Know
        </p>
        <mat-icon class="icon">launch</mat-icon>
      </a>

      <a class="card" [href]="infoLinks.CLIENT_IN_PERSON_VS_ONLINE" target="_blank">
        <p class="title">
          Online Therapy vs. In-Person Therapy: What's Right for Me?
        </p>
        <mat-icon class="icon">launch</mat-icon>
      </a>
    </div>

    <div class="row">
      <a class="card" [href]="infoLinks.INSURANCE_AND_PAYMENT_FAQS" target="_blank">
        <p class="title">
          How Much Will My Therapy Appointment Cost?
        </p>
        <mat-icon class="icon">launch</mat-icon>
      </a>

      <a class="card" [href]="infoLinks.CLIENT_FIVE_TIPS" target="_blank">
        <p class="title">
          How to Get the Most Out of Therapy: 5 Tips for Success
        </p>
        <mat-icon class="icon">launch</mat-icon>
      </a>
    </div>
  </div>
  <div class="were-here-for-you">
    <h2 class="were-here-for-you-header">We're here for you on your journey</h2>
    <p class="were-here-for-you-body-text">At SonderMind, we meet you where you are. We offer free resources about therapy and how it works on our website.</p>
  </div>
</div>
