<div class="flows-intake-help-and-hero"
  *ngIf="!!component.config.heroText || !!component.config.helpText">
  <flows-intake-hero
    [component]="component"
    [response]="response"
  >
  </flows-intake-hero>
  <flows-intake-helptext
    [component]="component"
    [response]="response"
  >
  </flows-intake-helptext>
</div>
