<label class="sonder-option-checkbox-wrapper sondermind-design-system">
  <ng-content></ng-content>
  <div class="sonder-option-checkbox" *ngIf="label">
    <span
      class="sonder-option-checkbox-message"
      [innerHTML]="label"
      [attr.data-test]="'therapy-reason-label-' + label"
    >
    </span>
  </div>
</label>
