/* eslint-disable max-classes-per-file */
import {
  NgModule, Inject, ApplicationRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { TextMaskModule } from 'angular2-text-mask';

import { JourneyModule } from '@sondermind/features/design/journey-header';
import { ButtonsModule } from '@sondermind/features/design/buttons';
import { FormlibModule } from '@sondermind/formlib';
import { ENVIRONMENT } from '@sondermind/utilities/models-environment';
import { DirectiveModule } from '@sondermind/utilities/directives';
import { DatadogLoggingModule } from '@sondermind/datadog-logging';
import { ConfigurationModule } from '@sondermind/configuration';
import { ErrorHandlingModule } from '@sondermind/error-handling';
import { FlowsHostModule } from '@sondermind/flows-host';
import { FlowsIntakeModule } from '@sondermind/flows-intake';
import { FlowsLaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';
import { BrowserNavButtonsService } from '@sondermind/utilities/browser-nav-buttons';
import { LoggingInitModule } from '@sondermind/logging-init';
import { TherapistStore } from '@sondermind/data-access/client-facing/therapist';
import { CurrentUser } from '@sondermind/data-access/client-user';

import { AppBootComponent } from './components/app-boot/app-boot.component';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    A11yModule,
    ButtonsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    FormlibModule,
    JourneyModule,

    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
    MatLegacyInputModule,
    MatLegacyRadioModule,
    MatLegacyTabsModule,
    MatLegacyProgressSpinnerModule,
    MatLegacySelectModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    TextMaskModule,

    FlowsHostModule.forRoot(),
    DirectiveModule,
    DatadogLoggingModule.forRoot(environment),
    ConfigurationModule,
    LoggingInitModule,
    FlowsIntakeModule,
    ErrorHandlingModule
  ],
  declarations: [
    AppBootComponent
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: LAUNCH_DARKLY_SERVICE, useClass: FlowsLaunchDarklyService
    },
    BrowserNavButtonsService,
    // adding a dummy CurrentUser and TherapistStore providers.
    // there are needed to allow running flows both independently, and embedded in client portal
    // since when embedded, we want to interact with both CurrentUser and TherapistStore services
    {
      provide: CurrentUser,
      useValue: { } as CurrentUser
    },
    {
      provide: TherapistStore,
      useValue: { } as TherapistStore
    },
  ],
  bootstrap: [
  ]
})
export class AppModule {
  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppBootComponent);
  }
}
