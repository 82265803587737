import { ReplaySubject, BehaviorSubject } from 'rxjs';

export class SupportChecks {
  private supportsCapture = new ReplaySubject<boolean>(1);
  private supportsWebcam = new ReplaySubject<boolean>(1);
  private availableDevicesSubj = new BehaviorSubject<MediaDeviceInfo[]>([]);

  capture$ = this.supportsCapture.asObservable();
  webcam$ = this.supportsWebcam.asObservable();

  availableDevices$ = this.availableDevicesSubj.asObservable();
  get availableDevices() {
    return this.availableDevicesSubj.value;
  }

  initialize() {
    if (!document || !navigator) {
      this.supportsCapture.next(false);
      this.supportsWebcam.next(false);
      return;
    }

    const input = document.createElement('input');
    input.setAttribute('capture', 'true');

    const supportCapture = !!input['capture'];
    this.supportsCapture.next(supportCapture);

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      this.supportsWebcam.next(false);
      return;
    }

    navigator.mediaDevices.enumerateDevices().then((ds) => {
      const cams = ds.filter((d) => d.kind === 'videoinput');
      this.availableDevicesSubj.next(cams);

      const supportWebcam = !!cams.length;
      this.supportsWebcam.next(supportWebcam);
    }, (err) => {
      console.error(err);
      this.supportsWebcam.next(false);
    });
  }
}
