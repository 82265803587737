import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'bindTemplate',
  pure: true
})
export class BindTemplatePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(text: string, data: { [key: string]: any; }): SafeHtml {
    if (text == null) {
      return text;
    }

    // eslint-disable-next-line no-param-reassign
    text = text.replace(/(\s*)#{(.*?)}/, (all, ws, key) => {
      if (data[key] == null || data[key] === '') {
        return '';
      }

      return ws + data[key];
    });

    return this.sanitizer.sanitize(SecurityContext.HTML, text);
  }
}
