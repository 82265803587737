import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { StepItemConfig } from '@sondermind/utilities/models-flows';
import { WizardService } from '@sondermind/flows-host';
import { IntakeStepComponent } from '../intake-step.component';

export interface SelectImageItem {
  label: string;
  value: string;
  src?: string;
  width?: string;
}

export interface SelectImageConfig extends StepItemConfig {
  name: string;
  cssClass: string;
  options: SelectImageItem[];
}

/**
 * @deprecated use BasicForm selectImages type instead -
 * this is still used in legacy configs and should not be removed
 */
@Component({
  selector: 'flows-intake-select-images',
  templateUrl: './intake-select-images.component.html',
  styleUrls: ['./intake-select-images.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
  ]
})
export class IntakeSelectImagesComponent extends IntakeStepComponent<SelectImageConfig> implements OnInit {
  selection: string;

  constructor(
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit() {
    this.selection = this.data[this.config.name];
    this.valid.next(this.validate());
  }

  flex(option: SelectImageItem) {
    if (option.width) {
      return `0 0 calc(${option.width} - 32px)`;
    }

    return `0 0 calc(50% - 32px)`;
  }

  get hasContext(): boolean {
    return !!this.wizard.context;
  }

  contextWithImg(option: SelectImageItem): boolean {
    return this.hasContext && !!option.src;
  }

  get imageItems(): SelectImageItem[] {
    let options = [];
    if (this.hasContext) {
      options = this.wizard.context;
    }

    if (this.config.options) {
      options = options.concat(this.config.options);
    }

    return options;
  }

  handleChange() {
    this.valid.next(this.selection !== null);
  }

  validate() {
    return this.selection != null;
  }

  onSubmit() {
    return [
      { human: this.config.name, key: this.config.name, value: this.selection }
    ];
  }
}
