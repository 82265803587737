import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

export { MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetatagService {
  constructor(
    private meta: Meta
  ){ }

  /**
   * This will create a tag. In the case a duplicate tag already exists, it will return the existing HTMLMetaElement.
   * @param tag { MetaDefinition }
   */
  addTag(tag: MetaDefinition): HTMLMetaElement | null {
    return this.meta.addTag(tag);
  }

  /**
   * This will remove a tag selected via its name.
   * @param name { string }
   */
  removeTagByName(name: string): void {
    this.meta.removeTag(`name="${name}"`);
  }
}
