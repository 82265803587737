import { Component, Input } from '@angular/core';
import { FlowResponse, StepItem } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'flows-intake-help-and-hero',
  templateUrl: './help-and-hero.component.html',
  styleUrls: [
    './help-and-hero.component.scss'
  ]
})
export class IntakeHelpAndHeroComponent {
  @Input() component: StepItem;
  @Input() response: FlowResponse;
}
