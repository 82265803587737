import { Injectable } from '@angular/core';
import {
  Observable, forkJoin, BehaviorSubject, of, Subject
} from 'rxjs';
import {
  tap, map, switchMap, catchError, startWith, filter,
  takeWhile
} from 'rxjs/operators';

import { UserSystemInfoService } from '@sondermind/utilities/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService, IEnvironmentConfig } from '@sondermind/configuration';
import { BrowserNavButtonsService, NavigationDirection } from '@sondermind/utilities/browser-nav-buttons';
import {
  IFlowsEndpoint,
  FlowsEndpointType,
  StepItem,
  StepConfig,
  FlowResponse,
  ResponseUpdateItem,
  StepItemConfig
} from '@sondermind/utilities/models-flows';

import { SentryClient } from '@sondermind/sentry-client';

import { FlowApiClient } from './wizard/flow-api-client';
import { ResponseApiClient } from './wizard/response-api-client';
import { getBrowsingAppTypeName } from './helpers';
import { FlowsError } from '../models/flows-error';

/**
 * @param {IEnvironmentConfig} config
 * @returns a flows endpoint configured to fetch from the intake service
 */
export function buildIntakeEndpoint(config: IEnvironmentConfig): IFlowsEndpoint {
  return {
    flows: `${config.intakeSvcBase}/flows`,
    response: `${config.svcBase}/flows`,
    type: FlowsEndpointType.INTAKE
  };
}

/**
 * @param {IEnvironmentConfig} config
 * @returns a flows endpoint configured to fetch from the monolith
 */
export function buildMonolithEndpoint(config: IEnvironmentConfig): IFlowsEndpoint {
  return {
    flows: `${config.svcBase}/flows`,
    response: `${config.svcBase}/flows`,
    type: FlowsEndpointType.MONOLITH
  };
}

export interface IInitializeOptions {
  pristine?: boolean;
  slug_unique?: string;
  endpoint_builder?: (config: IEnvironmentConfig) => IFlowsEndpoint;
}

export const MAIN_INTAKE_FLOW_SLUG = 'matching-flow-base';
export const NATIVE_ONBOARDING_FLOW_SLUG = 'matching-flow-native-onboarding';

export const INTAKE_FLOW_SLUGS = [
  MAIN_INTAKE_FLOW_SLUG,
  'matching-flow-base-short',
  'shareable-link-match-flow',
  'directory-matching-flow',
  NATIVE_ONBOARDING_FLOW_SLUG
];

const DEFAULT_OPTIONS: IInitializeOptions = {
  pristine: true,
  slug_unique: undefined,
  endpoint_builder: buildMonolithEndpoint
};

export const GENERIC_FLOWS_ERROR_MESSAGE = 'This page could not load. Refresh the page and try again.' +
'If the problem continues, check back in 5 minutes.';

@Injectable()
export class WizardService {
  private window = window;

  private slug: string;
  private version: string;
  private slug_unique = '';

  resetable = false; // can we reset?

  private initializedSubj = new BehaviorSubject<boolean>(false);
  private startedSubj = new BehaviorSubject<boolean>(false);
  private runningSubj = new BehaviorSubject<boolean>(false);
  private completedSubj = new BehaviorSubject<boolean>(false);

  private configSubj = new BehaviorSubject<StepConfig>(null);
  private responseSubj = new BehaviorSubject<FlowResponse>(null);
  private stepSubj = new BehaviorSubject<string>(null);
  private contextSubj = new BehaviorSubject<any | any[]>(null);
  private flowContainerRenderedSubj = new BehaviorSubject<boolean>(false);

  private browserNavButtonsService: BrowserNavButtonsService | undefined = undefined;

  initialized$ = this.initializedSubj.asObservable();
  started$ = this.startedSubj.asObservable();
  running$ = this.runningSubj.asObservable();
  completed$ = this.completedSubj.asObservable();

  config$ = this.configSubj.asObservable();
  response$ = this.responseSubj.asObservable();
  step$ = this.stepSubj.asObservable();
  context$ = this.contextSubj.asObservable();
  flowContainerRendered$ = this.flowContainerRenderedSubj.asObservable();

  errorSubject: Subject<HttpErrorResponse> = new Subject();
  showError$: Observable<HttpErrorResponse>;

  // assume off-modal as default
  isOffModalMatchflow = true;

  get initialized(): boolean { return this.initializedSubj.value }
  get started(): boolean { return this.startedSubj.value }
  get completed(): boolean { return this.completedSubj.value }
  get running(): boolean { return this.runningSubj.value }

  get config(): StepConfig { return this.configSubj.value }
  get response(): FlowResponse { return this.responseSubj.value }
  get step(): string { return this.stepSubj.value }
  get context() { return this.contextSubj.value }

  get component(): StepItem<StepItemConfig> {
    if (this.config == null || this.step == null) {
      return null;
    }

    return this.config.steps.find((c) => c.slug === this.step);
  }

  get shouldSignalCompletion(): boolean {
    return [
      MAIN_INTAKE_FLOW_SLUG,
      NATIVE_ONBOARDING_FLOW_SLUG
    ].includes(this.response.slug);
  }

  constructor(
    private configurationService: ConfigurationService,
    private userSystemInfoSvc: UserSystemInfoService,
    private flowSvc: FlowApiClient,
    private responseSvc: ResponseApiClient
  ) {
    this.showError$ = this.errorSubject.asObservable().pipe(startWith(null));
  }

  // initializes the service to run a wizard from the given flow slug
  initialize(
    slug: string,
    options: IInitializeOptions = DEFAULT_OPTIONS,
    hash: string = null,
    strict = false
  ): Observable<void> {
    const { pristine, slug_unique, endpoint_builder } = { ...DEFAULT_OPTIONS, ...options };
    this.errorSubject.next(null);

    this.showError$.pipe(takeWhile(() => !this.completed)).subscribe((error: HttpErrorResponse | FlowsError) => {
      // SentryClient will have been initialized via LoggingInitModule
      // don't want to log `null`, emitted when error is "cleared"
      if (!error) return;

      if (error.name === 'FlowsError') {
        SentryClient.captureException(error, error.context);
      } else {
        SentryClient.captureException(error);
      }
    });

    return this.configurationService.configSubj.pipe(
      filter((e) => !!e && Object.keys(e).length !== 0),
      switchMap((config) => {
        // build up the services, so we can use options to create
        const endpoint = endpoint_builder(config);

        this.flowSvc.setEndpoint(endpoint);
        this.responseSvc.setEndpoint(endpoint);
        return this.flowSvc.fetch(slug, hash, strict);
      }),
      switchMap((f) => forkJoin([
        of(f),
        this.responseSvc.fetch(f.slug, f.version, { autocreate: false, pristine, slug_unique })
      ])),
      tap(([flow, resp]) => {
        this.slug = flow.slug;
        this.version = flow.version;
        this.slug_unique = slug_unique;

        const hasData = resp && Object.keys(resp.data).length > 0;
        this.startedSubj.next(hasData);
        this.resetable = hasData;

        this.configSubj.next(flow.config);
        this.initializedSubj.next(true);
      }),
      // disabling no-invalid-void-type check since this is intentional use of void
      // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
      map(() => undefined as void),
      catchError((error: HttpErrorResponse) => {
        this.errorSubject.next(error);
        return of();
      })
    );
  }

  // called to enter the flow, regardless of prior state
  start(initialData: ResponseUpdateItem[] = []): Observable<void> {
    // add flow interaction source info to response data
    initialData.push({
      human: 'Response Submission Channel',
      key: 'submission_channel',
      value: getBrowsingAppTypeName()
    });

    // get the value of the call component flag
    const callComponentLDFlag = initialData.filter((x) => x.key === 'callComponent')[0]?.value as boolean ?? false;

    this.errorSubject.next(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.responseSvc
      .fetch(this.slug, this.version, { slug_unique: this.slug_unique })
      .pipe(
        switchMap((r) => {
          const step = this.findCurrentStep(this.config, r, callComponentLDFlag);
          const initialResponseSvcData = this.getResponseItemsWithUserSystemInfo(initialData).concat(
            this.parseQueryParamsToResponseUpdateItems()
          );

          return this.responseSvc.enter(r, step, initialResponseSvcData);
        }),
        tap((update) => {
          const step = this.findCurrentStep(this.config, update.data.response, callComponentLDFlag);

          this.startedSubj.next(true);
          this.resetable = Object.keys(update.data.response.data).length > initialData.length;
          this.responseSubj.next(update.data.response);
          this.contextSubj.next(update.data.context);
          this.stepSubj.next(step);

          this.browserNavButtonsService?.recordFlowsWizardStart(step);
        }),
        map(() => undefined),
        catchError((error: HttpErrorResponse) => {
          this.errorSubject.next(error);
          return of();
        })
      );
  }

  parseQueryParamsToResponseUpdateItems(): ResponseUpdateItem[] {
    const urlParams = new URLSearchParams(this.window.location.search);

    const result: ResponseUpdateItem[] = [];

    if (urlParams.has('anonUserId')) {
      result.push({
        human: 'marketingSiteLaunchDarklyKey',
        key: 'marketingSiteLaunchDarklyKey',
        value: urlParams.get('anonUserId')
      });
    }

    if (urlParams.has('referringUrl')) {
      result.push({
        human: 'Referring URL',
        key: 'referringUrl',
        value: decodeURIComponent(urlParams.get('referringUrl'))
      });
    }

    if (urlParams.has('state')) {
      result.push({
        human: 'Location',
        key: 'location',
        value: {
          address: urlParams.get('state').concat(', USA')
        }
      });
    }

    if (urlParams.has('modalities')) {
      result.push({
        human: 'modalities',
        key: 'modalities',
        value: urlParams.get('modalities').split(',').map((x) => parseInt(x, 10))
      });
    }

    if (urlParams.has('specialties')) {
      result.push({
        human: 'reason',
        key: 'reason',
        value: urlParams.get('specialties').split(',')
      });
    }

    // payment pref
    if (urlParams.has('paymentPref')) {
      result.push({
        human: 'paymentPref',
        key: 'paymentPref',
        value: urlParams.get('paymentPref')
      });
    }

    // if insurance
    if (urlParams.get('paymentPref') === 'insurance') {
      result.push({
        human: 'insurancePref',
        key: 'insurancePref',
        value: [urlParams.get('paymentTag')]
      });
    }

    // if eap
    if (urlParams.get('paymentPref') === 'eap') {
      result.push({
        human: 'eapPref',
        key: 'eapPref',
        value: urlParams.get('paymentTag')
      });
    }

    // if medicare
    if (urlParams.get('paymentPref') === 'medicare_medicare_advantage') {
      result.push({
        human: 'medicarePref',
        key: 'medicarePref',
        value: urlParams.get('paymentTag')
      });
    }

    return result;
  }

  // sends the given updates to the server and moves on to the next step
  next(items: ResponseUpdateItem[]): Observable<void> {
    return this.responseSvc
      .leave(this.response, this.step, items)
      .pipe(
        switchMap((update) => {
          const next = this.findNextStep(update.data.status, this.component, update.data.response);
          return forkJoin([
            of(next),
            this.responseSvc.enter(update.data.response, next, [])
          ]);
        }),
        tap(([next, update]) => {
          this.resetable = false;
          this.errorSubject.next(null);

          this.responseSubj.next(update.data.response);
          this.contextSubj.next(update.data.context);
          this.stepSubj.next(next);

          this.browserNavButtonsService?.processFlowsWizardNavigation(next, NavigationDirection.NEXT);
        }),
        // disabling no-invalid-void-type check since this is intentional use of void
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        map(() => undefined as void),
        catchError((error: HttpErrorResponse) => {
          this.errorSubject.next(error);
          return of();
        })
      );
  }

  // leave the current step, and enter the previous one in the stack
  back(): Observable<void> {
    if (!this.response || this.response.path.length < 2) {
      return of(undefined);
    }

    const last = this.response.path[this.response.path.length - 2];
    return this.responseSvc
      .back(this.response)
      .pipe(
        switchMap((update) => forkJoin([
          of(last),
          this.responseSvc.enter(update.data.response, last, [])
        ])),
        // eslint-disable-next-line @typescript-eslint/no-shadow
        tap(([last, update]) => {
          this.resetable = false;
          this.errorSubject.next(null);
          this.responseSubj.next(update.data.response);
          this.contextSubj.next(update.data.context);
          this.stepSubj.next(last);

          this.browserNavButtonsService?.processFlowsWizardNavigation(last, NavigationDirection.BACK);
        }),
        // disabling no-invalid-void-type check since this is intentional use of void
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        map(() => undefined as void),
        catchError((error: HttpErrorResponse) => {
          this.errorSubject.next(error);
          return of();
        })
      );
  }

  canBack(): boolean {
    return this.response && this.response.path.length > 1;
  }

  // reset the extant data, pause the flow, and mark complete
  complete(): Observable<void> {
    return of(undefined)
      .pipe(tap(() => {
        this.stepSubj.next(this.step);
        this.completedSubj.next(true);
        this.toggleRunning(false);
      }));
  }

  resendEmail(): Observable<void> {
    return this.responseSvc
      .resend(this.response.key)
      .pipe(
        tap(() => {
          this.stepSubj.next(this.step);
          this.completedSubj.next(true);
        }),
        // disabling no-invalid-void-type check since this is intentional use of void
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        map(() => undefined as void),
      );
  }

  // exit is the same as pause for now
  exit(): Observable<void> {
    return of(undefined).pipe(
      tap(() => this.toggleRunning(false))
    );
  }

  // save the response and exit the flow
  exitAndSave(items: ResponseUpdateItem[]): Observable<void> {
    return this.responseSvc
      .leave(this.response, this.step, items)
      .pipe(
        // disabling no-invalid-void-type check since this is intentional use of void
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        map(() => undefined as void),
        tap(() => this.toggleRunning(false)),
        catchError((error: HttpErrorResponse) => {
          this.errorSubject.next(error);
          return of();
        })
      );
  }

  // reset the response values, re-initialize and start over
  reset(): Observable<void> {
    this.responseSvc.reset(this.slug, this.version);
    return this.start().pipe(
      tap(() => this.toggleRunning(true))
    );
  }

  // mark whether we're running or not
  toggleRunning(run?: boolean): void {
    if (run == null) {
      this.runningSubj.next(!this.running);
    } else if (run !== this.running) {
      this.runningSubj.next(run);
    }
  }

  findCurrentStep(config: StepConfig, resp: FlowResponse, callComponentLDFlag: boolean): string {
    if (resp.path.length > 0) {
      return resp.path[resp.path.length - 1];
    }

    // If the LD flag is true, then start with the splash screen
    // match-flow-call-component
    if (resp.slug === 'matching-flow-base' && callComponentLDFlag) {
      return 'splash-screen';
    }

    // default is to return the start state, or the first component when poorly configured
    return config.steps[0].slug;
  }

  private findNextStep(status: string, component: StepItem, resp: FlowResponse): string {
    // simplest makes no distinction
    if (typeof component.next === 'string') {
      return component.next;
    }

    // otherwise it's by update status
    const next = component.next[status];
    if (typeof next === 'string') {
      return next;
    }

    // possibly it's also based on incoming data
    const slug = next?.options[resp.data[next.name]];
    if (slug) {
      return slug;
    }

    // no idea where we're supposed to be, don't move I guess?
    throw new FlowsError('Unknown next step!', { extra: { status, resp, slug } });
  }

  /**
   * Given a list of `ResponseUpdateItem`'s, creates a copy that
   * includes a `ResponseUpdateItem` object containing user system information.
   * (Note: this function returns a shallow copy of `itemListToAugment`.)
   * @param itemListToAugment The list of `ResponseUpdateItem`'s to augment with user system information.
   */
  private getResponseItemsWithUserSystemInfo(itemListToAugment: ResponseUpdateItem[]): ResponseUpdateItem[] {
    const USER_SYSTEM_INFO_KEY: string = 'userSystemInfo';

    /* create a shallow copy of itemListToAugment (if it exists)
    so we can safely inject user system info without affecting itemListToAugment;
    otherwise create a new empty array */
    const itemListWithSystemInfo = itemListToAugment?.slice(0) ?? [];

    // inject user system info into itemListToAugment only if it does not already include it
    if (!itemListWithSystemInfo.some((resUpdateItem) => resUpdateItem?.key === USER_SYSTEM_INFO_KEY)) {
      itemListWithSystemInfo.unshift(
        {
          human: USER_SYSTEM_INFO_KEY,
          key: USER_SYSTEM_INFO_KEY,
          value: this.userSystemInfoSvc.userSystemInfo
        }
      );
    }

    return itemListWithSystemInfo;
  }

  setFlowContainer(): void {
    this.flowContainerRenderedSubj.next(true);
  }

  unsetFlowContainer(): void {
    this.flowContainerRenderedSubj.next(false);
  }

  attachBrowserButtonService(svc: BrowserNavButtonsService): void {
    this.browserNavButtonsService = svc;
  }

  detachBrowserButtonService(): void {
    this.browserNavButtonsService = null;
  }
}
