import { Component, Input } from '@angular/core';
import { FlowResponse, StepItem } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'flows-helptext',
  templateUrl: './helptext.component.html',
  styleUrls: [
    './helptext.component.scss',
    '../../../styles/flows-style-classes.scss'
  ]
})
export class HelptextComponent {
  @Input() component: StepItem;
  @Input() response: FlowResponse;
}
