<div class="host-router flowsHostPanel sonder-formlib-light-background">
  <div
    class="flows-router-spinner"
    *ngIf="wizardSvc.response?.slug == null; else flowHost"
  >
    <div class="flow-spinner-container">
      <mat-spinner
        diameter="88"
        strokeWidth="6"
        aria-label="Loading Match Flow"
      ></mat-spinner>
    </div>
  </div>
  <ng-template #flowHost>
    <ng-container *ngIf="!isIntakeHost; else intakeHost">
      <flows-host></flows-host>
    </ng-container>
    <ng-template #intakeHost>
      <flows-intake-host></flows-intake-host>
    </ng-template>
  </ng-template>
</div>
