import { Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

@Pipe({ name: 'formatTimeAgo' })

export class FormatTimeAgoPipe implements PipeTransform {
  transform(value: Date | string, omitSuffix: boolean = false): string {
    if (value != null) {
      let date = null;
      if (value instanceof Date) {
        date = value;
      } else {
        date = new Date(value);
      }

      return `${formatDistanceToNow(date)} ${omitSuffix ? '' : 'ago'}`;
    }
    return '';
  }
}
