import { Component, OnInit } from '@angular/core';

import { StepItemConfig } from '@sondermind/utilities/models-flows';
import { StepComponent } from '../step.component';
import { WizardService } from '../../../services/wizard.service';

export interface MultiStepReviewConfig extends StepItemConfig {
  reviewItems: Array<{
    sectionHeader: string;
    sectionItems: Array<{
      formName: string;
      label?: string;
      childFormName?: string;
      value?: string;
    }>;
  }>;
}

@Component({
  selector: 'flows-multi-step-review',
  templateUrl: './multi-step-review.component.html',
  styleUrls: ['./multi-step-review.component.scss']
})
export class MultiStepReviewComponent extends StepComponent<MultiStepReviewConfig> implements OnInit {
  reviewItemSections: Array<{
    header: string;
    items: string[];
  }> = [];

  constructor(
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit() {
    this.reviewItemsInit();
  }

  onSubmit() {
    return [];
  }

  /**
   *
   * Sort review items into groups for display:
   * if data exists at valueKeyPath, item will show with following conditons:
   * if displayValue exists, it will show
   * if displayKeyPath exists but not displayValue, the value of the item at displayValue will show
   *
   * if listValue exists, that means the item at valueKeyPath is expected to be a list
   * if the listValue is in that list, the item will show
   */
  reviewItemsInit(): void {
    this.reviewItemSections = [];

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.config.reviewItems) {
      // eslint-disable-next-line no-restricted-syntax
      for (const section of this.config.reviewItems) {
        const header = section.sectionHeader;
        const sectionItems = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of section.sectionItems) {
          const valueFromFlow = this.parseKeysToValue(item.formName);

          if (valueFromFlow != null) {
            let valueOfItem;

            if ((item.value != null && valueFromFlow?.includes(item.value)) || item.value == null) {
              if (item.label != null) {
                valueOfItem = item.label;
              } else if (item.childFormName != null) {
                valueOfItem = this.parseKeysToValue(item.childFormName);
              } else {
                valueOfItem = valueFromFlow;
              }
            }

            if (valueOfItem != null) {
              sectionItems.push(valueOfItem);
            }
          }
        }

        if (sectionItems.length > 0) {
          this.reviewItemSections.push({
            header,
            items: sectionItems
          });
        }
      }
    }
  }

  parseKeysToValue(keys: string): string | string[] | null {
    const keyList = keys.split('.');
    let value = this.data[keyList.shift()];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of keyList) {
      if (value != null) {
        value = value[key];
      }
    }

    if (value == null) {
      return null;
    }

    return value;
  }
}
