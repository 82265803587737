import {
  Component, ContentChild, NgZone, ChangeDetectorRef
} from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { MatLegacyInput } from '@angular/material/legacy-input';

import { FormFieldComponent } from '../form-field/form-field.component';
import { BreakpointsService } from '../../services/breakpoints.service';

@Component({
  selector: 'sonder-form-optin',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss']
})
export class OptInComponent extends FormFieldComponent {
  @ContentChild(NgModel) override model: NgModel = {} as NgModel;
  @ContentChild(MatLegacyInput) override input: MatLegacyInput = {} as MatLegacyInput;

  constructor(
    form: NgForm,
    breakpoint: BreakpointsService,
    zone: NgZone,
    cdr: ChangeDetectorRef
  ) {
    super(form, cdr, zone, breakpoint);
  }
}
