<flows-header
  [@fadeHeader]="wizard.running$ | async"
  sondermindResizeObserver
  (backClicked)="handleBack()"
  [back]="wizard.canBack()"
  [fullscreen]="fullScreen"
></flows-header>
<div
  #scrollContainer
  sondermindMediaQueryAttribute
  class="flows-host-backdrop"
  [ngClass]="overrideClass"
  [@fade]="wizard.running$ | async"
  (@fade.done)="fadeComplete()"
  data-test="flows-header-text"
>
  <div class="flow-spinner" *ngIf="wizard.started !== true">
    <div class="flow-spinner-container">
      <mat-spinner
        color="primary"
        diameter="88"
        strokeWidth="6"
        aria-label="Loading Match Flow"
      ></mat-spinner>
    </div>
  </div>
  <div #flowContainer>
    <div
      *ngIf="data$ | async as data"
      class="flows-host-container mat-typography"
      sonderMediaqueryClass="mq"
      [ngSwitch]="data.component.config.fullscreen"
    >
      <div *ngSwitchCase="true" class="flows-host-child flows-host-fullscreen">
        <flows-step-host
          [component]="data.component"
          [response]="data.response"
          [resetable]="wizard.resetable"
          (stepNext)="wizard.next($event)"
          (stepBack)="wizard.back()"
          (stepExit)="wizard.exit()"
          (stepReset)="wizard.reset()"
          cdkTrapFocus
          [busy]="busy"
        ></flows-step-host>
      </div>

      <div
        *ngSwitchDefault
        class="flows-host-child flows-host-wizard"
      >
        <div class="flows-host-body">
          <flows-helptext
            [component]="data.component"
            [response]="data.response"
          ></flows-helptext>
          <flows-step-host
            [component]="data.component"
            [response]="data.response"
            [resetable]="wizard.resetable"
            cdkTrapFocus
            [busy]="busy"
          ></flows-step-host>
        </div>
      </div>
    </div>
  </div>
</div>
