<div data-test="flows-pause">
  <div
    class="pause-image"
    [style.text-align]="imageAlignment"
  >
    <img
      alt="pause image"
      [src]="imageUrl"
    >
  </div>
  <ng-container *ngIf="dynamicHeaderText || dynamicBodyText">
    <h2 class="pause-page-header">{{ dynamicHeaderText | bindTemplate:response.data }}</h2>
    <p
      *ngFor="let textEntry of dynamicBodyText"
      class="pause-page-help"
      [innerHTML]="textEntry | bindTemplate:response.data"
    >
    </p>
  </ng-container>
  <div
    *ngIf="infoText"
    class="pause-page-info-text"
    [innerHTML]="infoText | bindTemplate:response.data"
  ></div>
</div>
