import { ErrorHandler, NgModule } from '@angular/core';
import { LocalStorageModule } from '@sondermind/local-storage';
import { DatadogLoggingModule } from '@sondermind/datadog-logging';
import { AppErrorHandler } from './error-handling.service';

@NgModule({
  imports: [
    DatadogLoggingModule,
    LocalStorageModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ]
})
export class ErrorHandlingModule {}
