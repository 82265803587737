<form
  #form="ngForm"
  class="decline-theme formContainer"
  (submit)="submit.emit()"
  sonderMediaqueryClass="mq"
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  fxLayoutGap="0px"
  fxLayoutGap.gt-sm="32px"
>
  <ng-container *ngFor="let item of this.formConfig" >
    <ng-container *ngIf="shouldShowItem(item.name)">
      <ng-container [ngSwitch]="item.type">

        <sonder-form-optin
          *ngSwitchCase="'disclosure'"
          fxFlex.lt-md="1 1 100%"
          [fxFlex.gt-sm]="flex(item)"
        >
          <mat-checkbox
            matInput
            color="primary"
            [(ngModel)]="value[item.name]"
            [name]="item.name"
            [required]="item|requiredField"
          >
            {{ item.label }}
          </mat-checkbox>
        </sonder-form-optin>

        <sonder-checkbox-multi
          *ngSwitchCase="'checkbox'"
          fxFlex.lt-md="1 1 100%"
          [fxFlex.gt-sm]="flex(item)"
          [ngModelGroup]="item.name"
          [label]="item.label"
        >
            <ng-container *ngFor="let option of item.options">
                <mat-checkbox
                  matInput
                  color="primary"
                  [(ngModel)]="value[item.name][option.value]"
                  [name]="option.value"
                  [disabled]="limitReached && !value[item.name][option.value]"
                  [attr.data-test]="'select-urgent-questions-checkbox-' + option.value"
                >
                <span
                  [innerHTML]="option.label">
                </span>
                </mat-checkbox>
            </ng-container>
        </sonder-checkbox-multi>

        <div *ngSwitchCase="'selectBasic'" class="basic-select">
          <div *ngIf="!isItemHidden(item)">
            {{ item.header }}
            <ng-container *ngFor="let option of item.options">
              <mat-checkbox
                matInput
                color="primary"
                [name]="option.value"
                [(ngModel)]="value[item.name][option.value]"
                class="iris-body-responsive"
              >
              <span>
                {{ option.label }}
              </span>
              </mat-checkbox>
            </ng-container>
          </div>
        </div>

        <div class="options-select-column-wrapper no-bottom-margin complex-checkbox-group-wrapper">
          <sonder-checkbox-multi
            *ngSwitchCase="'complexCheckbox'"
            fxFlex.lt-md="1 1 100%"
            [fxFlex.gt-sm]="flex(item)"
            [ngModelGroup]="item.name"
            [label]="item.label"
          >
            <ng-container *ngFor="let option of item.options">
              <mat-checkbox
                matInput
                color="primary"
                [(ngModel)]="value[item.name][option.value]"
                [name]="option.value"
                *ngIf="option.value !== 'ncc'"
                [attr.data-test]="'complex-checkbox-option-' + option.value"
              >
                {{ option.label }}
                <p
                  *ngIf="option.helperText"
                  class="complex-checkbox-helper-text"
                >
                  {{ option.helperText }}
                </p>
              </mat-checkbox>
              <div
                *ngIf="option.value === 'ncc'"
                class="complex-checkbox-checked-wrapper mat-checkbox-layout"
              >
                <iris-icon
                  name="check"
                  [color]="iconColors.BRAND1"
                  [size]="IconSizes.MD"
                  [usage]="IconUsageTypes.DECORATIVE"
                ></iris-icon>
                <span class="complex-checkbox-label" data-test="secure-message-option">
                  <span>
                    {{ option.label }}
                  </span>
                  <p
                    *ngIf="option.helperText"
                    class="complex-checkbox-helper-text"
                    data-test="secure-message-text"
                  >
                    {{ option.helperText }}
                  </p>
                </span>
              </div>
            </ng-container>
            <div
              *ngIf="component.config.reminderText"
              class="iris-h4-responsive reminderText"
              data-test="reminder-text"
            >
             {{ component.config.reminderText }}
            </div>
          </sonder-checkbox-multi>
        </div>

        <sonder-form-rating
          *ngSwitchCase="'rating'"
          [(rating)]="value[item.name]"
          [ratingConfig]="item"
          [required]="item|requiredField"
          fxFlex.lt-md="1 1 100%"
          [fxFlex.gt-sm]="flex(item)"
        >
        </sonder-form-rating>

        <div class="options-select-column-wrapper no-bottom-margin">
          <sonder-form-radio-group
            *ngSwitchCase="'radiogroup'"
            [label]="item.label"
            fxFlex.lt-md="1 1 100%"
            [fxFlex.gt-sm]="flex(item)"
            data-test="form-radio-group"
          >
            <mat-radio-group
              [ngClass]="(!(isMobile$ | async) && item.forceTwoColumnDesktop) ? 'options-select-column-wrapper-desktop' : 'options-select-column-wrapper-mobile'"
              matInput
              [(ngModel)]="value[item.name]"
              [name]="item.name"
              [required]="item|requiredField"
              (input)="onRadioSelect($event.target)"
            >
              <ng-container *ngFor="let option of item.options">
                <mat-radio-button
                  color="primary"
                  [value]="option.value"
                  [ngClass]="{
                    'helper-text-visible': option.helperText && option.value === value[item.name]
                  }"
                  [ngStyle]="{'grid-column' : (option.width == '100%') ? 'span 2' : 'span 1'}"
                  [attr.data-test]="'form-radio-group-option-' + option.value"
                >
                  {{ option.label }}
                </mat-radio-button>
                <p
                  *ngIf="option.helperText && option.value === value[item.name]"
                  class="radio-helper-text"
                  data-test="radio-helper-text"
                >
                  {{ option.helperText }}
                </p>
              </ng-container>
            </mat-radio-group>
          </sonder-form-radio-group>
        </div>

        <div class="options-select-column-wrapper no-bottom-margin complex-radio-group-wrapper">
          <sonder-form-radio-group
            *ngSwitchCase="'complex-radiogroup'"
            [label]="item.label"
            fxFlex.lt-md="1 1 100%"
            [fxFlex.gt-sm]="flex(item)"
            data-test="form-radio-group"
          >
            <mat-radio-group
              [ngClass]="(!(isMobile$ | async) && item.forceTwoColumnDesktop) ? 'options-select-column-wrapper-desktop' : 'options-select-column-wrapper-mobile'"
              matInput
              [(ngModel)]="value[item.name]"
              [name]="item.name"
              [required]="item|requiredField"
            >
              <ng-container *ngFor="let option of item.options">
                <mat-radio-button
                  color="primary"
                  [value]="option.value"
                  [ngStyle]="{'grid-column' : (option.width == '100%') ? 'span 2' : 'span 1'}"
                  [attr.data-test]="'form-radio-group-option-' + option.value"
                >
                  {{ option.label }}
                  <p
                    *ngIf="option.helperText"
                    class="complex-radio-helper-text"
                  >
                    {{ option.helperText }}
                  </p>
                </mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </sonder-form-radio-group>
        </div>

        <sonder-form-radio-group
          *ngSwitchCase="'refdata-radiogroup'"
          [label]="item.label"
          fxFlex.lt-md="1 1 100%"
          [fxFlex.gt-sm]="flex(item)"
        >
          <mat-radio-group
            matInput
            [(ngModel)]="value[item.name]"
            [name]="item.name"
            [required]="item|requiredField"
          >
            <mat-radio-button
              *ngIf="item.nullOptionLabel"
              color="primary"
              [value]="null"
              data-test="no-preference-option"
            >
              {{ item.nullOptionLabel }}
            </mat-radio-button>
            <ng-container *ngFor="let option of (refdataList(item.refdataType, item.skipOptions) | async)">
              <mat-radio-button
                color="primary"
                [value]="option.id"
                [attr.data-test]="'form-refdata-radio-option-' + option.title"
              >
                {{ option.title }}
              </mat-radio-button>
              <p
                *ngIf="option.id === value[item.name] && (option.title | refdataHelpText: $any(item.refdataHelpText)) as helpText"
                class="radio-helper-text"
                data-test="radio-helper-text"
              >
                {{ helpText }}
              </p>
            </ng-container>
          </mat-radio-group>
        </sonder-form-radio-group>

        <sonder-form-field
          *ngSwitchCase="'select'"
          class="basic-form-field-full"
          [label]="item.label"
          [required]="item|requiredField"
        >
          <mat-select
            *ngSwitchCase="'select'"
            #selectField
            [(ngModel)]="value[item.name]"
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
          >
            <mat-option *ngFor="let option of item.options" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </sonder-form-field>

        <sonder-form-field
          *ngSwitchCase="'refdata-select'"
          class="basic-form-field-full"
          [label]="item.label"
          [required]="item|requiredField"
        >
          <mat-select
            #selectField
            [(ngModel)]="value[item.name]"
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            data-test="gender-dropdown"
          >
            <mat-option
              *ngFor="let option of (refdataList(item.refdataType, item.skipOptions) | async)"
              color="primary"
              [value]="option.id"
              [attr.data-test]="'gender-dropdown-option-' + option.title"
            >
              {{ option.title }}
            </mat-option>
          </mat-select>
        </sonder-form-field>

        <flows-basic-form-nps-score
          *ngSwitchCase="'npsScore'"
          [(ngModel)]="value[item.name]"
          [name]="item.name"
          [required]="item|requiredField"
          fxFlex.lt-md="1 1 100%"
          [fxFlex.gt-sm]="flex(item)"
        >
        </flows-basic-form-nps-score>

        <sonder-therapy-reason
          *ngSwitchCase="'therapyReason'"
          [(ngModel)]="value[item.name]"
          [formValue]="value"
          [name]="item.name"
          [required]="item|requiredField"
          [optionList]="$any(item.options)"
          [isMobile]="(isMobile$ | async)"
          [maxItemsAllowed]="config.maxItemsAllowed"
          [maxAllowedInfoText]="config.maxAllowedInfoText"
        >
        </sonder-therapy-reason>

        <sonder-multi-section-checkboxes
          *ngSwitchCase="'multiSectionCheckboxes'"
          [(ngModel)]="value[item.name]"
          [formValue]="value"
          [name]="item.name"
          [required]="item|requiredField"
          [sectionList]="item.sections"
          [isMobile]="(isMobile$ | async)"
          [maxItemsAllowed]="item.maxItemsAllowed"
          [maxAllowedInfoText]="item.maxAllowedInfoText"
        >
        </sonder-multi-section-checkboxes>

        <div
          *ngSwitchCase="'selectFancy'"
          class="decline-theme"
          fxLayout="column"
          fxLayoutGap="32px"
        >
          <div
            sondermindMediaQueryAttribute
            class="option-container"
            fxLayout="row wrap"
            [ngModelGroup]="item.name"
          >
            <sonder-option-disc
              *ngFor="let option of item.options"
              class="option-disc"
              [label]="option.label | bindTemplate:response.data"
            >
              <input
                type="checkbox"
                tabindex="0"
                [(ngModel)]="value[item.name][option.value]"
                [name]="option.value"
                [value]="true"
                (change)="handleFancySelectionChange(item, option)"
              >
            </sonder-option-disc>
          </div>
        </div>

        <div
          *ngSwitchCase="'selectPills'"
          class="decline-theme"
          fxLayout="column"
          fxLayoutGap="32px"
        >
          <div
            sondermindMediaQueryAttribute
            class="option-container chip-container"
            [ngClass]="{ 'full-width': !(isMobile$ | async) }"
            fxLayout="row wrap"
          >
            <mat-chip-list
              selectable multiple
              #pillList
            >
              <mat-chip
                #chip="matChip"
                sondermind-secondary
                class="sondermind-design-system"
                *ngFor="let option of item.options"
                [name]="option.value"
                [(ngModel)]="value[item.name][option.value]"
                ngDefaultControl
                (click)="handlePillSelectionChange(item, option, chip)"
              >
                {{ option.label | bindTemplate:response.data }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>

        <div
          *ngSwitchCase="'multiSelectImages'"
          class="decline-theme full-width"
          fxLayout="column"
          fxLayoutGap="32px"
        >
          <div class="options-select-column-wrapper">
            <div [ngClass]="!(isMobile$ | async) ? 'options-select-column-wrapper-desktop' : 'options-select-column-wrapper-mobile'">
              <sonder-option-checkbox
                *ngFor="let option of imageItems"
              >
                <mat-checkbox
                  matInput
                  color="primary"
                  [(ngModel)]="option.selectValue"
                  [name]="option.value"
                  (ngModelChange)="handleMultiImageSelectionChange(item, option)"
                  [attr.data-test]="'multi-select-images-option-' + option.value"
                >
                </mat-checkbox>
                <span class="multi-select-images-without-img-label">
                  {{ option.label }}
                </span>
              </sonder-option-checkbox>
            </div>
          </div>
        </div>

        <div
          *ngSwitchCase="'selectImages'"
          class="decline-theme full-width"
          fxLayout="column"
          fxLayoutGap="32px"
        >
          <div class="options-select-column-wrapper">
            <sonder-form-radio-group
              class="options-select-column-wrapper"
              [label]="item.label"
            >
              <mat-radio-group
                [ngClass]="!(isMobile$ | async) ? 'options-select-column-wrapper-desktop' : 'options-select-column-wrapper-mobile'"
                matInput
                [(ngModel)]="value[item.name]"
                [name]="item.name"
                [required]="item|requiredField"
              >
                <mat-radio-button
                  *ngFor="let option of imageItems"
                  color="primary"
                  [value]="option.value"
                  [ngStyle]="{'grid-column' : (option.width == '100%') ? 'span 2' : 'span 1'}"
                  [attr.data-test]="'select-images-option-' + option.value"
                >
                  <span class="multi-select-images-without-img-label">
                    {{ option.label }}
                  </span>
                </mat-radio-button>
              </mat-radio-group>
            </sonder-form-radio-group>
          </div>
        </div>

        <sonder-form-field
          *ngSwitchCase="'geolocation'"
          class="basic-form-field-full"
          [label]="item.label"
          [required]="item|requiredField"
        >
          <input
            matInput
            googleMapsAutoComplete
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            [(ngModel)]="value[item.name]"
            (change)="invalidateFormForManualAddressChange()"
            (onAutoCompleteSelected)="locationSelected($event)"
            data-hj-suppress
          >
          <mat-icon matSuffix class="margin-right-15">add_location</mat-icon>
        </sonder-form-field>

        <sonder-form-field
          *ngSwitchCase="'geolocationStreetAddr'"
          class="basic-form-field-full"
          [label]="item.label"
          [required]="item|requiredField"
          [helperText]="item.helperText"
        >
          <input
            matInput
            googleMapsAutoComplete
            [autocompleteParams]="{ componentRestrictions: { country: 'us' }, types: ['address'] }"
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            [(ngModel)]="value[item.name]"
            (change)="invalidateFormForManualAddressChange()"
            (onAutoCompleteSelected)="locationSelected($event)"
            data-hj-suppress
          >
          <mat-icon matSuffix class="margin-right-15">add_location</mat-icon>
        </sonder-form-field>

        <ng-container *ngSwitchCase="'smsOptin'">
          <sonder-form-field
            class="basic-form-field-full sms-optin"
            [label]="item.label"
            [description]="item.description"
          >
            <input
              matInput
              [(ngModel)]="value[item.name]"
              type="tel"
              [name]="item.name"
              [textMask]="phoneFieldOptions"
              [pattern]="phoneFieldOptions.pattern"
              [required]="item|requiredField"
              [placeholder]="placeholder(item)"
              data-test="phone-input"
            >
          </sonder-form-field>
          <sonder-form-optin>
            <mat-checkbox
              class="optin-checkbox"
              name="optin"
              color="primary"
              matInput
              [(ngModel)]="mobileOptin"
              value="true"
              data-test="sms-opt-in-checkbox"
            >
              {{ item.optinText }}
            </mat-checkbox>
          </sonder-form-optin>
        </ng-container>

        <ng-container *ngSwitchCase="'emailOptin'">
          <sonder-form-optin>
            <mat-checkbox
              class="optin-checkbox"
              [name]="item.name"
              color="primary"
              matInput
              [(ngModel)]="emailOptin"
              value="true"
              data-test="email-opt-in-checkbox"
            >
              {{ item.optinText }}
            </mat-checkbox>
          </sonder-form-optin>
        </ng-container>

        <sonder-form-field
          *ngSwitchDefault
          class="basic-form-field-full"
          [ngSwitch]="item.type"
          [type]="item.type"
          [label]="item.label"
          [description]="item.description"
          [required]="item|requiredField"
        >
          <input
            *ngSwitchCase="'text'"
            matInput
            [(ngModel)]="value[item.name]"
            type="text"
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            [attr.data-test]="'basic-form-input-' + item.name"
          >
          <input
            *ngSwitchCase="'email'"
            matInput
            [(ngModel)]="value[item.name]"
            type="email"
            [name]="item.name"
            [pattern]="emailFieldOptions.pattern"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            data-test="basic-form-email-input"
          >
          <input
            *ngSwitchCase="'phone'"
            matInput
            [(ngModel)]="value[item.name]"
            type="tel"
            [name]="item.name"
            [textMask]="phoneFieldOptions"
            [pattern]="phoneFieldOptions.pattern"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            data-test="basic-form-phone-input"
          >
          <input
            *ngSwitchCase="'zipcode'"
            matInput
            [(ngModel)]="value[item.name]"
            type="text"
            [name]="item.name"
            [textMask]="zipFieldOptions"
            [pattern]="zipFieldOptions.pattern"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
          >

          <textarea
            *ngSwitchCase="'textarea'"
            data-hj-suppress
            rows="5"
            matInput
            [(ngModel)]="value[item.name]"
            type="text"
            [name]="item.name"
            [required]="item|requiredField"
            [placeholder]="placeholder(item)"
            (input)="onTextareaChange($event)"
          ></textarea>

          <mat-slider
            *ngSwitchCase="'slider'"
            [(ngModel)]="value[item.name]"
            [name]="item.name"
            [required]="item|requiredField"
            [ngClass]="{ 'mat-slider-buffered': item.buffer }"
            [max]="item.max"
            [min]="item.min - (item.buffer ? 1 : 0)"
            [step]="$any(item.ticks)"
            [thumbLabel]="true"
            [tickInterval]="$any(item.ticks)"
          ></mat-slider>

          <ng-container *ngSwitchCase="'calendar'">
            <input
              matInput
              [(ngModel)]="value[item.name]"
              [max]="maxDate"
              [matDatepicker]="myDatepicker"
              [name]="item.name"
              [required]="item|requiredField"
              [placeholder]="placeholder(item)">
            <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker [touchUi]="isMobile$ | async"></mat-datepicker>
          </ng-container>

          <ng-container *ngSwitchCase="'birthdate'">
            <input
              *ngIf="!(isMobileOrTablet$ | async)"
              type="text"
              [(ngModel)]="value[item.name]"
              [name]="item.name"
              [textMask]="masks.birthdayMask"
              placeholder="mm/dd/yyyy"
              [required]="item|requiredField"
              [pattern]="birthdayFieldOptions.pattern"
              [max]="maxDate"
              data-test="basic-form-birthday-input"
            />
            <input
              *ngIf="(isMobileOrTablet$ | async)"
              type="text"
              [(ngModel)]="value[item.name]"
              [name]="item.name"
              [required]="item|requiredField"
              [textMask]="masks.birthdayMask"
              [pattern]="birthdayFieldOptions.pattern"
              placeholder="mm/dd/yyyy"
              data-test="mobile-basic-form-birthday-input"
            />
          </ng-container>
        </sonder-form-field>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- hack to make submit on enter work -->
  <button type="submit" class="cdk-visually-hidden"></button>
</form>
