<div *ngIf="hasContextData">
  <div class="cost-estimate-icon">
    <img
      alt="cost estimate icon"
      [src]="urlBase + '/assets/cost-estimate-icon.svg'"
    />
  </div>

  <div class="cost-estimate-amount-bubble">
    <div class="cost-estimate-amount-bubble-contents">
      <p class="cost-estimate-amount-pre-text" data-test="cost-estimate-text">Your estimated cost for care</p>
      <h2 class="cost-estimate-amount-text" data-test="cost-estimate-amount-text">{{ costEstimate }} per session</h2>
    </div>
  </div>

  <p
    *ngIf="selfPay"
    class="cost-estimate-info-text"
    data-test="self-pay-cost-estimate-help-text"
  >
    We offer competitive rates for those who aren't using insurance.
    Outside of SonderMind, the average cost of therapy in the U.S. ranges from $100 to $200
    per session.
  </p>

  <p
    *ngIf="!selfPay"
    class="cost-estimate-info-text"
    data-test="non-self-pay-cost-estimate-help-text"
  >
    This is based on what someone with {{ insurancePayor }} in {{ insuranceState }} typically pays
    per 60-minute therapy session.
  <br><br>
    Your actual cost depends on your specific plan - factors like your plan structure,
    the length of your session, and the type of provider you're seeing will determine your
    final cost after insurance is applied.
  </p>
</div>
