import { Component, Input } from '@angular/core';
import { FlowResponse, StepItem } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'flows-intake-helptext',
  templateUrl: './intake-helptext.component.html',
  styleUrls: [
    './intake-helptext.component.scss'
  ]
})
export class IntakeHelptextComponent {
  @Input() component: StepItem;
  @Input() response: FlowResponse;
}
