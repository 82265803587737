import {
  Component, forwardRef, Input, OnInit
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors
} from '@angular/forms';

import { IBasicFormSections } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'sonder-multi-section-checkboxes',
  templateUrl: './multi-section-checkboxes.component.html',
  styleUrls: ['./multi-section-checkboxes.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiSectionCheckboxesComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MultiSectionCheckboxesComponent), multi: true }
  ]
})
export class MultiSectionCheckboxesComponent implements OnInit, ControlValueAccessor {
  @Input() required = false;
  @Input() disabled = false;

  @Input() name: string;
  @Input() value: { [key: string]: boolean; } = {};
  @Input() formValue: { [key: string]: { [key: string]: boolean; }; } = {};
  @Input() sectionList: IBasicFormSections[] = [];
  @Input() isMobile: boolean;
  @Input() maxItemsAllowed: number;
  @Input() maxAllowedInfoText: string;

  limitReached: boolean = false;

  ngOnInit() {
    this.reselectOptions();
  }

  /**
   * Reselect options if any were previously selected and user navigates to a different page.
   */
  reselectOptions(): void {
    this.limitSelection(this.formValue[this.name]);
  }

  /**
   * Update `value` object when one of the select options changes
   */
  handleChange(key: string, newValue: boolean): void {
    this.value[key] = newValue;
    this.onChange(this.value);
    this.limitSelection(this.value);
  }

  /**
   * Limit the number of reasons able to be selected based on maxItemsAllowed
   * If no max is set, do not limit.
   */
  limitSelection(formValue: { [key: string]: boolean; }): void {
    if (this.maxItemsAllowed) {
      const selectionCount = Object.values(formValue).filter((selected) => selected).length;

      this.limitReached = selectionCount >= this.maxItemsAllowed;
    }
  }

  // below here are required control value accessor handlers and form control functions
  onChange = (_: { [key: string]: boolean; }): void => {};
  onTouched = (): void => {};

  writeValue(obj: { [key: string]: boolean; }): void {
    this.value = obj;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(_c: AbstractControl): ValidationErrors {
    const errors: ValidationErrors = {};
    if (this.required && !this.atLeastOneOptionSelected()) {
      errors.required = true;
    }

    return errors;
  }

  /**
   * To be valid for submission, at least one option must be true.
   */
  atLeastOneOptionSelected(): boolean {
    return this.value && Object.keys(this.value).some((key) => this.value[key]);
  }
}
