import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output,
  Inject,
  ViewChild, ViewContainerRef,
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { Observable, Subject, takeUntil } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import {
  FlowResponse,
  IDropdownConfig,
  ResponseUpdateItem,
  StepItem,
  StepNext
} from '@sondermind/utilities/models-flows';
import { WizardService, GENERIC_FLOWS_ERROR_MESSAGE } from '@sondermind/flows-host';
import { INTAKE_COMPONENT_MAP } from '../../component.models';
import { IntakeStepComponent } from './intake-step.component';
import { MIN_OPTIONS_FOR_DROPDOWN } from '../utilities/constants';
import { BasicFormItem, IChipsConfig } from './basic-form/model';

@Component({
  selector: 'flows-intake-step-host',
  templateUrl: './intake-step-host.component.html',
  styleUrls: [
    './intake-step-host.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntakeStepHostComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollAnchor') scrollAnchor: ElementRef;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  private child: ComponentRef<IntakeStepComponent>;
  private destroyed$ = new Subject<void>();

  @Input() busy: string;
  disabled: boolean;
  showError$: Observable<HttpErrorResponse>;
  errorMessage: string = '';
  showStepButtons: boolean = false;

  @Input() response: FlowResponse;
  @Input() component: StepItem;
  @Input() resetable: boolean;

  @Output() stepBack = new EventEmitter<void>();
  @Output() stepNext = new EventEmitter<ResponseUpdateItem[]>();
  @Output() stepExit = new EventEmitter<void>();
  @Output() stepReset = new EventEmitter<void>();

  constructor(
    public wizard: WizardService,
    @Inject(INTAKE_COMPONENT_MAP)
    private componentMap: { [key: string]: any; },
    private cdRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
  ) {
  }

  ngOnInit(): void {
    this.showError$ = this.wizard.showError$;
    this.showError$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((error: HttpErrorResponse) => {
        if (error?.error?.errors?.base != null) {
          this.busy = null;
          this.errorMessage = error?.error.errors.base[0].replace('<br>', '');
        } else {
          this.errorMessage = GENERIC_FLOWS_ERROR_MESSAGE;
        }
      });

    this.convertDropdownToChips();
    this.showStepButtons = this.setShowStepButtons();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.attachNewChild(this.component);
  }

  handleNext(): void {
    if (this.busy) { return }
    if (this.disabled) { return }
    const data = this.child.instance.onSubmit();

    this.busy = 'next';
    this.wizard.next(data).subscribe(() => {
      this.busy = null;
      this.stepNext?.emit(data);
      this.scrollToTop();
    });
  }

  handleBack(): void {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.back().subscribe(() => {
      this.busy = null;
      this.stepBack?.emit();
      this.scrollToTop();
    });
  }

  handleExit(): void {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.exit().subscribe(() => {
      this.busy = null;
    });
  }

  handleExitAndSave(): void {
    if (this.busy) { return }
    const data = this.child.instance.onSubmit();

    this.busy = 'back';
    this.wizard.exitAndSave(data).subscribe(() => {
      this.busy = null;
      this.stepExit?.emit();
    });
  }

  handleReset(): void {
    if (this.busy) { return }

    this.busy = 'back';
    this.wizard.reset().subscribe(() => {
      this.busy = null;
    });
  }

  // Catches instances where a child flow's next config setting is empty and the next button should be hidden
  showChildFlowNextButton(next: StepNext): boolean {
    if (typeof next === 'string') {
      return next.substr(-1) !== '/';
    }
    return true;
  }

  convertDropdownToChips(): void {
    if (this.component.config.component !== 'Dropdown') return;

    const options = [
      ...this.wizard.context,
      ...(this.component.config as IDropdownConfig).additionalOptions || []
    ];

    // We want to show the choice chips when there are less than some number
    const shouldShowChips = options.length < MIN_OPTIONS_FOR_DROPDOWN &&
                            !(this.component.config as IDropdownConfig)?.isMulti;
    if (!shouldShowChips) return;

    // Need to convert IDropdownConfig to IChipsConfig type
    const form: BasicFormItem = {
      name: this.component.config?.name,
      type: 'chipgroup',
      label: '',
      header: '',
      placeholder: '',
      width: '',
      options: options.map((option) => ({
        label: option.label,
        value: option.value
      }))
    };

    this.component.config.component = 'Chips';
    (this.component.config as IChipsConfig).form = [form];
  }

  setShowStepButtons(): boolean {
    if (this.component.config.fullscreen) return false;
    if (this.disabled === null) return false;
    if (this.component.config.component !== 'Chips') return true;

    const [formItem] = (this.component.config as IChipsConfig)?.form;
    return formItem?.name in this.response.data;
  }

  private scrollToTop() {
    const containers = this.scrollDispatcher.getAncestorScrollContainers(this.scrollAnchor);
    const container = containers.find((x) => x.measureScrollOffset('top') > 0);
    const scrollMe = container?.getElementRef().nativeElement ?? window;
    scrollMe.scrollTo(0, 0);
  }

  private attachNewChild(c: StepItem) {
    if (this.container == null) {
      return;
    }

    this.container.clear();
    this.child = null;

    if (c) {
      const cType = this.componentMap[c.config.component];

      this.child = this.container.createComponent<IntakeStepComponent>(cType);

      // set @Inputs
      this.child.instance.component = c;
      this.child.instance.response = this.response;

      // subscribe to @Outputs
      this.child.instance.validityChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe((v) => {
          this.disabled = !v;
          this.cdRef.detectChanges();
        }
        );

      this.child.instance.submit
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.handleNext();
        }
        );

      this.child.changeDetectorRef.detectChanges();
    }
  }
}
