import { NgModule } from '@angular/core';

import { AppHeightDirective } from './app-height.directive';
import { FeatureFlagDirective } from './feature-flag.directive';
import { GoogleMapsAutoCompleteDirective } from './google-maps-autocomplete.directive';
import { MediaQueryAttributeDirective } from './mediaquery-attr.directive';
import { MediaQueryIfDirective } from './mediaquery-if.directive';
import { ResizeObserverDirective } from './resize-observer.directive';
import { SMAutofocusDirective } from './sm-autofocus.directive';
import { TemplateStoreDirective } from './template-store.directive';

const DIRECTIVES = [
  AppHeightDirective,
  FeatureFlagDirective,
  GoogleMapsAutoCompleteDirective,
  MediaQueryAttributeDirective,
  MediaQueryIfDirective,
  ResizeObserverDirective,
  SMAutofocusDirective,
  TemplateStoreDirective,
];

@NgModule({
  imports: [],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES]
})
export class DirectiveModule { }
