import {
  Input, EventEmitter, Output, OnDestroy, Directive
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  StepItem,
  StepItemConfig,
  ResponseUpdateItem,
  FlowResponse
} from '@sondermind/utilities/models-flows';

import { WizardService } from '../../services/wizard.service';

export const PHONE_FIELD_OPTS = {
  mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  pattern: /\([1-9]\d\d\) \d\d\d-\d\d\d\d/,
};

export const ZIP_FIELD_OPTS = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
  pattern: /[0-9]{5}/,
};

export const EMAIL_FIELD_OPTS = {
  // match for any uppercase/lowercase/special character/digit/dot/number/underscore etc
  // match specifically for an '@' sign
  // same match as the first
  // match specifically for a '.'
  // same match as the first (just incase top level domain has a # etc but does that make it TLD 🤔 but adding it as a check)
  pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]+\.[A-Za-z0-9._%+-]+$/
};

const getBirthdayPattern = () => {
  const currentYear = new Date().getFullYear();
  const earliestYear = currentYear - 120;
  // match a month between the range of 01 - 12
  // match the '/' character between the mm -> dd
  // match a day in the range of 01 - 31
  // match the '/' character between the dd -> yyyy
  // matches a year in the past 120 years (abritariy number - this could be whatever but it seems extra safe)
  // eslint-disable-next-line no-useless-escape
  return new RegExp(`^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(${earliestYear}|${earliestYear - 1}|${earliestYear - 2}|[1-9]\\d{3})$`);
};

export const BIRTHDAY_FIELD_OPTS = {
  pattern: getBirthdayPattern()
};

@Directive({ selector: 'step-component' })
export abstract class StepComponent<T extends StepItemConfig = StepItemConfig> implements OnDestroy {
  @Input() component: StepItem<T>;
  @Input() response: FlowResponse;

  @Output() validityChange: Observable<boolean>;
  @Output() submit = new EventEmitter<void>();

  valid = new Subject<boolean>();
  protected destroyed$ = new Subject<void>();

  abstract onSubmit(): ResponseUpdateItem[];

  constructor(
    public wizard: WizardService,
  ) {
    this.validityChange = this.valid.pipe(distinctUntilChanged());
  }

  get data() { return this.response && this.response.data }
  get config() { return this.component && this.component.config }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
