import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild
} from '@angular/core';
import {
  trigger, state, style, transition, animate
} from '@angular/animations';
import { NgForm, NgModel } from '@angular/forms';

import { IBasicFormItem } from '@sondermind/utilities/models-flows';

import { FormFieldComponent } from '../form-field/form-field.component';
import { BreakpointsService } from '../../services/breakpoints.service';

@Component({
  selector: 'sonder-form-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  animations: [
    trigger('slideUp', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':enter', [
        style({ transform: 'translateY(1em)', opacity: 0 }),
        animate('100ms ease')
      ])
    ])
  ]
})
export class RatingComponent extends FormFieldComponent implements AfterViewInit, OnInit {
  @Input() ratingConfig: IBasicFormItem;

  /* Two-way binding property for setting/getting the rating value */
  @Input() rating: string;
  @Output() ratingChange = new EventEmitter<string>();

  @ViewChild(NgModel) override model: NgModel = {} as NgModel;

  hoveredRating: { value: string; };

  get ratingOptions(): Array<{ value: string; }> {
    return this.ratingConfig.options;
  }

  constructor(
    form: NgForm,
    breakpoint: BreakpointsService,
    zone: NgZone,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(form, changeDetectorRef, zone, breakpoint);
  }

  override ngOnInit() {
    this.label = this.ratingConfig.label;
  }

  ngAfterViewInit() {
    // Add our internally created ngModel into the parent component's form
    this.form.addControl(this.model);
  }

  /**
 *
 *
 * @param {{ value: string }} ratingOption
 * @return {*}  {boolean}
 * @memberof RatingComponent
 */
  isDisplayedFilled(ratingOption: { value: string; }): boolean {
    if (!ratingOption || !this.ratingOptions?.length) { return false }

    if (this.hoveredRating) {
      return this.ratingOptions.indexOf(ratingOption) <= this.ratingOptions.indexOf(this.hoveredRating);
    }

    return this.rating
      && this.ratingOptions.indexOf(ratingOption) <= this.ratingOptions.findIndex((rating) => rating.value === this.rating);
  }
}
