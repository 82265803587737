import { Component } from '@angular/core';
import { IrisButtonTypes } from '@sondermindorg/iris-design-system-angular';
import { SharedMasks, TextMasks } from '@sondermind/utilities/text-masks';

@Component({
  selector: 'flows-intake-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class IntakeCallComponent {
  IrisButtonTypes = IrisButtonTypes;

  HELP_NUMBER = '8669418770';
  masks: TextMasks = SharedMasks;
}
