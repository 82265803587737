<form class="decline-theme" fxLayout="column" fxLayoutGap="32px">
  <div 
    class="option-container chip-container" 
    [ngClass]="{ 'full-width': !isMobile }"
    fxLayout="row wrap">
    <mat-chip-list>
      <mat-chip
        #chip="matChip"
        sondermind-secondary
        class="sondermind-design-system"
        *ngFor="let option of config.options"
        ngDefaultControl
        [name]="option.value"
        [(ngModel)]="selections[option.value]"
        (click)="handleSelectionChange(option, chip)">
        {{ option.label | bindTemplate:response.data }}
      </mat-chip>
    </mat-chip-list>
    <span class="spacer" *ngFor="let i of missing"></span>
  </div>
  <sonder-form-field *ngIf="config.other" [label]="config.other.label | bindTemplate:response.data">
    <input matInput type="text" [(ngModel)]="other" [name]="config.other.name" [placeholder]="config.other.placeholder" (ngModelChange)="handleChange()">
  </sonder-form-field>
</form>
