import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { StepItem } from '@sondermind/utilities/models-flows';
import { WizardService } from '@sondermind/flows-host';

import { ConfigurationService, IFlowsAppConfig } from '@sondermind/configuration';
import { NativeAppContextHelpers } from '@sondermind/native-app-interface';
import { IrisIconButtonTypes } from '@sondermindorg/iris-design-system-angular';

@Component({
  selector: 'flows-intake-header',
  templateUrl: './intake-header.component.html',
  styleUrls: ['./intake-header.component.scss'],
})
export class IntakeHeaderComponent implements OnInit{
  @Output() backClicked = new EventEmitter<void>();
  @Input() back: boolean;
  @Input() component: StepItem;
  @Input() isLogoClickable = true;

  logoLink: string | null = null;

  irisIconButtonTypes = IrisIconButtonTypes;

  data$ = combineLatest([
    this.wizard.config$,
    this.wizard.response$
  ]).pipe(
    filter((xs) => !xs.some((x) => x == null)),
    map(([config, response]) => ({ config, component: this.wizard.component, response }))
  );

  get urlBase(): string {
    return this.configurationService.env.urlBase;
  }

  getLogoLink(): string | null {
    if (this.isLogoClickable && !NativeAppContextHelpers.isHostedInNativeApp()) {
      // if marketingUrl undefined, return null
      return this.configurationService.env.marketingUrl || null;
    }
    return null;
  }

  constructor(
    public configurationService: ConfigurationService<IFlowsAppConfig>,
    public wizard: WizardService
  ) {}

  ngOnInit(): void {
    this.logoLink = this.getLogoLink();
  }
}
