import { DirectScheduleLocation } from '@sondermind/utilities/models-direct-scheduling';
import { SessionType } from '@sondermind/utilities/models-session';

import {
  IClientMatchingProfile,
  IClientMatchingProfileApiResponse,
  clientMatchingResponseTransformer
} from '@sondermind/utilities/models-matching';
import { InternalReferralTypes } from '@sondermind/data-access/provider-facing/internal-referral';
import { ReferralSourceEnum } from './referral-source.enum';

export class ClientReferral implements IClientReferral {
  id: number;
  automatch: boolean;
  userId: number;
  referralState: ReferralState;
  therapists: IReferralTherapist[];
  createdAt: string;
  updatedAt: string;
  latLngCity: string;
  latLngState: string;
  unpauseReason: string | null;
  matchingProfile?: IClientMatchingProfile;
  referralSource: string;
  referringType: string;
  isInternalReferral: boolean;
  isReferringTypePsychiatry: boolean;

  constructor(json: IClientReferralApiResponse) {
    if (!json) { return }

    this.id = json.id;
    this.automatch = json.automatch;
    this.userId = json.user_id;
    this.referralState = json.referral_state;
    this.therapists = json.therapists;
    this.createdAt = json.created_at;
    this.updatedAt = json.updated_at;
    this.latLngCity = json.lat_lng_city;
    this.latLngState = json.lat_lng_state;
    this.unpauseReason = json.unpause_reason;
    this.matchingProfile = json.matching_profile ?
      clientMatchingResponseTransformer(json.matching_profile)
      : undefined;
    this.referralSource = json.referral_source;
    this.referringType = json.referring_type;
    this.isInternalReferral = json.referral_source === ReferralSourceEnum.INTERNAL_REFERRAL;
    this.isReferringTypePsychiatry = json.referring_type === InternalReferralTypes.PSYCHIATRY;
  }
}

export enum ReferralState {
  created = 'created',
  processing = 'processing',
  scheduled = 'scheduled',
  manualMatchRequired = 'manual_match_required',
  clientCanceled = 'client_canceled',
  matchingCanceled = 'matching_canceled',
  requiresRematch = 'requires_rematch',
  paused = 'paused'
}

export enum ClientUnpauseReasonState {
  INDEPENDENT_ACCOUNT_COMPLETION = 'independent_account_completion',
  PROPOSE_A_TIME = 'propose_a_time',
  REQUEST_TO_SCHEDULE = 'request_to_schedule',
  NON_CLINICAL_CHAT = 'non_clinical_chat'
}

export interface IClientReferralApiResponse {
  id: number;
  automatch: boolean;
  user_id: number;
  referral_state: ReferralState;
  therapists: IReferralTherapist[];
  created_at: string;
  updated_at: string;
  lat_lng_city: string;
  lat_lng_state: string;
  unpause_reason: string | null;
  matching_profile?: IClientMatchingProfileApiResponse;
  referral_source: string;
  referring_type: string;
}

export interface IClientReferral {
  id: number;
  automatch: boolean;
  userId: number;
  referralState: ReferralState;
  therapists: IReferralTherapist[];
  createdAt: string;
  updatedAt: string;
  latLngCity: string;
  latLngState: string;
  unpauseReason: string | null;
  matchingProfile?: IClientMatchingProfile;
  referralSource: string;
  referringType: string | null;
  isInternalReferral: boolean;
  isReferringTypePsychiatry: boolean;
}

export interface IClientReferralUTMData {
  id: number;
  referralState: string;
  createdAt: Date;
  updatedAt: Date;
  utmSource: string;
  utmMedium: string;
  unpauseReason: string | null;
  utmCampaign: string;
}

export interface IReferralTherapist {
  id: number;
  userId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  displayFirstName: string;
  displayName: string;
  practiceName: string;
  profilePhotoUrl: string;
  proxyPhoneNumberFeatureFlag: boolean;
  phoneNumber: string;
  email: string;
  locations?: DirectScheduleLocation[];
  bioExcerpt: string;
  searchBio: string;
  certifications: { name: string; abbrev: string; };
  topSpecialties: { name: string; desc: string; };
  noPhysicalLocation: boolean;
  directSchedulingEnabled?: boolean;
  sessionTypesOffered: SessionType[];
}
