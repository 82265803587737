import {
  Directive, OnDestroy, HostBinding, EventEmitter
} from '@angular/core';
import { BreakpointsService } from '@sondermind/utilities/services';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[sondermindMediaQueryAttribute]'
})
export class MediaQueryAttributeDirective implements OnDestroy {
  private destroyed$ = new EventEmitter<void>();

  @HostBinding('attr.data-mediaquery')
  matchedQueryValues: string;

  constructor(
    breakpoints: BreakpointsService,
  ) {
    breakpoints.suffixes$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((suffixes) => {
      this.matchedQueryValues = suffixes.join(' ');
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
