<div *ngIf="ratingOptions" class="sonder-form-field" [ngClass]="classToggles">
    <div class="sonder-form-field-label" *ngIf="label" fxLayout="column">
        <span class="sonder-form-field-label-text" fxFlex="0 0 auto">{{ label }}</span>
        <ul class="sonder-form-errors" fxFlex="1 1 100%">
            <li *ngFor="let error of errorMessages" [@slideUp]="'in'">{{ error }}</li>
        </ul>
    </div>
    <div class="rating-container">
        <mat-button-toggle-group 
            [(ngModel)]="rating" 
            (ngModelChange)="ratingChange.emit(rating)" 
            [name]="ratingConfig.name" 
            [required]="required" 
            aria-label="rating"
        >
            <mat-button-toggle  
                *ngFor="let option of ratingOptions" 
                [value]="option.value" 
                aria-label="{{option.value}} star"
                [disableRipple]="true"
                (mouseenter)="hoveredRating = option"
                (mouseleave)="hoveredRating = null"
            >
                <mat-icon color="primary">{{isDisplayedFilled(option) ? 'star' : 'star_outline'}}</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div>
            <span class="min-label">{{ratingConfig.minRatingLabel}}</span>
            <span class="max-label">{{ratingConfig.maxRatingLabel}}</span>
        </div>
    </div>
  </div>
  