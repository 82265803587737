import {
  Component, EventEmitter, OnDestroy, OnInit
} from '@angular/core';
import { AnimationBuilder, animate, style } from '@angular/animations';

import {
  BehaviorSubject, Subject,
  take, tap, timer, finalize, filter, mergeMap,
  takeUntil
} from 'rxjs';

import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { TherapistStore } from '@sondermind/data-access/client-facing/therapist';
import { CurrentUser } from '@sondermind/data-access/client-user';
import { WizardService } from '@sondermind/flows-host';

import { IntakeStepComponent } from '../../intake-step.component';

@Component({
  selector: 'flows-intake-embedded-resend',
  templateUrl: './embedded-intake-resend.component.html',
  styleUrls: ['./embedded-intake-resend.component.scss'],
})
export class EmbeddedIntakeResendComponent extends IntakeStepComponent implements OnInit, OnDestroy {
  destroyed$ = new EventEmitter<void>();

  isDone$ = new BehaviorSubject<boolean>(false);
  private doCheckProviders$ = new Subject<boolean>();

  constructor(
    wizard: WizardService,
    private therapistStore: TherapistStore,
    private currentUser: CurrentUser,
    private animationBuilder: AnimationBuilder,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.startCheckingForAvailableProviders();
    this.fadeOutFlowProgressBar();
  }

  ngOnDestroy(): void {
    this.markFlowComplete();
    this.destroyed$.next();
    this.cancelTickers();
    this.doCheckProviders$.unsubscribe();
  }

  private fadeOutFlowProgressBar(): void {
    const flowProgressBar = document.querySelector<HTMLElement>('flows-intake-host-progress-bar');
    if (!flowProgressBar) return;

    const factory = this.animationBuilder.build(
      [
        // attaching animation player messes with layout, so
        //  setting z-index to prevent header from overflowing progress bar
        style({ 'z-index': 9 }),
        // fade out over 500ms after 4s delay
        animate('500ms 4s', style({ opacity: 0 }))
      ]
    );
    // create player, and play the animation
    factory.create(flowProgressBar)?.play();
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from resend email!');
  }

  private startCheckingForAvailableProviders(): void {
    let numIterations = 0;

    this.doCheckProviders$.subscribe(() => {
      if (numIterations < 3) {
        // since the provider check signals when complete, wait 15 seconds before checking again
        timer(15000).pipe(
          tap(() => {
            numIterations += 1;
            this.checkAvailableProviders();
          })
        ).subscribe();
      } else {
        // since we already waited at least 50 seconds (5 + (3 * 15))
        // reload user info, and navigate to dashboard
        this.currentUser.reload$().pipe(
          take(1),
          tap(() => {
            this.navigateToDashboard();
          })
        ).subscribe();
      }
    });

    // wait 5 seconds before first check
    timer(5000).pipe(
      tap(() => {
        numIterations += 1;
        this.checkAvailableProviders();
      })
    ).subscribe();
  }

  private navigateToDashboard(): void {
    this.cancelTickers();
    this.markFlowComplete();
    const dashboardLink = document.querySelector<HTMLAnchorElement>("client-nav-link a[href='/dashboard']");
    dashboardLink?.click();
  }

  private cancelTickers(): void {
    this.isDone$.next(true);
  }

  private checkAvailableProviders(): void {
    // (re)load matches
    this.therapistStore.loadAll$().pipe(
      take(1),
      // if got matches, proceed
      filter(() => !this.therapistStore.noMatchesYet),
      // switch to reloading current user
      mergeMap(() => this.currentUser.reload$()),
      take(1),
      tap(() => {
        this.navigateToDashboard();
      }),
      finalize(() => {
        // unless ready to navigate away, signal check completion
        if (this.isDone$.value !== true) {
          this.doCheckProviders$.next(true);
        }
      })).subscribe();
  }

  private markFlowComplete(): void {
    // since we don't call `resendEmail()` (which would set the completed flag internally)
    this.wizard.complete().pipe(takeUntil(this.destroyed$)).subscribe();
  }
}
