import {
  Component, Output, Input, EventEmitter
} from '@angular/core';

@Component({
  selector: 'sonder-confirmable-button',
  templateUrl: './confirmable-button.component.html',
  styleUrls: ['./confirmable-button.component.scss']
})
export class ConfirmableButtonComponent {
  @Output() confirm = new EventEmitter<Event>();
  @Input() htmlClass: string;

  armed = false;

  generateClass(): string {
    const classes = [this.htmlClass, 'confirm-button', 'confirmable-button'];

    if (this.armed) {
      classes.push('armed');
    }

    return classes.join(' ');
  }

  onClick(event: Event) {
    event.preventDefault();

    if (!this.armed) {
      this.armed = true;
    } else {
      this.armed = false;
      this.confirm.next(event);
    }
  }

  onCancel(event: Event) {
    event.preventDefault();
    this.armed = false;
  }
}
