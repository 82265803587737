<div class="resend-step-wrapper sondermind-design-system">
    <div class="resend-step-hero" sonderMediaqueryClass="mq">
        <ng-container>
            <div class="hero-inner-wrapper">
                <div
                  *ngIf="config.helpText"
                  [innerHTML]="config.helpText"
                  class="help-text-wrapper"
                >
                </div>
                <div class="email-icon">
                  <img
                    alt="email icon"
                    [src]="urlBase + '/assets/email-icon.svg'"
                  >
                </div>
                <p
                  class="info-text-wrapper"
                  [innerHTML]="config.infoText | bindTemplate:response.data"
                ></p>
                <hr>
                <div class="resend-footer-wrapper">
                  <p>
                    If you don’t see our email, hang tight! It could take a few minutes to get to you. You can also check your spam folder, try 
                    <a (click)="handleResend()" class="iris-link-primary-responsive">resending the email</a>, or contact the support team at
                    <a disable-external-link-directive class="iris-link-primary-responsive" href="tel:{{ supportPhoneLink }}">{{ supportPhoneDisplay }}</a> or
                    <a disable-external-link-directive class="iris-link-primary-responsive" href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>.
                  </p>
                </div>
                <div
                *ngIf="showConfirmation"
                class="success-icon"
                [ngClass]="{'success-mobile': (isMobile$ | async)}"
              >
                <mat-icon inline="true">done</mat-icon>&nbsp; Email sent
              </div>
            </div>
        </ng-container>
    </div>
</div>
