export interface IFlowInfo {
  slug?: string;
  hash?: string;
}

export class FlowInfo implements IFlowInfo {
  slug?: string;
  hash?: string;
  shouldLoadStrict: boolean;

  constructor(slug?: string, hash?: string, shouldLoadStrict = false) {
    if (slug) this.slug = slug;
    if (hash) this.hash = hash;
    this.shouldLoadStrict = shouldLoadStrict;
  }
}
