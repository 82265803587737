import {
  Component, OnInit, Input, ContentChild, AfterContentInit, ChangeDetectorRef, NgZone, OnDestroy, ElementRef
} from '@angular/core';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  NgModel, NgForm, ControlContainer, NgControl
} from '@angular/forms';
import {
  trigger, state, style, animate, transition
} from '@angular/animations';
import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
import { MatLegacyInput } from '@angular/material/legacy-input';
import { MatLegacySlider } from '@angular/material/legacy-slider';
import { BreakpointsService } from '../../services/breakpoints.service';

export interface FormFieldTypeConfig {
  icon?: string;
}

// Register material icons here
// refer to https://material.io/resources/icons/?style=baseline for the icons
export const FORM_FIELD_TYPE_CONFIG: { [key: string]: FormFieldTypeConfig; } = {
  search: { icon: 'search' }
};

const ERROR_MESSAGES = {
  /* eslint-disable quote-props */
  'pattern': 'Invalid format',
  'required': 'Required',
  'validateCVC': 'CVV must be 3-4 digits',
  'sonderInvalidOption': '',
  'matDatepickerMax': 'Invalid date',
  'matDatepickerParse': 'Invalid date',
  /* eslint-enable */
};

@Component({
  selector: 'sonder-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm },
  ],
  // animation copied to radio-group and select-field and card-upload,
  // inheritance is weird
  animations: [
    trigger('slideUp', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition(':enter', [
        style({ transform: 'translateY(1em)', opacity: 0 }),
        animate('100ms ease')
      ])
    ])
  ]
})
export class FormFieldComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() label: string | false | any;
  @Input() helperText: string;
  @Input() overrideLabelCase: boolean = false;
  @Input() description: string | false;
  @Input() required: boolean;

  @Input() errors: string[];
  @Input() failFast = false;
  @Input() showSuccess = false;

  @Input() standin: string | null;
  @Input() iconType: string;
  @Input() type: string;

  @ContentChild(NgModel) model: NgModel;
  @ContentChild(NgControl) control: NgControl;
  @ContentChild(MatLegacyFormFieldControl) input: MatLegacyInput;

  // if we're a slider, track focus with blur/focus events
  @ContentChild(MatLegacySlider)
  private slider: MatLegacySlider;
  private sliderFocus = false;

  icon: string = '';

  get empty(): boolean {
    return this.input && this.input.empty;
  }

  constructor(
    protected form: NgForm,
    protected cdr: ChangeDetectorRef,
    protected zone: NgZone,
    public breakpoint: BreakpointsService,
  ) { }

  ngOnInit(): void {
    if (this.iconType) {
      this.icon = FORM_FIELD_TYPE_CONFIG[this.iconType].icon;
    }

    // Ensure all labels are sentence case except for those that have explicitly stated that label case should be overridden
    if (this.label && !this.overrideLabelCase) {
      if (this.label.includes('ID')) {
        // Keep ID capitalized for labels such as Member/ID number in insurance
        const splitByID = this.label.split(/(ID)/g);
        this.label = splitByID[0].charAt(0).toUpperCase() + splitByID[0].slice(1).toLowerCase();
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < splitByID.length; i++) {
          if (splitByID[i] === 'ID') {
            this.label += splitByID[i];
          } else {
            this.label += splitByID[i].toLowerCase();
          }
        }
      } else {
        this.label = this.label.charAt(0).toUpperCase() + this.label.slice(1).toLowerCase();
      }
    }
  }

  ngAfterContentInit(): void {
    if (this.input && this.required === undefined) {
      this.required = this.input?.required || this.control?.errors?.['required'];
    }

    // watch slider focus
    // we have to hack around, because the slider doesn't expose focus/blur events
    if (this.slider) {
      this.sliderFocusMonitor
        .monitor(this.sliderElementRef.nativeElement, true)
        .subscribe((origin: FocusOrigin) => this.zone.run(() => {
          this.sliderFocus = !!origin;
          this.cdr.markForCheck();
        }));
    }
  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.sliderFocusMonitor.stopMonitoring(this.sliderElementRef.nativeElement);
    }
  }

  get classToggles(): { [key: string]: boolean; } {
    return {
      'sm-focused': this.focused,
      'sm-disabled': this.disabled,
      'sm-errors': this.showErrors,
      'sm-success': this.showSuccess && this.hasSuccess,
    };
  }

  get contentClassToggles(): { [key: string]: boolean; } {
    return {
      'sonder-form-field-standin': !!this.standin,
      'sonder-form-field-content': !this.standin,
      'sm-mat-focusable': !this.standin
    };
  }

  get openTextClass(): boolean {
    return this.type === 'textarea' && !!this.description;
  }

  get focused(): boolean {
    return (this.input && this.input.focused) || (this.slider && this.sliderFocus);
  }

  get disabled(): boolean {
    return (this.input && this.input.disabled)
      || (this.model && this.model.disabled)
      || (this.control && this.control.disabled)
      || (this.slider && this.slider.disabled);
  }

  get hasErrors(): boolean {
    if (!(this.model && this.model.errors) && !(this.control && this.control.errors) && !(this.errors && this.errors.length)) {
      return false;
    }

    // when showing standin, ignore errors
    if (this.standin) {
      return false;
    }

    // show in fail fast mode
    if (this.failFast && !(this.model && this.model.pristine) && !(this.control && this.control.pristine)) {
      return true;
    }

    // show if the form's been submitted or blurred
    if (this.form.submitted && !this.form.pristine) {
      return true;
    }

    // show if there's a model that's been touched
    return (this.model ? !this.model.untouched : false) || (this.control ? !this.control.untouched : false);
  }

  get hasSuccess(): boolean {
    return (
      (this.model && (!this.model.pristine && this.model.valid))
      || (this.control && (!this.control.pristine && this.control.valid))
    );
  }

  get showErrors(): boolean {
    if (!this.hasErrors) {
      return false;
    }

    // don't show errors if they're only blank strings
    if (this.errorMessages.filter((m) => m !== '').length === 0) {
      return false;
    }

    return true;
  }

  get showPending(): boolean {
    return (
      (this.model && this.model.pending)
      || (this.control && this.control.pending)
    );
  }

  get errorMessages(): string[] {
    if (!this.hasErrors) {
      return [];
    }

    const errors = (this.model && this.model.errors) || (this.control && this.control.errors);
    const keys = errors ? Object.keys(errors) : [];
    const msgs = keys.map<string>((k) => {
      if (ERROR_MESSAGES[k] != null) {
        return ERROR_MESSAGES[k];
      }

      if (typeof errors[k] === 'string') {
        return errors[k] as string;
      }

      return 'Error';
    });

    return [...msgs, ...this.errors || []];
  }

  private get sliderFocusMonitor(): FocusMonitor {
    return this.slider && this.slider['_focusMonitor'];
  }

  private get sliderElementRef(): ElementRef<any> {
    return this.slider && this.slider['_elementRef'];
  }
}
