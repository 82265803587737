<div class="sonder-form-field sonder-form-radio-group" [ngClass]="classToggles">
  <div class="sonder-form-field-label" *ngIf="label" fxFlexLayout="row">
    <span 
      class="sonder-form-field-label-text" 
      fxFlex="0 0 auto"
      data-test="form-field-label"
      >
      {{ label }}
    </span>
    <span class="sonder-form-field-label-required" fxFlex="0 0 auto" *ngIf="required">*</span>
    <ul class="sonder-form-errors" fxFlex="1 1 100%">
      <li *ngFor="let error of errors" [@slideUp]="'in'">{{ error }}</li>
    </ul>
  </div>
  <div class="sonder-form-helptext sonder-form-field-helptext">
    <ng-content select="footer"></ng-content>
  </div>
  <ng-content></ng-content>
  <div class="sonder-form-field-after">
    <ng-content select="aside"></ng-content>
  </div>
</div>
