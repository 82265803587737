import { NativeAppContextHelpers } from '@sondermind/native-app-interface';

export enum BrowsingAppTypeName {
  UNIFIED_APP = 'unified_app',
  TOTALBRAIN_APP = 'totalbrain_app',
  WEB = 'web'
}

export function getBrowsingAppTypeName(): BrowsingAppTypeName {
  if (NativeAppContextHelpers.isHostedInNativeApp()) return BrowsingAppTypeName.UNIFIED_APP;

  if (window['ReactNativeWebView']) return BrowsingAppTypeName.TOTALBRAIN_APP;

  return BrowsingAppTypeName.WEB;
}
