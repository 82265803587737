<form #form="ngForm" (submit)="submit.emit()" class="decline-theme formContainer" sonderMediaqueryClass="mq" fxLayout="row wrap" fxLayoutAlign="start start">

  <sonder-form-radio-group fxFlex="auto">
    <mat-radio-group matInput [(ngModel)]="mobileOptin" name="optin">
      <mat-radio-button color="primary" [value]="true">
        Yes, I'd like to receive texts about my match.
      </mat-radio-button>
      <ng-container *ngIf="mobileOptin">
        <sonder-form-field class="sonder-form-field" label="Mobile Phone" [required]="true">
          <input matInput [(ngModel)]="mobileNumber" name="number" type="tel" [textMask]="phoneFieldOptions" [pattern]="phoneFieldOptions.pattern" [required]="true">
        </sonder-form-field>
        <sonder-form-optin>
          <mat-checkbox matInput color="primary" [(ngModel)]="mobileOptin" name="optin" value="true" [required]="true">
            I would like to receive SMS text messages from Sondermind. Standard messaging rates apply.
          </mat-checkbox>
        </sonder-form-optin>
      </ng-container>
      <mat-radio-button color="primary" [value]="false">
        No, thanks. Just send me an email.
      </mat-radio-button>
    </mat-radio-group>
  </sonder-form-radio-group>

  <!-- hack to make submit on enter work -->
  <button type="submit" class="cdk-visually-hidden"></button>
</form>
