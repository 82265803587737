import { Component } from '@angular/core';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';
import { WizardService } from '@sondermind/flows-host';
import { ConfigurationService } from '@sondermind/configuration';
import { IntakeStepComponent } from '../intake-step.component';

@Component({
  selector: 'intake-step-cost-estimate',
  templateUrl: './cost-estimate.component.html',
  styleUrls: ['./cost-estimate.component.scss']
})
export class CostEstimateComponent extends IntakeStepComponent<StepItemConfig> {
  urlBase: string = '';

  hasContextData: boolean = false;
  costEstimate: number = 0;
  selfPay: boolean = true;
  insurancePayor: string = '';
  insuranceState: string = '';

  constructor(
    wizard: WizardService,
    private configurationService: ConfigurationService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.urlBase = this.configurationService.env.urlBase;
    this.valid.next(true);

    this.costEstimate = this.wizard.context['average_client_responsibility'];
    this.selfPay = this.wizard.context['self_pay'];
    this.insurancePayor = this.wizard.context['insurance_payor'];
    this.insuranceState = this.wizard.context['insurance_state'];

    // This is needed to fix an animation bug after adding default values
    // for the component
    this.hasContextData = true;
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
