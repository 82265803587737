<form
  [formGroup]="basicInfoForm"
>
  <ng-container *ngFor="let item of config.form" [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'text'" [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{
      $implicit: item
    }"></ng-container>

    <ng-container *ngSwitchCase="'textarea'" [ngTemplateOutlet]="textarea" [ngTemplateOutletContext]="{
      $implicit: item
    }"></ng-container>

    <ng-container *ngSwitchCase="'refdata-select'" [ngTemplateOutlet]="refDataSelect" [ngTemplateOutletContext]="{
      $implicit: item
    }"></ng-container>

    <ng-container *ngSwitchCase="'optin-checkbox'" [ngTemplateOutlet]="optinCheckbox" [ngTemplateOutletContext]="{
      $implicit: item
    }"></ng-container>

    <ng-container *ngSwitchCase="'complexCheckbox'" [ngTemplateOutlet]="complexCheckbox" [ngTemplateOutletContext]="{
      $implicit: item
    }"></ng-container>
  </ng-container>

  <div
    *ngIf="component.config.reminderText"
    data-test="reminder-text"
    [innerHTML]="component.config.reminderText"
  >
  </div>

  <!-- Text Input Template -->
  <ng-template #text let-item>
    <iris-form-field
      [label]="item.label"
      [helperText]="item.description"
      [customErrorMessages]="{
        pattern: 'Invalid format'
      }"
      [attr.data-test]="'input-field-' + item.label"
    >
      <iris-text-input
        [formControlName]="item.name"
        [placeholder]="item.placeholder ? item.placeholder : ''"
        [mask]="masks[item.mask]"
        [pattern]="patterns[item.pattern]"
        [type]="formTypes[item.pattern] ?? 'text'"
        [attr.data-test]="'basic-form-input-' + item.name"
      ></iris-text-input>
    </iris-form-field>
  </ng-template>

  <!-- Text Area Template -->
  <ng-template #textarea let-item>
    <iris-form-field
      [label]="item.label"
      [helperText]="item.description"
      [attr.data-test]="'input-field-' + item.label"
    >
      <iris-text-area
        [formControlName]="item.name"
        [placeholder]="item.placeholder ? item.placeholder : ''"
        [size]="textareaSizes.SM"
      ></iris-text-area>
    </iris-form-field>
  </ng-template>

  <!-- Ref Data Select Template -->
  <ng-template #refDataSelect let-item>
    <iris-form-field
      [label]="item.label"
      [helperText]="item.description"
      [attr.data-test]="'input-field-' + item.label"
      *ngIf="(options[item.name] | async)?.length"
    >
      <iris-dropdown
        [formControlName]="item.name"
        [placeholder]="item.placeholder ? item.placeholder : ''"
        [options]="options[item.name] | async"
        [attr.data-test]="item.name + '-dropdown'"
      >
      </iris-dropdown>
    </iris-form-field>
  </ng-template>

  <!-- Opt in Checkbox Template -->
  <ng-template #optinCheckbox let-item>
    <iris-checkbox
      [formControlName]="item.name"
      [attr.data-test]="'opt-in-checkbox-' + item.name"
    >
      {{item.optinText}}
    </iris-checkbox>
  </ng-template>

  <!-- Complex Checkbox Template -->
  <ng-template #complexCheckbox let-item>
    <iris-form-field
      [showOptionalTag]="false"
      [label]="item.label"
      class="complex-checkbox-form-field"
    >
      <div class="complex-checkbox-container" [formGroupName]="item.name">
        <ng-container *ngFor="let option of item.options">
          <iris-checkbox
            *ngIf="!option.isHidden"
            [formControlName]="option.value"
            [attr.data-test]="'complex-checkbox-option-' + option.value"
          >
            <b>{{ option.label }}</b>
            <div>{{ option.helperText }} </div>
          </iris-checkbox>
        </ng-container>
      </div>
    </iris-form-field>
  </ng-template>
</form>
