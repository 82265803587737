import { Component, Input } from '@angular/core';

@Component({
  selector: 'sondermind-journey-header',
  templateUrl: './journey-header.component.html',
})

export class JourneyHeaderComponent {
  @Input() title: string;
}
