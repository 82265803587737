import { Component, Input } from '@angular/core';

import { FlowResponse, StepItem } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'flows-intake-step-notice',
  templateUrl: './intake-step-notice.component.html',
  styleUrls: ['./intake-step-notice.component.scss']
})
export class IntakeStepNoticeComponent {
  @Input() component: StepItem;
  @Input() response: FlowResponse;
}
