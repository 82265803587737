import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

import {
  IrisButtonTypes,
  IrisButtonContainerAlignments
} from '@sondermindorg/iris-design-system-angular'

export interface StepButtonsConfig {
  back: boolean;
  exit: boolean;
  done: boolean;
}

@Component({
  selector: 'flows-step-buttons',
  templateUrl: './step-buttons.component.html',
  styleUrls: ['./step-buttons.component.scss']
})
export class StepButtonsComponent {
  resetArmed = false;

  @Input() config: StepButtonsConfig;

  @Input() back: boolean;
  @Input() exit: boolean;
  @Input() exitAndSave: boolean;
  @Input() done: string;
  @Input() disabled: boolean;
  @Input() reset: boolean;
  @Input() busy?: string;
  @Input() stepName: string;
  @Input() nextButtonName?: string;
  @Input() dialNumber?: number;
  @Input() showNextButton: boolean;
  @Input() confirmButtonGtmClass?: string;

  @Output() backClicked = new EventEmitter<void>();
  @Output() exitClicked = new EventEmitter<void>();
  @Output() exitAndSaveClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();
  @Output() resetClicked = new EventEmitter<void>();

  irisButtonTypes = IrisButtonTypes;
  irisButtonContainerAlignments = IrisButtonContainerAlignments;

  handleReset() {
    if (this.resetArmed) {
      this.resetArmed = false;
      this.resetClicked.emit();
    } else {
      this.resetArmed = true;
    }
  }

  disarm() {
    this.resetArmed = false;
  }
}
