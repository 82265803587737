import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ItemStatus } from './journey-item.model';

@Component({
  selector: 'sondermind-journey-item',
  templateUrl: './journey-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JourneyItemComponent {
  @Input() icon: string;
  @Input() status: ItemStatus;

  statusClass(): string {
    const capFirst: string = this.status.charAt(0).toUpperCase() + this.status.substring(1);
    return `-status${capFirst}`;
  }
}
