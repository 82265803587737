import { Component, EventEmitter, Output } from '@angular/core';
import { WizardService } from '@sondermind/flows-host';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { BreakpointsService } from '@sondermind/utilities/services';

import { IrisButtonTypes } from '@sondermindorg/iris-design-system-angular';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'flows-intake-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class IntakeSplashScreenComponent {
  isTiny$: Observable<boolean> = of(false);
  IrisButtonTypes = IrisButtonTypes;

  @Output() stepNext = new EventEmitter<ResponseUpdateItem[]>();

  constructor(
    public wizard: WizardService,
    private breakpointService: BreakpointsService,
  ) {}

  ngOnInit(): void {
    this.isTiny$ = this.breakpointService.isTiny$;
  }

  startFlow(): void {
    this.wizard.next([]).subscribe(() => {
      this.stepNext?.emit([]);
    });
  }
}
