import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'refdataHelpText',
  pure: true
})
export class RefdataHelpTextPipe implements PipeTransform {
  constructor() {}

  transform(optionLabel: string, refdataHelpText: Array<{ optionLabel: string; helpText: string; }>): SafeHtml {
    if (optionLabel == null) {
      return '';
    }

    // eslint-disable-next-line consistent-return
    return refdataHelpText?.find((entry) => entry.optionLabel === optionLabel)?.helpText;
  }
}
