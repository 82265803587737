<div class="sondermind-design-system multi-section-wrapper">
  <p *ngIf="maxAllowedInfoText" class="topics-limit-text">
    {{ maxAllowedInfoText }}
  </p>
  <div class="multi-section-checkboxes-container">
    <div class="select-checkbox-wrapper-mobile">
      <ng-container *ngFor="let section of sectionList">
        <sonder-checkbox-multi 
          [label]="section.label"
        >
          <ng-container *ngFor="let option of section.options">
            <mat-checkbox 
              matInput 
              color="primary" 
              [(ngModel)]="option.selectValue" 
              (ngModelChange)="handleChange(option.value, option.selectValue)"
              [name]="option.value"
              [disabled]="limitReached && !(option.selectValue)"
            >
              {{ option.label }}
            </mat-checkbox>
          </ng-container>
        </sonder-checkbox-multi>
      </ng-container>
    </div>
  </div>
</div>
