/* eslint-disable no-mixed-operators */
import { Injectable } from '@angular/core';

import {
  BehaviorSubject, Observable, combineLatest
} from 'rxjs';
import {
  filter,
  map, skip, take
} from 'rxjs/operators';

import {
  ITherapistMatch,
  ITherapistTemplate,
  MatchCategory,
  NonDisplayedMatchCategories,
  ProviderMatchStatus,
  RequestedSessionType
} from '@sondermind/utilities/models-therapist';
import { ClientUser } from '@sondermind/utilities/models-user-client';
import { MatchAssignmentType } from '@sondermind/utilities/models-matching';
import { IAPIListRequestOptions } from '@sondermind/utilities/models-http';
import { ClientReferralHttpService } from '@sondermind/data-access/client-referral';
import { CurrentUser, ClientUserTags } from '@sondermind/data-access/client-user';

import { ITherapistStore } from './therapist-store.interface';
import { TherapistHttpService } from '../services/therapist.httpservice';

const EXPIRED_AND_TIMED_OUT_FILTER: Partial<IAPIListRequestOptions> = {
  filterArray: [
    { key: 'exclude_aasm_states', value: 'timed_out' },
    { key: 'exclude_aasm_states', value: 'expired' }
  ]
};

@Injectable()
export class TherapistStore implements ITherapistStore {
  private therapistListSubj: BehaviorSubject<ITherapistTemplate[]> = new BehaviorSubject<ITherapistTemplate[]>([]);
  loadingComplete$ = new BehaviorSubject(false);

  // used to quickly determine display types in components
  allTherapistsDeclined: boolean = false;
  oneOfManyTherapistDeclined: boolean = false;
  allTherapistsDirectScheduleEnabled: boolean = false;
  directScheduleMix: boolean = false;
  hasManualMatches: boolean = false;
  hasOpenNcr: boolean = false;
  hasScheduledTherapist: boolean = false;
  hasSingleActiveMatch: boolean = false;
  noMatchesYet: boolean = false;
  noTherapistsDirectScheduleEnabled: boolean = false;
  showMatchedTherapistLists: boolean = false;

  hasAnyMatches$ = this.fullTherapistList$().pipe(
    map((therapists) => therapists.some((therapist) => !this.isDeclined(therapist))),
  );

  fullTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.therapistListSubj.asObservable();
  }

  inPostSessionRequestState$ = this.fullTherapistList$().pipe(
    map((therapists) => therapists.some((t: ITherapistTemplate) => this.isTherapistPostSessionRequestState(t))
    ));

  /**
   * Propose a time or request to schedule therapist list
   */
  patOrRtsTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter((t: ITherapistTemplate) => this.isPendingPatRtsOrConsult(t)))
    );
  }

  isPendingPatRtsOrConsult(therapist: ITherapistTemplate): boolean {
    return ((this.hasRTSSessionRequested(therapist) || this.hasExistingPatRequest(therapist))
      && !this.isAccepted(therapist)
      && !this.isDeclined(therapist)) ||
      (this.hasConsultationRequested(therapist) && !this.isDirectScheduleEnabled(therapist) && !this.isDeclined(therapist));
  }

  /**
   * Therapists that have been accepted
   */
  acceptedAndRequestedTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter(
        (t: ITherapistTemplate) => this.isAcceptedAndRequestedTherapist(t)
      ))
    );
  }

  isAcceptedAndRequestedTherapist(therapist: ITherapistTemplate): boolean {
    return this.isAccepted(therapist) && (this.hasRTSSessionRequested(therapist) || this.hasConsultationRequested(therapist));
  }

  /**
   * Filter list of associated therapists by SCHEDULED status.
   */
  scheduledTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter((t: ITherapistTemplate) => this.isScheduled(t)))
    );
  }

  /**
   * Filter list of associated therapists by NOTIFIED and ACCEPTED status.
   */
  notifiedTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter(
        (t: ITherapistTemplate) => this.isActionable(t) && !this.isAdditionalMatch(t)
      ))
    );
  }

  /**
   * Filter list of associated therapists by the additional match status
   */
  additionalTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter((t: ITherapistTemplate) => this.isAdditionalMatch(t)))
    );
  }

  /**
   * Filter list of associated therapists by the member selected status
   */
  memberSelectedTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter((t: ITherapistTemplate) => this.isMemberSelectedMatch(t)))
    );
  }
  /**
   * Filter list of associated therapists by DECLINED status.
   */
  declinedTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter((t: ITherapistTemplate) => this.isDeclined(t)))
    );
  }

  getOnHoldTherapistList$(): Observable<ITherapistTemplate[]> {
    return this.fullTherapistList$().pipe(
      map((tList) => tList.filter(
        (t: ITherapistTemplate) => this.isOnHoldMatch(t)
      ))
    );
  }

  constructor(
    private currentUser: CurrentUser,
    private therapistHttpService: TherapistHttpService,
    private referralDataService: ClientReferralHttpService
  ) {
    this.currentUser.loggedIn$.pipe(
      filter(Boolean),
      take(1)
    ).subscribe(() => this.loadAll());
  }

  // provider invited clients do not have a client referral so it causes incorrect behavior when we apply
  // the exclude filter
  getTherapistFilter(): Partial<IAPIListRequestOptions> {
    if (this.currentUser?.user?.providerInvited) return {};
    return EXPIRED_AND_TIMED_OUT_FILTER;
  }

  /**
   * Load all therapists associated with client. First, check if any SCHEDULED
   * therapists exist.
   * If any SCHEDULED exist, there is no need to fetch the rest because we won't
   * show non-SCHEDULED in this client portal state.
   * If no SCHEDULED exist, fetch full list so that the different lists by status can be
   * displayed across the portal.
   * TODO: for Client Search and Filter epic, may need to update to use shareReplay pattern
   */
  loadAll(): void {
    this.loadingComplete$.next(false);
    this.therapistHttpService.listAll(this.getTherapistFilter()).subscribe({
      next: (resp: ITherapistTemplate[]) => {
        this.updateStoreState(resp);
        this.loadingComplete$.next(true);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  waitUntilLoaded$(): Observable<void> {
    return this.loadingComplete$.pipe(
      filter((loaded) => loaded),
      take(1),
      map(() => undefined)
    );
  }

  /**
   * Load all therapists as well as the boolean variables associated with them
   * Create a observable pattern in order to subscribe to the result on other components
   * @returns voided observable
   */
  loadAll$(opts: { resetLoadingComplete: boolean; } = { resetLoadingComplete: true }): Observable<void> {
    if (opts.resetLoadingComplete) {
      this.loadingComplete$.next(false);
    }

    return this.therapistHttpService.listAll(this.getTherapistFilter()).pipe(
      map((resp: ITherapistTemplate[]) => {
        this.updateStoreState(resp);
        this.loadingComplete$.next(true);
        return undefined;
      })
    );
  }

  updateClientSentNcc$(personaProviderId: number): Observable<void> {
    return this.therapistHttpService.clientSentNcc(personaProviderId);
  }

  /**
   * Order is important here to set various state checks.
   */
  private updateStoreState(resp: ITherapistTemplate[]): void {
    const filteredList = this.getPendingMatchesAndScheduledTherapists(resp);
    this.hasScheduledTherapist = this.checkScheduledStatus(filteredList);
    this.hasOpenNcr = this.checkNcrStatus(filteredList);
    this.hasManualMatches = this.checkManualMatchedStatus(filteredList);
    this.noMatchesYet = this.checkNoMatchesYet(filteredList);
    this.allTherapistsDeclined = this.checkDeclinedStatus(filteredList);
    this.oneOfManyTherapistDeclined = this.checkOneOfManyDeclinedStatus(filteredList);
    this.allTherapistsDirectScheduleEnabled = this.checkAllDirectScheduleEnabled(filteredList);
    this.noTherapistsDirectScheduleEnabled = this.checkNoDirectScheduleEnabled(filteredList);
    this.directScheduleMix = !this.noTherapistsDirectScheduleEnabled && !this.allTherapistsDirectScheduleEnabled;
    this.hasSingleActiveMatch = this.getNumberOfActiveMatches(filteredList) === 1;
    this.showMatchedTherapistLists = !this.allTherapistsDeclined && !this.noMatchesYet;
    this.therapistListSubj.next(filteredList);
    this.checkIsPslClient();
  }

  checkIsPslClient() {
    this.currentUser.user$.pipe(take(1)).subscribe((user: ClientUser) => {
      // We want to show the PSL flow if they have a PSL tag, they aren't scheduled yet or they have been declined by their one PSL Provider.
      // If all of these conditions are not met, throw them into the normal flow.
      const isPslClient = user?.tags?.includes(ClientUserTags.PSL_SOURCED) && !this.hasScheduledTherapist && !this.oneOfManyTherapistDeclined;
      this.currentUser.setIsPslClient(isPslClient);
    });
  }
  /**
   * Used by TherapistResolver to decide if therapist's profile page can be shown.
   * If it's not in the store or if they have DECLINED, we shouldn't show the details.
   * skip/take pattern used here for handling page refresh on /therapist/[id]
   */
  getMatchedTherapist$(id: number): Observable<ITherapistTemplate | undefined> {
    return this.fullTherapistList$().pipe(
      skip(1),
      take(1),
      map(
        (tList) => tList.find((t) => t.userId === id && !this.isDeclined(t))
      )
    );
  }

  /**
   * Check if therapist has been SCHEDULED on NCR.
   *
   * From the Swagger documentation:
   *
   *  Indicates whether or not the current client is in a "scheduled" state with this provider.
   *  A client is in the "scheduled" state if any of the following conditions are true:
   *    - The client was matched with the provider and is now scheduled. (crm.aasm_state == "scheduled")
   *    - The client was invited by the provider and is now active. (patient.invite_sent_at && patient.active)
   *    - The client has any upcoming scheduled sessions with the provider.
   */
  isScheduled(therapist: ITherapistTemplate): boolean {
    return therapist.inScheduledState;
  }

  /**
   * Check if therapist has DECLINED on any NCR.
   */
  isDeclined(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.status === ProviderMatchStatus.DECLINED);
  }

  /**
   * Check if therapist has ACCEPTED on an NCR.
   */
  isAccepted(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.status === ProviderMatchStatus.ACCEPTED);
  }

  /**
   * Check if therapist has NOTIFIED on an NCR.
   */
  isNotified(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.status === ProviderMatchStatus.NOTIFIED);
  }

  /**
   * Check if therapist is matched but not yet scheduled via either NOTIFIED, NOTIFICATION_DELAYED, or ACCEPTED status.
   */
  isActionable(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.actionable);
  }

  isOnHoldMatch(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.status === ProviderMatchStatus.ON_HOLD);
  }

  isAdditionalMatch(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.additional_match);
  }

  isMemberSelectedMatch(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.status === ProviderMatchStatus.MEMBER_SELECTED);
  }
  isTherapistPostSessionRequestState(therapist: ITherapistTemplate): boolean {
    return ((this.hasRTSSessionRequested(therapist) || this.hasExistingPatRequest(therapist)) &&
      (this.isNotified(therapist) || this.isAccepted(therapist))) || this.hasConsultationRequested(therapist);
  }

  getMatch(therapist: ITherapistTemplate, referralId: number): ITherapistMatch {
    return therapist?.matches?.find((x) => x.referral_id === referralId);
  }

  getCategoryFromMatch(match: ITherapistMatch, appSciFlagOff: boolean, categoriesLDFlagOn: boolean): MatchCategory {
    const hasValidCategory = Object.values(MatchCategory).includes(match.category);
    const willUseMatchCategory = hasValidCategory && !appSciFlagOff
      && categoriesLDFlagOn && !NonDisplayedMatchCategories.has(match.category);
    const isAssignmentTopRecommended = match.match_assignment === MatchAssignmentType.TOP_RECOMMENDED
      || match.match_assignment === MatchAssignmentType.RUNNER_UP;

    if (willUseMatchCategory) return match.category;
    if (isAssignmentTopRecommended) return MatchCategory.TOP_RECOMMENDED;
    return MatchCategory.OTHER_TOP_SCORERS;
  }

  getNonDeclinedTherapistsOnMostRecentNcr$(): Observable<ITherapistTemplate[]> {
    return combineLatest({
      allTherapists: this.fullTherapistList$(),
      mostRecentReferral: this.referralDataService.mostRecentReferral$
    }).pipe(
      map((data) => data.allTherapists
        .filter((t) => !this.isDeclined(t))
        .filter((t) => !!this.getMatch(t, data.mostRecentReferral?.id))
      )
    );
  }

  /**
   * Check if therapist has any request sessions from the client.
   */
  hasRTSSessionRequested(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.session_requested && match.requested_session_type === RequestedSessionType.SESSION);
  }

  hasConsultationRequested(therapist: ITherapistTemplate): boolean {
    return therapist.matches?.some((match) => match.session_requested && match.requested_session_type === RequestedSessionType.FREE_CONSULTATION);
  }

  /**
   * Check if therapist has existing propose a time request.
   */
  hasExistingPatRequest(therapist: ITherapistTemplate): boolean {
    return therapist.hasPendingPatSession;
  }

  /**
   * Check if therapist has propose-a-time enabled
   */
  hasProposeATimeEnabled(therapist: ITherapistTemplate): boolean {
    return therapist.proposeATimeEnabled;
  }

  /**
   * Check if therapist has been automatched or manual matched
   * @param therapist
   * @returns true if manual match, false if automatch
   */
  private isManualMatched(therapist: ITherapistTemplate): boolean {
    return therapist.matches.some((match) => !match.auto_matched);
  }

  /**
   * Check if therapist offers direct scheduling
   * @param therapist
   * @returns true if direct schedule is enabled and therapist has accepted the match
   */
  isDirectScheduleEnabled(therapist: ITherapistTemplate): boolean {
    return therapist.directSchedulingEnabled
      && therapist.matches.some((match) => match.status === ProviderMatchStatus.ACCEPTED);
  }

  /**
   * Check if therapist and client relationship is established
   * @param therapist
   * @returns true if therapist status is ACCEPTED or SCHEDULED or if client is provider-invited
   */
  checkTherapistRelationshipEstablished(therapist: ITherapistTemplate): boolean {
    // isScheduled checks for either SCHEDULED status or if client is providerInvited
    return this.isScheduled(therapist) || this.isAccepted(therapist);
  }

  /**
   * @param therapistList
   * @returns if any provider was manually matched, return true
   * else return false
   */
  private checkManualMatchedStatus(therapistList: ITherapistTemplate[]): boolean {
    return therapistList.some((therapist) => this.isManualMatched(therapist));
  }

  /**
   * If any provider is SCHEDULED, return true.
   */
  private checkScheduledStatus(therapistList: ITherapistTemplate[]): boolean {
    return therapistList.some((t) => this.isScheduled(t));
  }

  /**
   * If there are no providers, assumption is that they are on initial NCR.
   * If any provider is not SCHEDULED, this means there is an open NCR.
   */
  private checkNcrStatus(therapistList: ITherapistTemplate[]): boolean {
    return therapistList.length === 0 || therapistList.some((t) => this.isActionable(t));
  }

  /**
   * Check if any therapists in list have matches of a status the client
   * can view for an open NCRs.
   */
  checkNoMatchesYet(therapistList: ITherapistTemplate[]): boolean {
    return !therapistList.some((t: ITherapistTemplate) => this.isDeclined(t) || this.isActionable(t));
  }

  /**
   * Check if all therapists on open NCRs have declined.
   */
  checkDeclinedStatus(therapistList: ITherapistTemplate[]): boolean {
    const declinedList = therapistList.filter((t: ITherapistTemplate) => this.isDeclined(t));

    return declinedList.length > 0
      && declinedList.length === therapistList.filter((t) => !this.isScheduled(t)).length;
  }

  /**
   * Check if one therapists on open NCRs have declined.
   */
  checkOneOfManyDeclinedStatus(therapistList: ITherapistTemplate[]): boolean {
    const declinedList = therapistList.filter((t: ITherapistTemplate) => this.isDeclined(t));
    return declinedList.length > 0 && therapistList.length > declinedList.length;
  }

  /**
   * Check if all therapists are direct schedule enabled.
   */
  checkAllDirectScheduleEnabled(therapistList: ITherapistTemplate[]): boolean {
    const directScheduleList = therapistList.filter((t: ITherapistTemplate) => this.isDirectScheduleEnabled(t));

    return directScheduleList.length > 0
      && directScheduleList.length === therapistList.filter((t) => !this.isScheduled(t)).length;
  }

  /**
   * Check if no therapists are direct schedule enabled.
   */
  checkNoDirectScheduleEnabled(therapistList: ITherapistTemplate[]): boolean {
    const directScheduleList = therapistList.filter((t: ITherapistTemplate) => this.isDirectScheduleEnabled(t));
    return directScheduleList.length === 0;
  }

  /**
   * Following scenarios for Request to Schedule
   * therapist is notified and does not have Direct Scheduling -> show RTS
   * therapist is notified and has DS -> show RTS
   * therapist is accepted and does not have DS -> show RTS
   * therapist is accepted and has DS -> do not show RTS
   * therapist is scheduled -> do not show RTS
   *
   */
  canShowRequestToSchedule(therapist: ITherapistTemplate): boolean {
    const isDirectScheduleEnabled = therapist.directSchedulingEnabled && this.currentUser?.user?.allowScheduling;

    return !this.checkTherapistRelationshipEstablished(therapist)
      || (therapist.proposeATimeForInvitedClientsFeatureFlag && this.currentUser.user?.providerInvited && !isDirectScheduleEnabled)
      || (this.isAccepted(therapist) && !isDirectScheduleEnabled)
      || therapist.hasPendingPatSession;
  }

  /**
   * From full therapist list we want to do the following to filter it down:
   * 1. If there are any SCHEDULED providers, remove others on same NCR.
   * 2. If there are no SCHEDULED providers, keep all from NCR.
   * Assumptions and notes:
   * 1. If an NCR has a SCHEDULED provider, the NCR is closed.
   * 2. If an an NCR has no SCHEDULED provider, the NCR is active and has 0 to N pending matches.
   * 3. If there are any SCHEDULED providers, appointments, schedule, etc, will show.
   * 4. If there is an open NCR, pending therapist match UI will show.
   * 5. It is possible for both to show simultaneously.
   *   a) Generally this will happen when a client requests a rematch flow.
   *   b) It is possbile (but extremely unlikely) that more than one open NCR can exist.
   *
   * The resulting list contains only:
   * 1. SCHEDULED providers.
   * 2. Providers on open NCRs that are not EXPIRED.
   */
  private getPendingMatchesAndScheduledTherapists(therapistList: ITherapistTemplate[]): ITherapistTemplate[] {
    const scheduledTherapistList = therapistList.filter((t) => this.isScheduled(t));
    const scheduledReferralIdList = this.getReferralIdList(scheduledTherapistList);

    return therapistList.filter((t) => scheduledTherapistList.includes(t)
      || this.therapistOnAtLeastOneOpenNcr(t, scheduledReferralIdList));
  }

  /**
   * For a list of ITherapistTemplate, return a unique list of NCR IDs (referral_id).
   */
  private getReferralIdList(therapistList: ITherapistTemplate[]): number[] {
    let referralIdList: number[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const therapist of therapistList) {
      // NOTE: if a provider has no matches, they count as "SCHEDULED"
      // see `isScheduled` above for more details
      if (therapist.matches?.length > 0) {
        // NOTE: possible, but unlikely, that one provider appears on multiple NCRs attached to a client
        // only the client's NCR's will be includes on the `matches` for a ITherapistTemplate
        // no need to check for uniqueness because one provider will not be SCHEDULED on multiple NCRs
        const idList = therapist.matches.map((m) => m.referral_id);

        referralIdList = referralIdList.concat(idList);
      }
    }

    return referralIdList;
  }

  /**
   * A therapist is NOT on an open NCR when:
   * 1. They have no matches.
   * 2. All their matches are status EXPIRED.
   * 3. All their matches' referral_ids are in the closedReferralIdList.
   */
  private therapistOnAtLeastOneOpenNcr(therapist: ITherapistTemplate, closedReferralIdList: number[]) {
    if (therapist.matches?.length === 0 || this.isFullyExpired(therapist)) {
      return false;
    }

    const openIdList = therapist.matches.map((m) => m.referral_id).filter((id) => !closedReferralIdList.includes(id));

    return openIdList.length > 0;
  }

  /**
   * Check if therapist has been expired from all related NCRs.
   * If there are no matches, provider had invited client and is not on any NCR.
   */
  private isFullyExpired(therapist: ITherapistTemplate): boolean {
    if (therapist.matches?.length > 0) {
      return !therapist.matches.some((m) => m.status !== ProviderMatchStatus.EXPIRED);
    }

    return false;
  }

  /**
   * Check number of matches in either NOTIFIED or ACCEPTED status.
   */
  private getNumberOfActiveMatches(therapistList: ITherapistTemplate[]): number {
    return therapistList.filter((t) => this.isAccepted(t) || this.isNotified(t)).length;
  }
}
