import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ITherapistTemplate } from '@sondermind/utilities/models-therapist';

export interface ITherapistStore {
  scheduledTherapistList$: () => Observable<ITherapistTemplate[]>;
  notifiedTherapistList$: () => Observable<ITherapistTemplate[]>;
  additionalTherapistList$: () => Observable<ITherapistTemplate[]>;
  memberSelectedTherapistList$: () => Observable<ITherapistTemplate[]>;
  declinedTherapistList$: () => Observable<ITherapistTemplate[]>;
  fullTherapistList$: () => Observable<ITherapistTemplate[]>;
  loadAll$: () => Observable<void>;
  waitUntilLoaded$: () => Observable<void>;
  updateClientSentNcc$?: (personaProviderId: number) => Observable<void>;
  canShowRequestToSchedule: (therapist: ITherapistTemplate) => boolean;
  hasRTSSessionRequested: (therapist: ITherapistTemplate) => boolean;
  hasConsultationRequested: (therapist: ITherapistTemplate) => boolean;
  hasExistingPatRequest: (therapist: ITherapistTemplate) => boolean;
  hasProposeATimeEnabled: (therapist: ITherapistTemplate) => boolean;
  checkTherapistRelationshipEstablished: (therapist: ITherapistTemplate) => boolean;
}

export const THERAPIST_STORE = new InjectionToken<ITherapistStore>('Therapist Store');
