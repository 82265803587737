import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';

import { MultiSelectImageItem } from '@sondermind/flows-host';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';

import { IntakeStepComponent } from '../intake-step.component';
import { IDropdownConfig } from '../basic-form/model';
import { MIN_OPTIONS_FOR_DROPDOWN } from '../../utilities/constants';

@Component({
  selector: 'flows-intake-dropdown',
  templateUrl: './intake-flow-dropdown.component.html',
  styleUrls: ['./intake-flow-dropdown.component.scss']
})
export class IntakeDropdown extends IntakeStepComponent<IDropdownConfig> implements OnInit, OnDestroy {
  minOptionsForDropdown = MIN_OPTIONS_FOR_DROPDOWN;
  options: MultiSelectImageItem[] = [];
  selectedCheckboxes: string[] = [];

  placeholder: string = '';
  isMulti: boolean = false;

  hasMaxSelectionsError: boolean = false;

  selectFormGroup: FormGroup;

  ngOnInit(): void {
    this.isMulti = this.config.isMulti;
    this.placeholder = this.config.placeholder;

    this.initForm();
    this.initOptions();
    this.initSelectedOptions();

    this.selectFormGroup.controls.select.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (newSelectValues: string[]) => {
        this.hasMaxSelectionsError = this.selectFormGroup.get('select').hasError('maxOptions');
        this.valid.next(newSelectValues?.length > 0 && !this.hasMaxSelectionsError);
      }
    );
    this.valid.next(this.selectedCheckboxes.length > 0);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initForm(): void {
    const startingValue = this.isMulti ? [] : null;

    this.selectFormGroup = new FormGroup({
      select: new FormControl(startingValue, Validators.required)
    });
  }

  initOptions(): void {
    this.options = [
        ...this.wizard.context,
        ...this.config.additionalOptions || []
    ].map((x) => ({ ...x, name: x.label }));
  }

  initSelectedOptions(): void {
    const selectedOptionFromWizardSvc = this.data[this.config.name];
    if (selectedOptionFromWizardSvc) {
      if (this.isMulti) {
        if (typeof selectedOptionFromWizardSvc === 'string') {
          this.selectedCheckboxes = [selectedOptionFromWizardSvc];
          this.selectFormGroup.patchValue({
            select: [selectedOptionFromWizardSvc]
          });
        } else {
          this.selectedCheckboxes = selectedOptionFromWizardSvc;
          this.selectFormGroup.patchValue({
            select: selectedOptionFromWizardSvc
          });
        }
      } else {
        // Always pass single string to a single select dropdown
        this.selectedCheckboxes = selectedOptionFromWizardSvc;
        this.selectFormGroup.patchValue({
          select: selectedOptionFromWizardSvc
        });
      }
    }
  }

  handleCheckboxChange(optionValue: string, checkStatus: boolean): void {
    if (checkStatus) {
      // Add the optionValue to the list
      this.selectedCheckboxes.push(optionValue);
    } else {
      // Remove the optionValue from the list
      const index = this.selectedCheckboxes.indexOf(optionValue);
      if (index > -1) {
        this.selectedCheckboxes.splice(index, 1);
      }
    }

    this.valid.next(this.selectedCheckboxes.length > 0);
  }

  onSubmit(): ResponseUpdateItem[] {
    if (this.options.length >= this.minOptionsForDropdown) {
      return [{
        human: this.config.name,
        key: this.config.name,
        value: this.selectFormGroup.controls.select.value
      }];
    }

    if (!this.isMulti) {
      return [{
        human: this.config.name,
        key: this.config.name,
        value: this.selectedCheckboxes[0]
      }];
    }

    return [{
      human: this.config.name,
      key: this.config.name,
      value: this.selectedCheckboxes
    }];
  }
}
