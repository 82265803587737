<div class="sonder-form-field sonder-form-checkbox-multi">
  <div class="sonder-form-field-label" *ngIf="label" fxFlexLayout="row">
    <span class="sonder-form-field-label-text" fxFlex="0 0 auto">{{ label }}</span>
    <span class="sonder-form-field-label-required" fxFlex="0 0 auto" *ngIf="required">*</span>
    <ul class="sonder-form-errors" fxFlex="1 1 100%">
      <li *ngFor="let error of errors" [@slideUp]="'in'">{{ error }}</li>
    </ul>
  </div>
  <ng-content></ng-content>
</div>
