import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, forwardRef } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { WebcamModule } from 'ngx-webcam';

import { IrisButtonModule, IrisIconModule } from '@sondermindorg/iris-design-system-angular';
import { FILE_UPLOAD_SERVICE, UploadDataService } from '@sondermind/data-access/formlib';
import { InvalidValueValidatorDirective } from '@sondermind/utilities/forms';

import { FormBackButtonDirective, FormNextButtonDirective } from './components/buttons/form-back-button.directive';
import { FormLinkButtonDirective } from './components/buttons/form-link-button.directive';
import { FormMiniButtonDirective } from './components/buttons/form-mini-button.directive';
import { CardUploadComponent } from './components/card-upload/card-upload.component';
import { CheckboxFieldComponent } from './components/checkbox-field/checkbox-field.component';
import { CheckboxMultiComponent } from './components/checkbox-multi/checkbox-multi.component';
import { ConfirmableButtonComponent } from './components/confirmable-button/confirmable-button.component';
import { ExternalLinkDirective } from './components/external-link.directive';
import { FormBodyComponent } from './components/form-body.component';
import { FormButtonContainerComponent } from './components/form-button-container';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormHelpTextComponent } from './components/form-helptext.component';
import { FormLegendComponent } from './components/form-legend/form-legend.component';
import { FormSeparatorDirective } from './components/form-separator.directive';
import { FormStandaloneFieldDirective } from './components/form-standalone-field.directive';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { OptionCheckboxComponent } from './components/option-checkbox/option-checkbox.component';
import { OptionDiscComponent } from './components/option-disc/option-disc.component';
import { PopupComponent } from './components/popup/popup.component';
import { PopupTriggerDirective } from './components/popup/trigger.directive';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RatingComponent } from './components/rating/rating.component';

import { CardCVCValidatorDirective } from './directives/card-cvc-validator.directive';
import { CardNumberValidatorDirective } from './directives/card-number-validator.directive';
import { ConfirmationValidatorDirective } from './directives/confirmation-validator.directive';
import { MediaqueryClassDirective } from './directives/mediaquery-class.directive';
import { MediaqueryIfDirective } from './directives/mediaquery-if.directive';
import { NoWrapDirective } from './directives/no-wrap.directive';
import { PasswordValidatorDirective } from './directives/password-validator.directive';
import { StopPropogationDirective } from './directives/stop-propogation.directive';
import { TextMaskValidatorDirective } from './directives/text-mask-validator.directive';

import { BindTemplatePipe } from './pipes/bind-template.pipe';
import { CardNicknamePipe } from './pipes/card-nickname.pipe';
import { CardTypeIconNamePipe } from './pipes/card-type-icon-name.pipe';
import { ConformToMaskPipe } from './pipes/conform-to-mask.pipe';
import { RefdataHelpTextPipe } from './pipes/refdata-help-text.pipe';
import { USStateNamePipe } from './pipes/us-state-name.pipe';

import { ImageUploadService } from './services/image-upload.service';
import { UTCNativeDateAdapter } from './services/utc-date-adapter.service';

const COMPONENTS = [
  CardUploadComponent,
  ImageUploadComponent,
  CheckboxFieldComponent,

  FormBodyComponent,
  FormButtonContainerComponent,
  FormFieldComponent,
  FormHelpTextComponent,
  FormLegendComponent,
  FormSeparatorDirective,
  FormStandaloneFieldDirective,
  OptInComponent,
  OptionCheckboxComponent,
  OptionDiscComponent,
  RadioGroupComponent,
  CheckboxMultiComponent,
  RatingComponent,

  ExternalLinkDirective,

  FormNextButtonDirective,
  FormBackButtonDirective,
  FormMiniButtonDirective,
  FormLinkButtonDirective,
  ConfirmableButtonComponent,

  PopupComponent,
  PopupTriggerDirective,

  MediaqueryIfDirective,
  MediaqueryClassDirective,

  ConfirmationValidatorDirective,
  CardNumberValidatorDirective,
  CardCVCValidatorDirective,
  InvalidValueValidatorDirective,
  NoWrapDirective,
  PasswordValidatorDirective,
  TextMaskValidatorDirective,
  StopPropogationDirective,

  BindTemplatePipe,
  CardNicknamePipe,
  CardTypeIconNamePipe,
  ConformToMaskPipe,
  RefdataHelpTextPipe,
  USStateNamePipe,
];

@NgModule({
  imports: [
    CommonModule,
    IrisButtonModule,
    IrisIconModule,
    FormsModule,
    FlexLayoutModule,
    PortalModule,
    OverlayModule,
    WebcamModule,
    MatButtonToggleModule,
    MatIconModule,
    MatLegacyProgressBarModule,
    MatLegacyProgressSpinnerModule,
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    ImageUploadService,
    NgForm,
    { provide: FILE_UPLOAD_SERVICE, useExisting: forwardRef(() => UploadDataService) },
  ]
})
export class FormlibModule {
  static forRoot(): ModuleWithProviders<FormlibModule> {
    return {
      ngModule: FormlibModule,
      providers: [
        { provide: DateAdapter, useClass: UTCNativeDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS }
      ]
    };
  }
}
