import {
  Component, EventEmitter, OnDestroy, OnInit
} from '@angular/core';
import { Observable, takeUntil } from 'rxjs';

import { COMMON } from '@sondermind/constants';
import { BreakpointsService } from '@sondermind/utilities/services';

import { WizardService } from '@sondermind/flows-host';
import { ConfigurationService } from '@sondermind/configuration';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { IntakeStepComponent } from '../../intake-step.component';

export interface IResendConfig extends StepItemConfig {
  headerTitle: string;
}

@Component({
  selector: 'flows-intake-independent-resend',
  templateUrl: './independent-intake-resend.component.html',
  styleUrls: ['./independent-intake-resend.component.scss']
})
export class IndependentIntakeResendComponent extends IntakeStepComponent<IResendConfig> implements OnInit, OnDestroy {
  supportPhoneLink = COMMON.SUPPORT_PHONE_NO_MATCH_LINK;
  supportPhoneDisplay = COMMON.SUPPORT_PHONE_NO_MATCH_DISPLAY;
  supportEmail = COMMON.SUPPORT_EMAIL;

  showConfirmation: boolean = false;
  isMobile$: Observable<boolean>;
  hasText: boolean = false;
  urlBase: string;

  destroyed$ = new EventEmitter<void>();

  constructor(
    private configurationService: ConfigurationService,
    wizard: WizardService,
    private breakpointService: BreakpointsService
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.urlBase = this.configurationService.env.urlBase;
    this.isMobile$ = this.breakpointService.isMobile$;
    this.hasText = this.wizard.response?.data?.commsPrefs?.includes('sms');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from resend email!');
  }

  /**
   * Make the API call to resend the confirmation email
   * and show "email sent" message no matter the API response.
   * We only want to send one request per user.
   */
  handleResend(): void {
    // If we are already showing the confirmation, don't send another resent request
    if (this.showConfirmation) {
      return;
    }

    this.wizard.resendEmail().pipe(takeUntil(this.destroyed$)).subscribe();
    this.showConfirmation = true;
  }
}
