<div class="confirmation-step-wrapper sondermind-design-system">
    <div class="confirmation-step-journey-header skip-formlib-override">
        <sondermind-journey-header [title]="config.headerTitle">
            <sondermind-journey-item *ngFor="let item of config.journeyItems" [icon]="item.icon" [status]="$any(item.status)">
                {{ item.text }}
            </sondermind-journey-item>
        </sondermind-journey-header>
    </div>
    <div class="confirmation-step-hero" sonderMediaqueryClass="mq">
        <!-- We have two views 1st is Confirmation and the 2nd Complete view -->
        <!-- Both views should have hero and info text according to that, with buttons -->
        <ng-container *ngIf="isConfirmationView; else completeView">
            <div class="hero-inner-wrapper">
                <p [innerHTML]="config.infoText | unsafeHtml"></p>

                <button mat-button sondermind-primary sondermind-nav class="skip-formlib-override" (click)="handleExit()">
                    {{ component.done }}
                </button>

                <a class="next-button" *ngIf="component.next" (click)="handleNext()">
                    {{ config.nextButtonName || "Next" }}
                </a>
            </div>
        </ng-container>

        <ng-template #completeView>
            <div class="hero-inner-wrapper">
                <p>{{ config.complete.infoText }}</p>

                <button mat-button sondermind-primary sondermind-nav class="skip-formlib-override" (click)="handleExit()">
                    {{ config.complete.done }}
                </button>
            </div>
        </ng-template>
    </div>
</div>
