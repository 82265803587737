import { Component, Input } from '@angular/core';

@Component({
  selector: 'flows-step-footer',
  templateUrl: './step-footer.component.html',
  styleUrls: ['./step-footer.component.scss']
})
export class StepFooterComponent {
  @Input() footer: string;
}
