import {
  Directive, TemplateRef, ViewContainerRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { FeatureFlags } from '@sondermind/utilities/services';

@Directive({
  selector: '[sondermindFeatureFlag]',
  exportAs: 'featureFlag'
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // once we've gotten our name, switch to checking the flag
  private nameSubj = new BehaviorSubject<string>('');
  @Input('sondermindFeatureFlag')
  set sondermindFeatureFlag(value: string) {
    this.nameSubj.next(value);
  }

  /**
   * Use this input iff you want to invert the display of the template.
   * @example <div *sondermindFeatureFlag="'SomeFeature'; invertCheck: true"></div>
   */
  private invertFeatureCheckSubj = new BehaviorSubject<boolean>(false);
  @Input('sondermindFeatureFlagInvertCheck')
  set invertFeatureCheck(value: boolean) {
    this.invertFeatureCheckSubj.next(value);
  }

  // have we built the view? should we tear it down?
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlags: FeatureFlags
  ) {

  }

  ngOnInit() {
    const featureEnabled$ = this.nameSubj.pipe(
      switchMap((featureName) => this.featureFlags.check$(featureName))
    );

    combineLatest([featureEnabled$, this.invertFeatureCheckSubj]).pipe(
      map(([enabled, invertCheck]) => (invertCheck ? !enabled : enabled)),
      takeUntil(this.destroy$)
    ).subscribe((v) => this.onVisible(v));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // when visible changes, either build or tear down the template as appropriate
  private onVisible(visible: boolean): void {
    if (visible && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    if (!visible && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
