import {
  Component, EventEmitter, forwardRef, Input, Output, OnInit
} from '@angular/core';
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors
} from '@angular/forms';

import { ITherapyReason } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'sonder-therapy-reason',
  templateUrl: './therapy-reason.component.html',
  styleUrls: ['./therapy-reason.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TherapyReasonComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TherapyReasonComponent), multi: true }
  ]
})
export class TherapyReasonComponent implements OnInit, ControlValueAccessor {
  @Input() required = false;
  @Input() disabled = false;

  @Input() name: string;
  @Input() value: { [key: string]: boolean; } = {};
  @Input() formValue: { [key: string]: { [key: string]: boolean; }; } = {};
  @Input() optionList: ITherapyReason[] = [];
  @Input() isMobile: boolean;
  @Input() maxItemsAllowed: number;
  @Input() maxAllowedInfoText: string;
  @Output() valueChanged = new EventEmitter<{ [key: string]: boolean; }>();

  generalTopicList: ITherapyReason[];
  // NOTE: (PE-15160) used for psychiatric-specific helperText formatting
  psychiatricTopicList: ITherapyReason[];
  moreTopicList: ITherapyReason[];
  // NOTE: used to display columns properly in desktop view
  generalHalfLength: number;
  moreHalfLength: number;
  // NOTE: used for disabling options once maxItemsAllowed has been selected
  limitReached: boolean = false;

  ngOnInit() {
    this.splitReasonList();
    this.reselectOptions();
  }

  /**
   * Divide up options by type and remove any general topics duplicates from more topics
   * set half list lengths for proper alphabetical display in desktop view
   */
  splitReasonList(): void {
    this.generalTopicList = this.optionList.filter((reason) => reason.is_general_topic && !reason.is_psychiatric_topic);
    this.psychiatricTopicList = this.optionList.filter((reason) => reason.is_psychiatric_topic && !reason.is_general_topic);
    this.moreTopicList = this.optionList.filter((reason) => !reason.is_general_topic && !reason.is_psychiatric_topic && !this.generalTopicList.map((t) => t.value).includes(reason.value));

    this.generalHalfLength = Math.ceil(this.generalTopicList.length / 2);
    this.moreHalfLength = Math.ceil(this.moreTopicList.length / 2);
  }

  /**
   * Reselect options is any were previously selected and user navigates to a different page.
   */
  reselectOptions(): void {
    [...this.generalTopicList, ...this.psychiatricTopicList, ...this.moreTopicList].forEach((topic) => {
      if (this.formValue[this.name][topic.value]) {
        // eslint-disable-next-line no-param-reassign
        topic.selectValue = true;
      }
    });

    this.limitSelection(this.formValue[this.name]);
  }

  /**
   * To be valid for submission, at least one option must be true.
   */
  atLeastOneOptionSelected(): boolean {
    return this.value && Object.keys(this.value).some((key) => this.value[key]);
  }

  /**
   * Update `value` object when one of the select options changes
   */
  handleChange(key: string, newValue: boolean) {
    this.value[key] = newValue;
    this.valueChanged.emit(this.value);
    this.onChange(this.value);
    this.limitSelection(this.value);
  }

  /**
   * Limit the number of reasons able to be selected based on maxItemsAllowed
   * If no max is set, do not limit.
   */
  limitSelection(formValue: { [key: string]: boolean; }): void {
    if (this.maxItemsAllowed) {
      const selectionCount = Object.values(formValue).filter((selected) => selected).length;

      this.limitReached = selectionCount >= this.maxItemsAllowed;
    }
  }

  // below here are required control value accessor handlers and form control functions
  onChange = (_: { [key: string]: boolean; }): void => {};
  onTouched = (): void => {};

  writeValue(obj: { [key: string]: boolean; }): void {
    this.value = obj;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(_c: AbstractControl): ValidationErrors {
    const errors: ValidationErrors = {};
    if (this.required && !this.atLeastOneOptionSelected()) {
      errors.required = true;
    }

    return errors;
  }
}
