import {
  animate, group, query, style, transition, trigger
} from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import {
  Component, ElementRef, Inject, Optional, ViewChild, OnInit, AfterViewInit, Input, OnDestroy
} from '@angular/core';

import {
  combineLatest, EMPTY, fromEvent, Observable, of, Subscription
} from 'rxjs';
import {
  filter, map, tap
} from 'rxjs/operators';

import { GtmDataLayerService, GtmCustomEvents } from '@sondermind/google-tag-manager';
import {
  FlowResponse,
  ResponseUpdateItem,
  StepConfig,
  StepItem,
  StepItemConfig
} from '@sondermind/utilities/models-flows';

import { HOST_OVERLAY, WizardService } from '@sondermind/flows-host';
import { ResizeObserverDirective } from '@sondermind/utilities/directives';
import { MetatagService, ROBOTS_NO_INDEX } from '@sondermind/utilities/metatag';
import { COMMON } from '@sondermind/constants';
import { FlowsLaunchDarklyFeatureFlags, FlowsLaunchDarklyService, LAUNCH_DARKLY_SERVICE } from '@sondermind/launch-darkly';

const TIMING_IN = '450ms ease';
const TIMING_OUT = '250ms ease';

enum FlowDirection {
  BACK = 'back',
  FORWARD = 'forward'
}

@Component({
  selector: 'flows-intake-host',
  templateUrl: './flows-intake-host.component.html',
  styleUrls: ['./flows-intake-host.component.scss'],
  animations: [
    trigger('fade', [
      transition('* => true', [
        style({ background: 'rgba(255,255,255,0)' }),
        query('.flows-host-child', style({ opacity: 0 }), { optional: true }),

        animate(TIMING_IN, style({ background: 'rgba(255,255,255,1)' })),
        query('.flows-host-child', animate(TIMING_IN, style({ opacity: 1 })), { optional: true })
      ]),

      transition('* => false', [
        style({ background: 'rgba(255,255,255,1)' }),
        query('.flows-host-child', style({ opacity: 1 }), { optional: true }),

        query('.flows-host-child', animate(TIMING_OUT, style({ opacity: 0 })), { optional: true }),
        animate(TIMING_OUT, style({ background: 'rgba(255,255,255,0)' })),
      ]),
    ]),
    trigger('fadeHeader', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate(TIMING_IN, style({ opacity: 1 })),
      ]),
      transition('* => false', [
        style({ opacity: 1 }),
        // Extra delay necessary to reduce flicker on the header component while waiting to be destroyed when detaching the overlay
        animate('500ms', style({ opacity: 0 })),
      ])
    ]),
    trigger('changeStep', [
      transition(`* => ${FlowDirection.FORWARD}`, group([
        query(':enter', [
          style({
            transform: 'translateX(100%)',
            position: 'relative'
          }),
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
        ]),
        query(':leave', [
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)', style({
            transform: 'translateX(-100%)'
          }))
        ], { optional: true })
      ])),
      transition(`* => ${FlowDirection.BACK}`, group([
        query(':enter', [
          style({
            transform: 'translateX(-100%)',
            position: 'relative'
          }),
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
        ]),
        query(':leave', [
          animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)', style({
            transform: 'translateX(100%)'
          }))
        ])
      ]))
    ])
  ]
})
export class FlowsIntakeHostComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer')
    scrollContainerElement: ElementRef<HTMLElement>;
  @ViewChild('flowContainer')
    flowContainerElement: ElementRef<HTMLElement>;
  @ViewChild(ResizeObserverDirective)
    headerResizeObserver: ResizeObserverDirective;

  // optional presentation control settings
  @Input() isFooterVisible: boolean = true;
  @Input() isLogoClickable: boolean = true;
  @Input() isFullHeight: boolean = true;
  @Input() applyGradient: boolean = true;

  faded = false;
  data$: Observable<{
    config: StepConfig;
    response: FlowResponse;
    step: string;
    component: StepItem<StepItemConfig>;
  }>;

  showCallComponent$ = of(false);
  windowResize$: Observable<Event>;
  windowResizeSubscription: Subscription;
  dataProgressBarSubscription: Subscription;
  scroll$: Observable<number>;
  scrollSubscription: Subscription;
  busy: string;
  flowDirection: FlowDirection;
  hasProgressValue: boolean = true;
  progress: number = 0;

  callComponentSlugs = [
    'start',
    'location',
    'reason',
    'session-preference-selection',
    'desired-availability',
    'therapistGenderPref',
    'reasonDetail',
    'paymentPref',
    'insuranceSelect',
    'cost-estimate',
    'medicarePref',
    'eapSelect',
  ];

  constructor(
    public wizard: WizardService,
    @Inject(HOST_OVERLAY)
    @Optional()
    public overlayRef: OverlayRef,
    private gtmDataLayerService: GtmDataLayerService,
    private metatagService: MetatagService,
    @Inject(LAUNCH_DARKLY_SERVICE)
    private launchDarklyService: FlowsLaunchDarklyService,
  ) { }

  ngOnInit(): void {
    this.showCallComponent$ = this.launchDarklyService.ldFlag$.pipe(
      map((ldFlags) => ldFlags[FlowsLaunchDarklyFeatureFlags.CALL_COMPONENT] as boolean)
    );

    this.metatagService.addTag(ROBOTS_NO_INDEX);

    // fetch data together, into a bundle to avoid multiple subscriptions
    this.data$ = combineLatest([
      this.wizard.config$,
      this.wizard.step$,
      this.wizard.response$
    ]).pipe(
      filter((xs) => !xs.some((x) => x == null)),
      map(([config, step, response]) => ({
        config,
        response,
        step,
        component: this.wizard.component
      })),
    );

    this.windowResize$ = fromEvent(window, 'resize');
    this.windowResizeSubscription = this.windowResize$.subscribe(
      (evt) => this.setResumeElementPosition(0)
    );
  }

  ngAfterContentInit(): void {
    this.dataProgressBarSubscription = this.data$.subscribe(({ config, step }) => {
      const currentConfigSteps = config.steps.filter((x) => x.slug === step);

      if (!currentConfigSteps || currentConfigSteps.length !== 1 || !currentConfigSteps[0].progress) {
        this.hasProgressValue = false;
        return;
      }

      this.hasProgressValue = true;
      this.progress = currentConfigSteps[0].progress;
    });
  }

  /**
   * On step change events, push GTM dataLayer variables to trigger custom events for GA.
   *
   * Scroll to the top when the step changes
   *
   * @memberof FlowsWizardIntakeHostComponent
   */
  ngAfterViewInit(): void {
    this.wizard.setFlowContainer();
    this.wizard.step$
      .pipe(
        tap(() => {
          this.scrollContainerElement.nativeElement.scrollTop = 0;
        }),
        filter((step) => step !== null)
      )
      .subscribe((step) => {
        this.gtmDataLayerService.dataLayer = {
          event: GtmCustomEvents.MATCH_FLOW,
          category: GtmCustomEvents.MATCH_FLOW,
          action: step,
          label: this.wizard.response.slug,
          matchFlowSlug: this.wizard.response.slug,
          matchFlowStep: step,
          formWizardKey: this.wizard.response.key
        };
      });

    this.scroll$ = fromEvent(this.scrollContainerElement.nativeElement, 'scroll')
      .pipe(
        map(() => this.scrollContainerElement.nativeElement.scrollTop)
      );

    this.setResumeElementPosition(0);

    this.scrollSubscription = combineLatest(
      [
        this.wizard.flowContainerRendered$,
        this.scroll$
      ]
    ).subscribe(
      ([hasRendered, scrollAmount]) => {
        if (!hasRendered) return EMPTY;
        return this.setResumeElementPosition(scrollAmount);
      }
    );
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.unsubscribe();
    this.scrollSubscription.unsubscribe();
    this.dataProgressBarSubscription.unsubscribe();
  }

  setResumeElementPosition(scrollAmount: number): void {
    const resumeElement = document.getElementById('resume-component');
    if (resumeElement) {
      const topAmountMobile = 20;
      const topAmountDesktop = 28;
      const defaultTopAmount = resumeElement.classList.contains('mq-lt-md') ? topAmountMobile : topAmountDesktop;
      const topAmount = defaultTopAmount - scrollAmount;

      resumeElement.style.setProperty('top', `${topAmount}px`);
      resumeElement.style.setProperty('right', '24px');
    }
  }

  fadeComplete(): void {
    if (!this.wizard.running && this.overlayRef) {
      this.overlayRef.detach();
    }

    this.faded = true;
  }

  handleBack(): void {
    if (this.busy) { return }
    this.busy = 'back';
    this.wizard.back().subscribe(() => {
      this.busy = null;
      this.flowDirection = FlowDirection.BACK;
    });
  }

  backButtonClickedInFlow(): void {
    this.flowDirection = FlowDirection.BACK;
  }

  nextButtonClickedInFlow(items: ResponseUpdateItem[]): void {
    this.flowDirection = FlowDirection.FORWARD;
  }
}
