import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { StepComponent } from '../step.component';
import { WizardService } from '../../../services/wizard.service';

interface SelectFancyItem {
  label: string;
  value: string;
}

export interface OtherItem {
  name: string;
  label: string;
  placeholder: string;
  required: boolean;
}

export interface SelectFancyConfig extends StepItemConfig {
  name: string;
  options: SelectFancyItem[];
  other?: OtherItem;
  select: string;
}

@Component({
  selector: 'flows-select-fancy',
  templateUrl: './select-fancy.component.html',
  styleUrls: ['./select-fancy.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }
  ]
})
export class SelectFancyComponent extends StepComponent<SelectFancyConfig> implements OnInit {
  selections = {};
  missing = null;
  other = null;

  constructor(
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit() {
    // pre-deselect everything
    this.config.options.forEach((s) => {
      this.selections[s.value] = false;
    });

    // select known data
    if (this.response && this.response.data && this.response.data[this.config.name]) {
      this.response.data[this.config.name].forEach((s) => {
        this.selections[s] = true;
      });
    }

    // setup other
    this.other = this.config.other && this.response.data[this.config.other.name];
    // eslint-disable-next-line no-mixed-operators
    this.missing = new Array(4 - Object.keys(this.selections).length % 4);

    this.valid.next(this.validate());
  }

  handleSelectionChange(change: SelectFancyItem) {
    // unselect the others when it's a single select
    if (this.config.select !== 'multi') {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.selections) {
        if (key === change.value) {
          // eslint-disable-next-line no-continue
          continue;
        }

        this.selections[key] = false;
      }
    }

    this.handleChange();
  }

  handleOtherChange() {
    this.handleChange();
  }

  handleChange() {
    this.valid.next(this.validate());
  }

  validate() {
    const keys = Object.keys(this.selections);
    const values = keys.filter((k) => this.selections[k]);

    if (values.length === 0) {
      return false;
    }

    if (this.config.other && this.config.other.required) {
      if (this.other == null || this.other === '') {
        return false;
      }
    }

    return true;
  }

  onSubmit() {
    const values: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.selections) {
      if (this.selections[key] === true) {
        values.push(key);
      }
    }

    const updates: ResponseUpdateItem[] = [];
    updates.push({ human: this.config.name, key: this.config.name, value: values });
    if (this.config.other) {
      updates.push({ human: this.config.other.label, key: this.config.other.name, value: this.other });
    }

    return updates;
  }
}
