<label class="sonder-form-field" [ngClass]="classToggles" sonderMediaqueryClass="mq">
  <div class="sonder-form-field-label" data-test="form-field-label">
    <div *ngIf="label">
      <span
        class="sonder-form-field-label-text"
        [ngClass]="{'iris-h4-responsive': openTextClass}"
        [attr.data-test]="'input-field-' + label"
      >
        {{ label }}
      </span>
      <span class="sonder-form-field-label-required" *ngIf="required">*</span>
    </div>
    <div *ngIf="showPending" [@slideUp]="'in'" class="sonder-form-field-pending">
      <mat-spinner color="primary" [diameter]="14" [strokeWidth]="2"></mat-spinner>
    </div>
  </div>
  <p
    *ngIf="!!description"
    [ngClass]="{'open-text-description': openTextClass}"
    data-test="paragraph-description-text"
  >
    {{ description }}
  </p>
  <p *ngIf="!!helperText" class="location-helper-text">{{ helperText }}</p>
  <div
    class="sonder-form-content"
    [ngClass]="contentClassToggles"
    data-test="input-field"
  >
    <ng-container *ngIf="standin">{{ standin }}</ng-container>
    <mat-icon *ngIf="iconType && icon" class="sonder-form-field-type-icon">{{ icon }}</mat-icon>
    <ng-content select="input,select,mat-select" *ngIf="!standin"></ng-content>
    <ng-content></ng-content>
    <div *ngIf="!(breakpoint.isMobile$ | async)" class="sonder-form-field-after">
      <ng-container *ngTemplateOutlet="afterContent"></ng-container>
    </div>
  </div>
  <div class="sonder-form-helptext sonder-form-field-helptext">
    <ng-content select="footer"></ng-content>
  </div>
  <div>
    <ul class="sonder-form-errors bottom-error-messages" data-test="sonder-form-errors">
      <li *ngFor="let error of errorMessages">{{ error }}</li>
    </ul>
  </div>
  <div *ngIf="breakpoint.isMobile$ | async" class="sonder-form-field-after">
    <ng-container *ngTemplateOutlet="afterContent"></ng-container>
  </div>
</label>
<ng-template #afterContent>
  <ng-content select="aside"></ng-content>
</ng-template>
