<div *ngIf="reviewItems.length > 0" class="user-input-review-container review-items">
  <ng-container *ngFor="let item of reviewItems">
    <h3 class="review-item-header">{{ item.header }}</h3>
    <p class="review-item-value">{{ item.value | phoneNumber }}</p>
  </ng-container>
  <a class="review-item-edit" (click)="goBack()">Edit</a>
</div>
<hr class="user-input-review-hr">
<div class="user-input-review-container review-text">
  <img
    *ngIf="imageUrl"
    class="user-input-review-image"
    [src]="imageUrl"
    alt="confirmation twinkle icon">
  <p class="user-input-review-image-helper-text">{{ config.imageHelperText }}</p>
</div>
