import { Component, OnInit } from '@angular/core';

import { StepItemConfig } from '@sondermind/utilities/models-flows';
import { WizardService } from '../../../services/wizard.service';
import { StepComponent } from '../step.component';

export interface DateTimeItem {
  day: string;
  time: string;
}

export interface DayTimesConfig extends StepItemConfig {
  availabilityName: string;
  nonAvailabilityName: string;
  maxItemsAllowed: number;
  minItemsRequired: number;
  defaultNumberOfItems: number;
}

const DAYS = [
  'All Days', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays', 'Sundays'
];

const TIMES = [
  'Not available on this day', 'Early Morning (7:00-9:00)',
  'Morning (9:00-12:00)', 'Afternoon (12:00-3:00)',
  'Late Afternoon (3:00-6:00)', 'Evening (6:00-9:00)'
];

@Component({
  selector: 'flows-select-day-times',
  templateUrl: './select-day-times.component.html',
  styleUrls: ['./select-day-times.component.scss'],
})
export class SelectDayTimesComponent extends StepComponent<DayTimesConfig> implements OnInit {
  items: DateTimeItem[];
  maxItemsAllowed: number;
  minItemsRequired: number;
  defaultNumberOfItems: number;
  availableAnyTime: boolean;

  constructor(
    wizard: WizardService,
  ) {
    super(wizard);
  }

  get dayOptions(): string[] { return DAYS }
  get timeOptions(): string[] { return TIMES }

  ngOnInit() {
    this.maxItemsAllowed = this.config.maxItemsAllowed || 3;
    this.minItemsRequired = this.config.minItemsRequired || 3;
    this.defaultNumberOfItems = this.config.defaultNumberOfItems || 3;

    this.availableAnyTime = this.data[this.config.availabilityName] || false;
    this.items = this.data[this.config.nonAvailabilityName] || [];

    // Add pending items
    const itemsNeeded = this.defaultNumberOfItems - this.items.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= itemsNeeded; i++) {
      this.items.push({ day: null, time: null });
    }

    this.handleChange();
  }

  addItem() {
    if (!this.allItemsAdded()) {
      this.items.push({ day: null, time: null });
      this.handleChange();
    }
  }

  removeItem(i: number) {
    this.items.splice(i, 1);
    this.handleChange();
  }

  moreItemsAllowed(): boolean {
    return this.maxItemsAllowed > this.defaultNumberOfItems;
  }

  itemEmpty(item: DateTimeItem): boolean {
    return item.day === null && item.time === null;
  }

  itemValid(item: DateTimeItem): boolean {
    return item.day !== null && item.time !== null;
  }

  allItemsAdded(): boolean {
    return this.items.length >= this.maxItemsAllowed;
  }

  canRemove(i: number): boolean {
    return i > (this.defaultNumberOfItems - 1);
  }

  disableOption(day: string, time: string): boolean {
    return (day && day.includes('All')) && (time && time.includes('Not available'));
  }

  requiredItemsValid(): boolean {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.minItemsRequired; i++) {
      const item = this.items[i];
      if (!!item && !this.itemValid(item)) {
        return false;
      }
    }

    return true;
  }

  validate() {
    if (this.availableAnyTime) {
      return true;
    // eslint-disable-next-line no-else-return
    } else if (this.requiredItemsValid()) {
      if (this.items.every((i) => this.itemValid(i) || this.itemEmpty(i))) {
        return true;
      }
    }

    return false;
  }

  handleChange() {
    this.valid.next(this.validate());
  }

  onSubmit() {
    return [
      { human: 'Available Any Time?', key: this.config.availabilityName, value: this.availableAnyTime },
      { human: 'Non Availability Preferences', key: this.config.nonAvailabilityName, value: this.items }
    ];
  }
}
