<form class="formContainer" sonderMediaqueryClass="mq">

  <div class="multi-select-images-wrapper">
    <ng-container>
      <div [ngClass]="!(isMobile$ | async) ? 'multi-select-images-wrapper-desktop' : 'multi-select-images-wrapper-mobile'">
        <sonder-option-checkbox
          *ngFor="let option of imageItems"
          [ngClass]="{ 'multi-select-images-without-img': !option.src }"
        >
          <mat-checkbox
            matInput
            color="primary"
            [(ngModel)]="option.selectValue"
            [name]="option.value"
            (ngModelChange)="handleSelectionChange(option)"
          >
          </mat-checkbox>
          <img
            *ngIf="option.src"
            class="multi-select-images-img"
            src="{{ option.src }}"
            title="{{ option.label }}">
          <span *ngIf="!option.src" class="multi-select-images-without-img-label">
            {{ option.label }}
          </span>
        </sonder-option-checkbox>
      </div>
    </ng-container>
  </div>
