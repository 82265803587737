<div
  sondermindMediaQueryAttribute
  class="progress-bar-container"
>
  <mat-progress-bar
    [ngClass]="{'progress-complete': progressBarValue === 100}"
    [value]="progressBarValue"
    mode="determinate"
  ></mat-progress-bar>
  <div>
    <iris-icon
      sondermindMediaQueryAttribute
      *ngIf="progressBarValue === 100"
      class="check-circle"
      name="check_circle"
    ></iris-icon>
  </div>
</div>
