<ng-container
  [templateStore]="{
    statusIndex: (progressStatusIndex$ | async),
    didYouKnowIndex: (didYouKnowIndex$ | async)
  }"
  #tStore="templateStore"
>
  <div class="matching-progress-container">

    <div class="dls-svg-container" [style.max-height.px]="svgMaxContainerHeight">
      <svg viewBox="0 0 287 311" preserveAspectRatio="xMidYMid meet">
        <circle cx="50.5" cy="107.5" r="50.5" fill="#EEC331"/>
        <g class="ellipse">
          <ellipse cx="125.5" cy="203.5" rx="32.5" ry="50.5" transform="rotate(-48.033 125.5 203.5)" fill="#BA8ECC"/>
        </g>
        <g class="semi-circle">
          <path d="M174.476 16.6565C190.963 21.0741 205.019 31.8601 213.554 46.6416C222.088 61.4232 224.4 78.9895 219.983 95.4762C215.565 111.963 204.779 126.019 189.998 134.554C175.216 143.088 157.65 145.4 141.163 140.983L157.82 78.8196L174.476 16.6565Z" fill="#326EB1"/>
        </g>
      </svg>
    </div>

    <div class="progress-status-row">
      <div
        class="progress-status-content"
      >
        <ng-container *ngFor="let item of progressStatusItems; index as i">
          <div [@progress]="{ value: tStore.statusIndex === i, params: { animationDuration: '1s'} }" class="progress-status">
            <div [ngClass]="progressStatusHeaderClass">{{item.heading}}</div>
            <div *ngIf="item.sub" [ngClass]="progressStatusSubHeaderClass">
              {{ item.sub }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="showDidYouKnow"
      class="did-you-know-row"
    >
      <div
        [@did-you-know-container]
        *ngIf="tStore.didYouKnowIndex >= 0"
      >
        <div
          [@did-you-know]="{ value: tStore.didYouKnowIndex, params: { animationDuration: '1s'} }"
          class="did-you-know-container"
        >
          <ng-container *ngFor="let blurb of didYouKnowBlurbs; index as i">
            <ng-container *ngIf="tStore.didYouKnowIndex == i">
              <div class="did-you-know">
                <div class="did-you-know-content">
                  <mat-icon>info_outlined</mat-icon>
                  <h4 class="heading">Did you know?</h4>
                  <p class="blurb">{{ blurb }}</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
