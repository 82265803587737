import {
  Component, OnInit, ViewChild, AfterViewInit
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
// eslint-disable-next-line import/no-extraneous-dependencies
import { conformToMask } from 'text-mask-core';

import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { StepComponent, PHONE_FIELD_OPTS } from '../step.component';
import { WizardService } from '../../../services/wizard.service';

export interface SMSOptinConfig extends StepItemConfig {
  mainNumberName: string;
  smsNumberName: string;
  smsOptinName: string;
  optin: boolean;
}

@Component({
  selector: 'flows-sms-optin',
  templateUrl: './sms-optin.component.html',
  styleUrls: ['./sms-optin.component.scss']
})
export class SMSOptinComponent extends StepComponent<SMSOptinConfig> implements OnInit, AfterViewInit {
  @ViewChild('form') form: NgForm;

  mobileNumber: string;
  mobileOptin: boolean;

  constructor(
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit() {
    this.mobileOptin = this.data[this.config.smsOptinName] || this.config.optin;
    this.mobileNumber = this.data[this.config.smsNumberName] || this.data[this.config.mainNumberName];

    if (this.mobileNumber) {
      const res = conformToMask(this.mobileNumber, this.phoneFieldOptions.mask, this.phoneFieldOptions);
      this.mobileNumber = res.conformedValue;
    }
  }

  ngAfterViewInit() {
    this.form.form.setValidators((c) => this.validate(c.value.number, c.value.optin));

    this.form.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.valid.next(this.form.valid));

    // validate initially
    this.valid.next(this.validate(this.mobileNumber, this.mobileOptin) === null);
  }

  get phoneFieldOptions() {
    return PHONE_FIELD_OPTS;
  }

  validate(number: string, optin: boolean) {
    if (
      optin === true
      && (number == null || number.length === 0 || !this.phoneFieldOptions.pattern.exec(number))
    ) {
      return { invalid: true };
    }

    return null;
  }

  onSubmit(): ResponseUpdateItem[] {
    const number = this.mobileOptin && this.mobileNumber ? this.mobileNumber.replace(/[^0-9]/g, '') : null;

    return [
      { human: 'SMS Phone', key: this.config.smsNumberName, value: number },
      { human: 'SMS Optin', key: this.config.smsOptinName, value: this.mobileOptin },
    ];
  }
}
