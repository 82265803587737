import {
  Component, EventEmitter, Input, Output, HostListener
} from '@angular/core';
import { BrowserButtonEvent } from '@sondermind/utilities/browser-nav-buttons';
import { IrisButtonTypes, IrisButtonContainerAlignments } from '@sondermindorg/iris-design-system-angular';

export interface StepButtonsConfig {
  back: boolean;
  exit: boolean;
  done: boolean;
}

@Component({
  selector: 'flows-intake-step-buttons',
  templateUrl: './intake-step-buttons.component.html',
  styleUrls: ['./intake-step-buttons.component.scss']
})
export class IntakeStepButtonsComponent {
  resetArmed = false;
  irisButtonTypes = IrisButtonTypes;
  irisButtonContainerAlignments = IrisButtonContainerAlignments;

  @Input() config: StepButtonsConfig;

  @Input() back: boolean;
  @Input() exit: boolean;
  @Input() exitAndSave: boolean;
  @Input() done: string;
  @Input() disabled: boolean;
  @Input() reset: boolean;
  @Input() busy?: string;
  @Input() stepName: string;
  @Input() nextButtonName?: string;
  @Input() dialNumber?: string;
  @Input() showNextButton: boolean;
  @Input() confirmButtonGtmClass?: string;
  @Input() showBackButton: boolean;
  @Input() backButtonName: string;

  @Output() backClicked = new EventEmitter<void>();
  @Output() exitClicked = new EventEmitter<void>();
  @Output() exitAndSaveClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();
  @Output() resetClicked = new EventEmitter<void>();
  handleReset() {
    if (this.resetArmed) {
      this.resetArmed = false;
      this.resetClicked.emit();
    } else {
      this.resetArmed = true;
    }
  }

  disarm() {
    this.resetArmed = false;
  }

  @HostListener(`window:${BrowserButtonEvent.BACK}`)
  onBrowserBack(): void {
    this.backClicked.emit();
  }

  @HostListener(`window:${BrowserButtonEvent.FORWARD}`)
  onBrowserForward(): void {
    this.nextClicked.emit();
  }
}
