<aside class="nps-score-labels" fxHide.gt-xs>
    <span>Unlikely</span>
</aside>

<ul class="nps-score-container" sonderMediaqueryClass="mq">
    <li *ngFor="let option of options">
        <label>
            <input type="radio" [name]="name" [(ngModel)]="value" [value]="option" (ngModelChange)="handleChange()" (blur)="onTouched()" />
            <span>{{ option }}</span>
        </label>
    </li>
</ul>

<aside class="nps-score-labels">
    <span fxHide.lt-sm>Unlikely</span>
    <span class="spacer"></span>
    <span>Likely</span>
</aside>
