import { NativeAppContextHelpers } from '@sondermind/native-app-interface';
import { WizardService } from '../../services/wizard.service';

export function haveNativeIntakeParam(): boolean {
  const searchParams = new URLSearchParams(window.document.location.search);
  return searchParams.has('native_intake');
}

// not exporting since it's only used locally
function haveTBEParam(): boolean {
  const searchParams = new URLSearchParams(window.document.location.search);
  return searchParams.has('tbe');
}

/**
 * The following are the shared expected string values between the flow and TB client app.
 */
export enum TBMessages {
  FLOW_TERMINATION_1 = 'tbe:mf_termination_1', // successful completion
  FLOW_TERMINATION_2 = 'tbe:mf_termination_2', // email exists
  FLOW_TERMINATION_3 = 'tbe:mf_termination_3', // services not available (Geo)
  FLOW_TERMINATION_4 = 'tbe:mf_termination_4', // services not available (in-person Geo)
}

export function signalFlowTermination(wizard: WizardService): void {
  let message = '';
  if (wizard.component) {
    // eslint-disable-next-line default-case
    switch (wizard.component.slug) {
      case 'complete':
        message = TBMessages.FLOW_TERMINATION_1;
        break;
      case 'complete-pending':
        message = TBMessages.FLOW_TERMINATION_2;
        break;
      case 'location-check':
        message = TBMessages.FLOW_TERMINATION_3;
        break;
      case 'locationCheckFollowUp':
        message = TBMessages.FLOW_TERMINATION_4;
        break;
    }
  }

  const isTBOrNative = haveTBEParam() || haveNativeIntakeParam();

  // if message is blank, then the "step" isn't one for which we want to signal
  const shouldNotify = message !== '' && wizard.shouldSignalCompletion && isTBOrNative;
  if (shouldNotify) {
    /**
     * for more details
     * https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#postmessagestr
     */
    interface IReactNativeWebView {
      postMessage: (message: string) => void;
    }
    const reactNativeWebView = window['ReactNativeWebView'] as IReactNativeWebView;
    if (reactNativeWebView?.postMessage) {
      reactNativeWebView.postMessage(message);
      return;
    }

    if (NativeAppContextHelpers.isHostedInNativeApp()) {
      NativeAppContextHelpers.onMatchingFlowTermination(message);
      return;
    }

    // iframe?
    if (window.parent !== window) {
      // NB: using targetOrigin of '*' for the initial implementation
      // TODO: replace with environment-specific value via ConfigurationService in the near future
      //  once we know what that value should be for a given environment
      window.parent.postMessage(message, '*');
    }
  }
}
