<form
  [formGroup]="selectFormGroup"
>
  <iris-form-field>
    <iris-dropdown
      data-test="intake-dropdown"
      [options]="$any(options)"
      placeholder="How you heard about us"
      formControlName="singleSelect"
    >
    </iris-dropdown>
  </iris-form-field>

  <!-- If they selected other, show the textarea -->
  <div
    *ngIf="shouldShowFreeText"
    class="free-text-container"
  >
    <p
      class="free-text-heading"
      data-test="referral-source-free-text-heading"
      >{{ freeTextHeading }}</p>
    <iris-text-area
      formControlName="freeText"
      [size]="textareaSizes.SM"
      data-test="referral-source-free-text"
    >
    </iris-text-area>
  </div>
</form>
