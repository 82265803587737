<form class="decline-theme formContainer" fxLayout="column" fxLayoutGap="24px" sonderMediaqueryClass="mq">
  <mat-checkbox [(ngModel)]="availableAnyTime" [ngModelOptions]="{ standalone: true }" (change)="handleChange()">
    My schedule is completely flexible and I am available anytime.
  </mat-checkbox>

  <ng-container *ngIf="!availableAnyTime">
    I am NOT available:
    <div *ngFor="let item of items; index as i; first as isFirst" class="formContainer-row" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="28px" fxLayoutGap.lt-md="12px">
      
      <sonder-form-field class="formContainer-selector" [label]="false" [required]="true" fxFlex="1 1 auto">
        <mat-select [(value)]="item.day" required (selectionChange)="handleChange()" placeholder="Select a Day">
          <mat-option [value]="null">Select a Day</mat-option>
          <mat-option *ngFor="let day of dayOptions" [value]="day" [disabled]="disableOption(day, item.time)">{{ day }}</mat-option>
        </mat-select>
      </sonder-form-field>
      <sonder-form-field class="formContainer-selector" [label]="false" [required]="true" fxFlex="1 1 auto">
        <mat-select [(value)]="item.time" required (selectionChange)="handleChange()" placeholder="Select a Time">
          <mat-option [value]="null">Select a Time</mat-option>
          <mat-option *ngFor="let time of timeOptions" [value]="time" [disabled]="disableOption(item.day, time)">{{ time }}</mat-option>
        </mat-select>
        <aside *ngIf="canRemove(i)">
          <a mat-icon-button [disableRipple]="true" (click)="removeItem(i)">
            <mat-icon>highlight_off</mat-icon>
          </a>
        </aside>
      </sonder-form-field>
    </div>
  
    <button mat-button class="formContainer-addAnother" color="accent" *ngIf="moreItemsAllowed()" (click)="addItem()" [disabled]="allItemsAdded()">Add Another</button>
  </ng-container>
</form>
