<form class="decline-theme formContainer" sonderMediaqueryClass="mq">
  <sonder-form-radio-group class="mat-radio-images">
    <mat-radio-group
      matInput [(ngModel)]="selection"
      [ngModelOptions]="{standalone:true}"
      [required]="true"
      (ngModelChange)="handleChange()"
      fxLayout="column wrap"
      fxLayout.gt-sm="row wrap"
      fxLayoutGap="32px"
    >
      <mat-radio-button
        *ngFor="let option of imageItems"
        color="primary"
        [value]="option.value"
        [class.selected]="option.value === selection"
        [fxFlex.gt-sm]="flex(option)"
        fxFlex.lt-md="0 100%"
      >
        <span class="img-as-bg without-img {{option.value}}">{{ option.label }}</span>
      </mat-radio-button>
    </mat-radio-group>
  </sonder-form-radio-group>
