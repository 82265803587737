import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAPIFetchResponse } from '@sondermind/utilities/models-http';

import { IFlowsEndpoint } from '@sondermind/utilities/models-flows';
import { Flow } from '../../models/flow.interface';

@Injectable({
  providedIn: 'root'
})
export class FlowApiClient {
  endpoint: IFlowsEndpoint;

  constructor(
    private http: HttpClient,
  ) { }

  setEndpoint(endpoint: IFlowsEndpoint): void {
    this.endpoint = endpoint;
  }

  fetch(slug: string, hash: string = null, shouldLoadStrict = false): Observable<Flow> {
    let queryParams = '';
    if (hash) {
      queryParams = `?version_hash=${hash}`;

      if (shouldLoadStrict) {
        queryParams += '&strict=yes';
      }
    }

    return this.http
      .get<IAPIFetchResponse<Flow>>(`${this.endpoint.flows}/${slug}${queryParams}`)
      .pipe(
        map((res) => res.data)
      );
  }
}
