<div class="buttonContainer">
  <iris-button-container [alignment]="irisButtonContainerAlignments.END">
    <iris-button
      *ngIf="back && !resetArmed"
      [ngClass]="['backButton', 'back-from-' + stepName]"
      (click)="backClicked.emit()"
      [disabled]="!!busy"
      [type]="irisButtonTypes.SECONDARY"
      data-test="back-button"
    >
      Back
    </iris-button>

    <iris-button
      *ngIf="exit"
      [ngClass]="['exitButton', 'exit-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="exitClicked.emit()"
    >
      Exit
    </iris-button>
    <iris-button
      *ngIf="exitAndSave"
      [ngClass]="['exitButton', 'exit-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="exitAndSaveClicked.emit()"
    >
      {{ nextButtonName || "Exit" }}
    </iris-button>
    <iris-button
      *ngIf="showNextButton && !done"
      [ngClass]="['nextButton', 'next-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="nextClicked.emit()"
      data-test="next-submit-button"
    >
      {{ nextButtonName || "Next" }}
    </iris-button>
    <iris-button
      *ngIf="dialNumber"
      [ngClass]="['callButton', 'call-from-' + stepName]"
      [fxHide]="resetArmed"
      linkHref="tel:{{ dialNumber }}"
    >
      Call
    </iris-button>
    <iris-button
      *ngIf="done"
      [ngClass]="['completeButton', 'complete-from-' + stepName, confirmButtonGtmClass ?? '']"
      [fxHide]="resetArmed"
      [disabled]="!!busy || disabled"
      (click)="nextClicked.emit()"
    >
      {{ done }}
    </iris-button>
  </iris-button-container>
</div>

<div class="buttonContainer resetContainer">
  <iris-button
    *ngIf="!busy && resetArmed"
    [ngClass]="['cancelButton', 'cancel-from-' + stepName]"
    [type]="irisButtonTypes.TERTIARY"
    (click)="disarm()"
  >
    Cancel
  </iris-button>
</div>
