<div
  class="were-here-to-help"
>
  <div class="display-text">
    <h3>We're here to help</h3>
    <p>Give us a call and one of our Care Coordinators will help you find a therapist over the phone.</p>
  </div>

  <div class="call-button">
      <iris-button
        [type]="IrisButtonTypes.SECONDARY"
      >
        <div class="iris-button-content">
          <mat-icon>phone</mat-icon>
          <a class="call-button-link" href="tel:{{ HELP_NUMBER }}">
            Call {{ HELP_NUMBER | conformToMask:$any(masks.phoneMask.mask) }}
          </a>
        </div>
      </iris-button>
  </div>
</div>
