import { Injectable, OnDestroy } from '@angular/core';
import { BreakPointRegistry, BreakPoint } from '@angular/flex-layout';
import {
  map, startWith, shareReplay, takeUntil, share
} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService implements OnDestroy {
  suffixes$: Observable<string[]>;
  isMobile$: Observable<boolean>;
  isMobileOrTablet$: Observable<boolean>;
  private mobileBreakpoint;
  private mobileOrTabletBreakpoint;

  private destroyed = new Subject<void>();

  get isMobile() {
    return this.breakpointObserver.isMatched(this.mobileBreakpoint);
  }

  get isMobileOrTablet() {
    return this.breakpointObserver.isMatched(this.mobileOrTabletBreakpoint);
  }

  // todo: update when flex-layout#426 is fixed
  // https://github.com/angular/flex-layout/issues/426
  // observableMedia.asObservable() doesn't always fire when initialized, meaning
  // we have to wait for a resize to occur to reliably get the currently active
  // mediaquery. rather, find the active breakpoints by iterating through the registry
  // and call `startWith` on the observable to force something to happen on load

  constructor(
    private breakpointObserver: BreakpointObserver,
    private breakpoints: BreakPointRegistry,
  ) {
    this.mobileBreakpoint = this.breakpoints.findByAlias('lt-md').mediaQuery;
    const allBreakpoints = this.breakpoints.items.map((bp) => bp.mediaQuery);
    this.mobileOrTabletBreakpoint = this.breakpoints.findByAlias('lt-lg').mediaQuery;

    const activeBreakpoints$ = this.breakpointObserver.observe(allBreakpoints).pipe(
      map((_) => this.currentlyActiveBreakpoints()),
      startWith(this.currentlyActiveBreakpoints()),
      takeUntil(this.destroyed.asObservable()),
      share(),
    );

    this.suffixes$ = activeBreakpoints$.pipe(
      map((bps) => bps.map((b) => b.alias)),
      startWith(this.currentlyActive()),
      shareReplay(1),
    );

    this.isMobile$ = activeBreakpoints$.pipe(
      map((_) => this.breakpointObserver.isMatched(this.mobileBreakpoint)),
      startWith(this.breakpointObserver.isMatched(this.mobileBreakpoint)),
      shareReplay(1),
    );

    this.isMobileOrTablet$ = activeBreakpoints$.pipe(
      map((_) => this.breakpointObserver.isMatched(this.mobileOrTabletBreakpoint)),
      startWith(this.breakpointObserver.isMatched(this.mobileOrTabletBreakpoint)),
      shareReplay(1),
    );
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  currentlyActive(): string[] {
    return this.currentlyActiveBreakpoints().map((b) => b.alias);
  }

  private currentlyActiveBreakpoints(): BreakPoint[] {
    return this.breakpoints.items
      .filter((b) => this.breakpointObserver.isMatched(b.mediaQuery));
  }
}
