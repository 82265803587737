<div class="decline-theme formContainer sondermind-design-system" fxLayout="column" fxLayoutGap="48px" fxLayoutAlign="center">
  <h1
    [innerHTML]="heroText | bindTemplate:response.data"
    data-test="flows-complete-header"
    >
  </h1>

  <div class="helpTextContainer" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
		<h2
      *ngFor="let text of helpTexts"
      [innerHTML]="text | bindTemplate:response.data"
      class='helpText'
      data-test="flows-complete-help-text"
      >
    </h2>
	</div>

  <div *ngIf="config.images" class="imagesContainer" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <div *ngFor="let image of config.images" fxLayout="column">
      <div class="-before-text" [innerHTML]="image.beforeText | bindTemplate:response.data"></div>
      <div class="-image-container">
        <img [fxFlex]="flex(image)" src="{{image.url}}">
      </div>
      <div
        class="-after-text"
        [innerHTML]="image.afterText | bindTemplate:response.data"
        data-test="flows-complete-after-text"
        >
      </div>
    </div>
  </div>

  <iris-button
    (click)="handleExit()"
    [type]="isClinicalQuestionnaire ? irisButtonTypes.PRIMARY : irisButtonTypes.SECONDARY"
    data-test="flows-complete-exit-button"
  >
    {{ component.done }}
  </iris-button>

  <div
    class="-info-text-container"
    *ngIf="config.infoText"
    [innerHTML]="config.infoText | bindTemplate:response.data"
    data-test="flows-complete-info-text"
    >
  </div>
</div>
