import { Component, Input } from '@angular/core';

@Component({
  selector: 'flows-intake-footer',
  templateUrl: './intake-footer.component.html',
  styleUrls: ['./intake-footer.component.scss']
})
export class IntakeFooterComponent {
  @Input() footer: string;
}
