<div
  #scrollContainer
  sondermindMediaQueryAttribute
  class="flows-host-backdrop"
  [ngClass]="{
    'position-fixed': isFullHeight,
    'gradient': (applyGradient && (data$ | async)?.step !== 'splash-screen'),
    'splash-screen-background': (data$ | async)?.step === 'splash-screen'
  }"
  [@fade]="wizard.running$ | async"
  (@fade.done)="fadeComplete()"
>
  <div class="flow-spinner" *ngIf="wizard.started !== true">
    <div class="flow-spinner-container">
      <mat-spinner
        color="primary"
        diameter="88"
        strokeWidth="6"
        aria-label="Loading Match Flow"
      ></mat-spinner>
    </div>
  </div>
  <div #flowContainer>
    <div
      *ngIf="data$ | async as data"
      class="flows-host-container mat-typography"
      [ngClass]="{'full-height': isFullHeight}"
      sonderMediaqueryClass="mq"
    >
      <flows-intake-header
        [@fadeHeader]="wizard.running$ | async"
        sondermindResizeObserver
        (backClicked)="handleBack()"
        [back]="wizard.canBack()"
        [component]="data.component"
        [isLogoClickable]="isLogoClickable"
      ></flows-intake-header>
      <flows-intake-host-progress-bar
        *ngIf="hasProgressValue"
        sondermindMediaQueryAttribute
        class="progress-bar"
        [progressBarValue]="progress"
      >
      </flows-intake-host-progress-bar>

      <flows-intake-splash-screen
        class="splash-screen-component"
        *ngIf="data.step === 'splash-screen'"
        (stepNext)="nextButtonClickedInFlow($event)"
      >
      </flows-intake-splash-screen>

      <div
        *ngIf="data.step !== 'splash-screen'"
        class="flows-animation-container"
        [@changeStep]="{
          value: flowDirection,
          params: {animationDuration: '500ms'}
        }"
        (@changeStep.done)="flowDirection = null"
      >
        <ng-container *ngFor="let component of data.config.steps">
          <div
            *ngIf="component.slug === data.step"
            class="flost-host-child-container"
          >
            <div
              id="flows-host-child"
              class="flows-host-child flows-host-wizard"
            >
              <flows-intake-step-host
                [ngClass]="{ 'flows-intake-step-host': (showCallComponent$ | async)}"
                [component]="component"
                [response]="data.response"
                [resetable]="wizard.resetable"
                (stepNext)="nextButtonClickedInFlow($event)"
                (stepBack)="backButtonClickedInFlow()"
                (stepExit)="wizard.exit()"
                (stepReset)="wizard.reset()"
                cdkTrapFocus
                [busy]="busy"
                data-test="pause-page-text"
              ></flows-intake-step-host>
              <flows-intake-call
                *ngIf="(showCallComponent$ | async) && callComponentSlugs.includes(component.slug)"
              >
              </flows-intake-call>
            </div>
          </div>
        </ng-container>
      </div>

      <flows-intake-footer
        *ngIf="isFooterVisible && !!data.component.config.footer"
        id="flows-intake-footer"
        sondermindMediaQueryAttribute
        [footer]="data.component.config.footer"
      ></flows-intake-footer>
    </div>
  </div>
</div>
