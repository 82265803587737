export interface IApiReferenceDatum {
  id: number;
  type: string;
  title: string;
  description: string;
  tag: string;
  hidden: boolean;
  /* eslint-disable @typescript-eslint/naming-convention */
  default_selected: boolean;
  external_title: string;
  external_tag: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export class ReferenceDatum {
  id: number;
  type: string;
  title: string;
  description: string;
  tag: string;
  hidden: boolean;
  defaultSelected: boolean;
  externalTitle: string;
  externalTag: string;

  static fromJson(json: IApiReferenceDatum): ReferenceDatum {
    return new ReferenceDatum().loadFromJson(json);
  }

  static fromPartial(json: Partial<IApiReferenceDatum>): ReferenceDatum {
    const datum = new ReferenceDatum();

    if (json.id) datum.id = json.id;
    if (json.type) datum.type = json.type;
    if (json.title) datum.title = json.title;
    if (json.description) datum.description = json.description;
    if (json.tag) datum.tag = json.tag;
    if (json.hidden) datum.hidden = json.hidden;
    if (json.default_selected) datum.defaultSelected = json.default_selected;
    if (json.external_title) datum.externalTitle = json.external_title;
    if (json.external_tag) datum.externalTag = json.external_tag;

    return datum;
  }

  loadFromJson(json: IApiReferenceDatum): ReferenceDatum {
    this.id = json.id;
    this.type = json.type;
    this.title = json.title;
    this.description = json.description;
    this.tag = json.tag;
    this.hidden = json.hidden;
    this.defaultSelected = json.default_selected;
    this.externalTitle = json.external_title;
    this.externalTag = json.external_tag;

    return this;
  }
}
