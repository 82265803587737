import { Component, OnInit } from '@angular/core';

import { ConfigurationService } from '@sondermind/configuration';
import { StepItemConfig } from '@sondermind/utilities/models-flows';
import { StepComponent } from '../step.component';
import { WizardService } from '../../../services/wizard.service';

export interface UserInputReviewConfig extends StepItemConfig {
  reviewItems: Array<{
    header: string;
    dataKey: string;
  }>;
  imageHelperText: string;
}

@Component({
  selector: 'flows-user-input-review',
  templateUrl: './user-input-review.component.html',
  styleUrls: ['./user-input-review.component.scss']
})
export class UserInputReviewComponent extends StepComponent<UserInputReviewConfig> implements OnInit {
  reviewItems: Array<{ header: string; value: string | number; }> = [];
  imageUrl: string;

  constructor(
    private configurationService: ConfigurationService,
    wizard: WizardService
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.valid.next(!!this.component.next);
    this.optionsInit();
    this.imageInit();
  }

  onSubmit(): any[] {
    return [];
  }

  optionsInit(): void {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!this.config.reviewItems) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.config.reviewItems) {
        if (item.dataKey && item.header && this.data[item.dataKey]) {
          this.reviewItems.push({
            value: this.data[item.dataKey],
            header: item.header
          });
        }
      }
    }
  }

  imageInit(): void {
    this.imageUrl = `${this.configurationService.env.urlBase}/assets/sm_twinkle.svg`;
  }

  goBack(): void {
    this.wizard.back().subscribe();
  }
}
