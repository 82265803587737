import { Component, OnInit, OnDestroy } from '@angular/core';

import { ConfigurationService, IFlowsAppConfig } from '@sondermind/configuration';
import { WizardService, signalFlowTermination } from '@sondermind/flows-host';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';
import { IrisButtonTypes } from '@sondermindorg/iris-design-system-angular';
import { IntakeStepComponent } from '../intake-step.component';

@Component({
  selector: 'flows-intake-pause',
  templateUrl: './intake-pause.component.html',
  styleUrls: ['./intake-pause.component.scss']
})
export class IntakePauseComponent extends IntakeStepComponent implements OnInit, OnDestroy {
  dynamicBodyText: string[] = [];
  dynamicHeaderText: string;
  infoText: string;
  imageUrl: string;
  imageAlignment: string;
  hasImage: boolean;

  irisButtonTypes = IrisButtonTypes;

  constructor(
    private configurationService: ConfigurationService<IFlowsAppConfig>,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.initImageVars();
    this.valid.next(true);

    // MER-138
    signalFlowTermination(this.wizard);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initImageVars(): void {
    this.hasImage = !!this.config.imagePath;
    this.imageUrl = this.configurationService.env.urlBase + this.config.imagePath;
    this.imageAlignment = this.config.imageAlignment ?? 'center';
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
