import { Component, OnInit } from '@angular/core';

import { StepItemConfig } from '@sondermind/utilities/models-flows';

import { WizardService } from '@sondermind/flows-host';
import { IntakeStepComponent } from '../intake-step.component';

export interface StaticContentConfig extends StepItemConfig {
  content?: string;
}

@Component({
  selector: 'flows-intake-static-content',
  templateUrl: './intake-static-content.component.html',
  styleUrls: ['./intake-static-content.component.scss']
})
export class IntakeStaticContentComponent extends IntakeStepComponent<StaticContentConfig> implements OnInit {
  constructor(
    wizard: WizardService
  ) {
    super(wizard);
  }

  get hasContext(): boolean {
    return !!this.wizard.context;
  }

  get staticContent(): string {
    let { content } = this.config;
    if (this.hasContext) {
      content = this.wizard.context;
    }

    return content;
  }

  onSubmit() {
    return [];
  }

  ngOnInit() {
    this.valid.next(!!this.component.next);
  }
}
