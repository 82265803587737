import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigurationService } from '@sondermind/configuration';
import { HttpService } from '@sondermind/http';
import { IAPIListRequestOptions, IAPIListResponse } from '@sondermind/utilities/models-http';

import { ReferenceDatum, IApiReferenceDatum } from '../models/reference-datum';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataHttpService {
  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  list(type: string, opts: Partial<IAPIListRequestOptions> = {}): Observable<IAPIListResponse<ReferenceDatum>> {
    const url = `${this.configurationService.env.svcBase}/core/reference_data/${type}`;
    const params = this.http.generateListParams(opts);

    return this.http
      .get<IAPIListResponse<IApiReferenceDatum>>(url, params)
      .pipe(
        map((resp) => ({ ...resp, data: resp.data.map(ReferenceDatum.fromJson) })),
        this.http.handleHttpErrorStrict()
      );
  }
}
