import { Component, OnInit } from '@angular/core';

import { ConfigurationService } from '@sondermind/configuration';
import { ResponseUpdateItem, StepItemConfig } from '@sondermind/utilities/models-flows';

import { InfoLinks } from '@sondermind/core/enums/info-links.enum';

import { WizardService } from '@sondermind/flows-host';
import { IntakeStepComponent } from '../intake-step.component';

@Component({
  selector: 'intake-contemplation',
  templateUrl: './contemplation-page.component.html',
  styleUrls: ['./contemplation-page.component.scss']
})
export class ContemplationPageComponent extends IntakeStepComponent<StepItemConfig> implements OnInit {
  infoLinks = InfoLinks;

  constructor(
    public configurationService: ConfigurationService,
    wizard: WizardService,
  ) {
    super(wizard);
  }

  ngOnInit(): void {
    this.valid.next(true);
  }

  onSubmit(): ResponseUpdateItem[] {
    return [];
  }
}
