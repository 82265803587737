import { Component, Input, ContentChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { MatLegacyInput } from '@angular/material/legacy-input';

@Component({
  selector: 'sonder-option-checkbox',
  templateUrl: './option-checkbox.component.html',
  styleUrls: ['./option-checkbox.component.scss']
})
export class OptionCheckboxComponent {
  @Input() label: SafeHtml;
  @ContentChild(MatLegacyInput) input: MatLegacyInput;
}
