/*
Import the google.maps type declarations into this file to use the google namespace;
This reference directive avoids having to import `google.maps` into every tsconfig.app.json file.
*/
/// <reference types="google.maps" />

import { DOCUMENT } from '@angular/common';
import {
  Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnInit, Output, Renderer2
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import PlaceResult = google.maps.places.PlaceResult;

/**
 * This is an in-house built Angular directive for connecting
 * an input element to Google Map's autocomplete API for places (address search).
 * *(This is an alternative to depending on the `agm` &
 * `@angular-material-extensions/google-maps-autocomplete` packages).*
 *
 * **Usage:**
 *
 * Installing this directive on an input element
 * will dynamically load the google maps places API script
 * and attach an autocomplete widget to that input element.
 *
 * When an autocomplete suggestion is selected this directive
 * will emit it through the `onAutoCompleteSelected` event emitter.
 */
@Directive({
  selector: '[googleMapsAutoComplete]'
})
export class GoogleMapsAutoCompleteDirective implements OnInit {
  private destroy$ = new Subject<void>();

  readonly GOOGLE_MAPS_API_KEY: string = 'AIzaSyDVsgpQY7cbGc9l-yrSpB0arYTXuYhRSzI';
  readonly GOOGLE_MAPS_API_SCRIPT_ID: string = 'googleMapsAPIScript';
  readonly GOOGLE_MAPS_API_SCRIPT_LOADED_CALLBACK_NAME: string = 'googleMapsAPIScriptLoaded';

  @Output()
    onAutoCompleteSelected: EventEmitter<PlaceResult> = new EventEmitter<PlaceResult>();

  @Input() autocompleteParams = {
    componentRestrictions: {
      country: 'us'
    },
    types: ['(regions)']
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderService: Renderer2,
    private hostElementRef: ElementRef,
    private hostFormControlRef: NgControl,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    const googleMapsAPILoader$ = this.getGoogleMapsAPIScriptLoader$();

    googleMapsAPILoader$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => this.attachGoogleMapsAutoCompleteToHostInput()
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getGoogleMapsAPIScriptLoader$(): Observable<void> {
    return new Observable(
      (subscriber) => {
        /* If the google maps API script already exists in the DOM
        then it has already been loaded; we are done. */
        if (this.document.getElementById(this.GOOGLE_MAPS_API_SCRIPT_ID)) {
          subscriber.next();
          subscriber.complete();
          return;
        }

        try {
          /* Build a script element to inject in the DOM which will asynchronously load the google maps API script.
          (See https://developers.google.com/maps/documentation/javascript/overview#Dynamic for reference). */
          const googleMapsAPIScript = this.renderService.createElement('script') as HTMLScriptElement;
          googleMapsAPIScript.type = 'text/javascript';
          googleMapsAPIScript.async = true;
          googleMapsAPIScript.defer = true;
          googleMapsAPIScript.id = this.GOOGLE_MAPS_API_SCRIPT_ID;
          googleMapsAPIScript.src = `https://maps.googleapis.com/maps/api/js?`
              + `key=${this.GOOGLE_MAPS_API_KEY}`
              + `&libraries=places`
              /* The `v` parameter specifies the google maps API version release cadence to use.
              This was set to quarterly by default in the AGM package, so we keep it that way for consistency.
              (See https://developers.google.com/maps/documentation/javascript/versions for reference). */
              + `&v=quarterly`
              + `&callback=${this.GOOGLE_MAPS_API_SCRIPT_LOADED_CALLBACK_NAME}`;

          /* If the script's `onerror` callback executes then we know an error occurred
          and our script did not load successfully; emit an error on the observable. */
          googleMapsAPIScript.onerror = (err) => subscriber.error(err);

          /* This callback will be executed by the google maps API script once it successfully finishes loading. */
          window[this.GOOGLE_MAPS_API_SCRIPT_LOADED_CALLBACK_NAME] = () => {
            subscriber.next();
            subscriber.complete();
          };

          this.renderService.appendChild(this.document.head, googleMapsAPIScript);
        } catch (err) {
          subscriber.error(err);
        }
      }
    );
  }

  // Recursively get the HtmlInputElement for google places to attach to.
  private getNativeInputElement(el: HTMLElement): HTMLInputElement | undefined {
    if (el instanceof HTMLInputElement) {
      return el;
    }
    if (el?.children?.length > 0) {
      return this.getNativeInputElement(el.children[0] as HTMLElement);
    }
    return undefined;
  }
  private attachGoogleMapsAutoCompleteToHostInput(): void {
    const inputElement = this.getNativeInputElement(this.hostElementRef.nativeElement);
    if (!inputElement) { return }

    const autoComplete = new google.maps.places.Autocomplete(
      inputElement,
      this.autocompleteParams
    );

    autoComplete.addListener('place_changed', () => this.ngZone.run(() => {
      /* force the host form control to refresh its model
        with the new value loaded in the textbox by the autocomplete */
      this.hostFormControlRef.control.setValue(
        (this.hostElementRef.nativeElement as HTMLInputElement).value
      );

      const place: PlaceResult = autoComplete.getPlace();
      this.onAutoCompleteSelected.emit(place);
    }));
  }
}
