<div *ngIf="formItem">
  <form [formGroup]="formGroup">
    <iris-form-field
      [label]="formItem.label"
      [helperText]="formItem.helperText"
    >
      <iris-text-input
        *ngIf="formItem.type === 'geolocation'"
        data-test="input-field"
        [formControl]="addressFormControl"
        googleMapsAutoComplete
        [placeholder]="formItem.placeholder"
        (onAutoCompleteSelected)="locationSelected($event)"
        data-hj-suppress
      >
      </iris-text-input>
      <iris-text-input
        *ngIf="formItem.type === 'geolocationStreetAddr'"
        data-test="input-field"
        [formControl]="addressFormControl"
        googleMapsAutoComplete
        [autocompleteParams]="{ componentRestrictions: { country: 'us' }, types: ['address'] }"
        [placeholder]="formItem.placeholder"
        (onAutoCompleteSelected)="locationSelected($event)"
        data-hj-suppress
      >
      </iris-text-input>
    </iris-form-field>
  </form>
  <div
    *ngIf="component.slug === 'inPersonFollowUp'"
    class="alert"
  >
    <mat-icon>info</mat-icon>
    <p>
      We'll do our best to match you with someone offering in-person sessions.
      If there isn't anyone available in your area, we'll match you with a therapist offering
      video sessions.
    </p>
  </div>
</div>
