import {
  Directive, ElementRef, NgZone, OnDestroy
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, throttleTime, distinctUntilChanged } from 'rxjs/operators';
import PlatformResizeObserver from 'resize-observer-polyfill';

// the dom rect returned by the polyfill doesn't include a toJSON method,
// nor does it export the type it returns, so we have to make our own
type PolyfilledDOMRect = Pick<DOMRectReadOnly, Exclude<keyof DOMRectReadOnly, 'toJSON'>>;

// the interface we export for the directive
export interface INgResizeObserver {
  rect$: Observable<PolyfilledDOMRect>;
  width$: Observable<number>;
  height$: Observable<number>;
}

///
// directive to attach a resize observer to the decorated element,
// which can be used to watch the content rect of the element change over time via observables
// runs the observations outside the angular zone for performance; bring back in in the subscription
//
@Directive({
  selector: '[sondermindResizeObserver]',
  exportAs: 'resizeObserver',
})
export class ResizeObserverDirective implements OnDestroy, INgResizeObserver {
  private observer!: PlatformResizeObserver;
  private rectSubj = new ReplaySubject<PolyfilledDOMRect>(1);

  constructor(
    el: ElementRef,
    zone: NgZone,
  ) {
    zone.runOutsideAngular(() => {
      this.observer = new PlatformResizeObserver(([event]) => this.rectSubj.next(event.contentRect));
      this.observer.observe(el.nativeElement);
    });
  }

  rect$ = this.rectSubj.pipe(throttleTime(250));
  width$ = this.rect$.pipe(map((r) => r.width));
  height$ = this.rectSubj.asObservable().pipe(map((r) => r.height), distinctUntilChanged());

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
