import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FlowResponse, StepItem } from '@sondermind/utilities/models-flows';

@Component({
  selector: 'flows-intake-hero',
  templateUrl: './intake-hero.component.html',
  styleUrls: [
    './intake-hero.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntakeHeroComponent {
  @Input() component: StepItem;
  @Input() response: FlowResponse;

  showOptionalTextSlugs = new Set<string>([
    'desired-availability'
  ]);
}
