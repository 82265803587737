<div>
  <div *ngIf="hasImage"
    class="pause-image"
    [style.text-align]="imageAlignment"
    data-test="pause-image"
  >
    <img
      alt="pause image"
      [src]="imageUrl"
    >
  </div>
  <ng-container *ngIf="this.config.pauseHeaderText || this.config.pauseBodyText">
    <h2 class="pause-page-header" [ngClass]="this.config.pauseHeaderClass" data-test="pause-page-text">{{ this.config.pauseHeaderText | bindTemplate:response.data }}</h2>
    <div
      class="pause-page-body"
      [innerHTML]="this.config.pauseBodyText | bindTemplate:response.data"
    ></div>
    <iris-button-container
      class="iris-mt-07 button-container"
      *ngIf="this.config.pauseButtonText"
    >
      <a
        class="iris-button iris-button--primary"
        *ngIf="this.config.pauseButtonLink"
        target="_blank"
        [href]="this.config.pauseButtonLink"
      >
        {{this.config.pauseButtonText}}
      </a>
    </iris-button-container>
  </ng-container>
</div>
