import { Injectable } from '@angular/core';
import { BrowserButtonEvent, NavigationDirection, StateFieldNames } from './enums';

type State = {
  [StateFieldNames.STEP_SLUG]: string;
  [StateFieldNames.TIMESTAMP]: number;
};

@Injectable()
export class BrowserNavButtonsService {
  private currentStepSlug: string = '';
  private visitedStepSlugs: string[] = [];

  /**
   * flag to indicate processing navigation triggered via browser button
   */
  private isNavigationBBInitiated = false;
  private isHistoryAdjustment = false;

  private emitEvent(eventName: BrowserButtonEvent): void {
    window.dispatchEvent(new Event(eventName));
  }

  private processStateChange(state: State): void {
    // sync"ing history, no event processing needed. just reset flag
    if (this.isHistoryAdjustment) {
      this.isHistoryAdjustment = false;
      return;
    }

    if (!state) return;

    // make sure we"ve got current value
    if (!this.currentStepSlug) return;

    // this may happen if user previously clicked on the flow"s "back" button
    if (state[StateFieldNames.STEP_SLUG] === this.currentStepSlug) return;

    const stateStepSlug = state[StateFieldNames.STEP_SLUG];

    const currentIndex = this.visitedStepSlugs.indexOf(this.currentStepSlug);
    const incomingIndex = this.visitedStepSlugs.indexOf(stateStepSlug);

    if (incomingIndex < currentIndex) {
      this.isNavigationBBInitiated = true;
      this.emitEvent(BrowserButtonEvent.BACK);
    }

    if (incomingIndex > currentIndex) {
      this.isNavigationBBInitiated = true;
      this.emitEvent(BrowserButtonEvent.FORWARD);
    }
  }

  private handlePopStateEvent(event: PopStateEvent): void {
    this.processStateChange(event.state);
  }

  private recordFlowsWizardNavigation(stepSlug: string): void {
    const state = {
      [StateFieldNames.STEP_SLUG]: stepSlug,
      [StateFieldNames.TIMESTAMP]: Date.now()
    };
    window.history.pushState(state, '');
  }

  init(): void {
    // using .bind to make sure correct "this" context is available in the handler
    // also, using named handler to avoid possible duplicate subscriptions (with an anonymous handler)
    window.addEventListener('popstate', this.handlePopStateEvent.bind(this));
  }

  destroy(): void {
    window.removeEventListener('popstate', this.handlePopStateEvent.bind(this));
  }

  recordFlowsWizardStart(stepSlug: string): void {
    this.currentStepSlug = stepSlug;

    this.visitedStepSlugs = [];
    this.visitedStepSlugs.push(stepSlug);

    const state = {
      [StateFieldNames.STEP_SLUG]: stepSlug,
      [StateFieldNames.TIMESTAMP]: Date.now()
    };
    window.history.replaceState(state, '');
  }

  processFlowsWizardNavigation(stepSlug: string, direction: NavigationDirection): void {
    this.currentStepSlug = stepSlug;

    // are we are processing browser button navigation?
    if (this.isNavigationBBInitiated) {
      // reset flag
      this.isNavigationBBInitiated = false;
      return;
    }

    // record new step, if needed, and navigate history to match step
    if (direction === NavigationDirection.NEXT) {
      if (this.visitedStepSlugs.includes(stepSlug)) {
        this.isHistoryAdjustment = true;
        window.history.forward();
      } else {
        this.recordFlowsWizardNavigation(stepSlug);
        this.visitedStepSlugs.push(stepSlug);
      }
    } else {
      this.isHistoryAdjustment = true;
      window.history.back();
    }
  }
}
