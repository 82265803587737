<header
  sondermindMediaQueryAttribute
  class="header-container"
>
  <iris-icon-button
    [style.visibility]="back && !component.config.fullscreen ? 'visible': 'hidden'"
    (click)="backClicked.emit()"
    icon="arrow_back"
    ariaLabel="Go back to previous page"
    [type]="irisIconButtonTypes.TERTIARY"
    class="back-button-icon"
    data-test="back-carrot"
  >
  </iris-icon-button>
  <div class="logo-container">
    <a [attr.href]="logoLink">
      <img
        fxHide
        fxShow.gt-sm
        [src]="urlBase + '/assets/sm-logos/logotype-lake.svg'"
        alt="SonderMind Logo Lake Color"
      />
      <img
        fxHide
        fxShow.lt-md
        [src]="urlBase + '/assets/sm-logos/logobug-lake.svg'"
        alt="SonderMind Logo Lake Color"
      />
    </a>
  </div>
  <div class="header-right-back-button">&nbsp;</div>

</header>
