import { Directive, ElementRef, OnDestroy } from '@angular/core';
import {
  Subscription, fromEvent, merge, animationFrameScheduler
} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[sondermindAppHeight]'
})
export class AppHeightDirective implements OnDestroy {
  private sub: Subscription;

  constructor(
    private element: ElementRef<HTMLElement>
  ) {
    // watch both resize and orientationchange
    // and update the height when they change
    // debounce on animationFrameScheduler to
    // force repaints on animation frame

    this.sub = merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'orientationchange')
    ).pipe(
      debounceTime(5, animationFrameScheduler)
    ).subscribe(() => {
      this.updateHeight();
    });

    // set the initial value asap
    animationFrameScheduler.schedule(() => {
      this.updateHeight();
    });
  }

  private updateHeight() {
    const vh = window.innerHeight * 0.01;
    this.element.nativeElement.style.setProperty('--vh', `${vh}px`);
    this.element.nativeElement.style.setProperty('--vh100', `${vh * 100}px`);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
