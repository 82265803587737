import { Component } from '@angular/core';

import { StepItemConfig, ResponseUpdateItem } from '@sondermind/utilities/models-flows';

import { WizardService } from '../../../services/wizard.service';
import { StepComponent } from '../step.component';
import { StepCompleteConfig } from '../../../models/step-complete-config.interface';

export interface ConfirmationConfig extends StepItemConfig {
  journeyItems: Array<{
    icon: string;
    text: string;
    status: string;
  }>;
  headerTitle: string;
  complete: StepCompleteConfig;
}

@Component({
  selector: 'flows-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent extends StepComponent<ConfirmationConfig> {
  isConfirmationView?: boolean = true;

  constructor(
    wizard: WizardService
  ) {
    super(wizard);
  }

  onSubmit(): ResponseUpdateItem[] {
    throw new Error('no submission from confirmation!');
  }

  handleExit() {
    this.wizard.complete().subscribe(() => {
      // eslint-disable-next-line prefer-destructuring
      const url = this.config.url;
      window.location.assign(url);
    });
  }

  handleNext() {
    this.isConfirmationView = false;
  }
}
