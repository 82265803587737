import {
  Component, OnInit, OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, startWith, takeUntil } from 'rxjs';

import { MultiSelectImageItem } from '@sondermind/flows-host';
import { ResponseUpdateItem } from '@sondermind/utilities/models-flows';

import { TextareaSizes } from '@sondermindorg/iris-design-system-angular';
import { IntakeStepComponent } from '../intake-step.component';
import { IDropdownConfig } from '../basic-form/model';

@Component({
  selector: 'flows-intake-heard-from',
  templateUrl: './heard-from.component.html',
  styleUrls: ['./heard-from.component.scss']
})
export class IntakeHeardFromComponent extends IntakeStepComponent<IDropdownConfig> implements OnInit, OnDestroy {
  textareaSizes = TextareaSizes;
  options: MultiSelectImageItem[] = [];

  shouldShowFreeText = false;
  freeTextHeading = '';

  selectFormGroup: FormGroup = new FormGroup({
    singleSelect: new FormControl('', Validators.required),
    freeText: new FormControl('', Validators.required)
  });

  ngOnInit(): void {
    this.initOptions();
    this.initExistingData();

    combineLatest({
      newSelectVal: this.selectFormGroup.controls.singleSelect.valueChanges.pipe(
        startWith(this.selectFormGroup.controls.singleSelect.value)
      ),
      newFreeTextVal: this.selectFormGroup.controls.freeText.valueChanges.pipe(
        startWith(this.selectFormGroup.controls.freeText.value)
      )
    }).pipe(
      takeUntil(this.destroyed$),
    ).subscribe((results) => {
      const selectedValue = results.newSelectVal as string;
      const freeText = results.newFreeTextVal as string;

      // display updates
      if (this.hasFreeText(selectedValue)) {
        this.freeTextHeading = this.getFreeTextHeading(selectedValue);
        this.shouldShowFreeText = true;
      } else {
        this.shouldShowFreeText = false;
      }

      // validation
      if (!selectedValue?.length) this.valid.next(false);
      else if (selectedValue !== 'other') this.valid.next(true);
      else if (freeText.length > 0) this.valid.next(true);
      else this.valid.next(false);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  initOptions(): void {
    interface IStepContext {
      options: Array<{ label: string; value: string; }>;
    }
    const context = this.wizard.context as IStepContext;
    this.options = context.options.map((x) => ({ ...x, name: x.label }));

    // We add an "other" option at the end
    this.options.push({
      label: 'Other',
      name: 'Other',
      value: 'other',
    });
  }

  initExistingData(): void {
    this.selectFormGroup.patchValue({
      singleSelect: this.data['referralSource'] as string ?? '',
      freeText: this.data['referralSourceText'] as string ?? ''
    });
  }

  onSubmit(): ResponseUpdateItem[] {
    const formControls = this.selectFormGroup.controls;
    const referralSource = formControls.singleSelect.value as string;
    const referralSourceText = formControls.freeText.value as string;

    return [
      {
        human: 'referralSource',
        key: 'referralSource',
        value: referralSource
      },
      {
        human: 'Other',
        key: 'referralSourceText',
        value: this.hasFreeText(referralSource) ? referralSourceText : ''
      }
    ];
  }

  private hasFreeText(item: string): boolean {
    return ['other', 'podcast', 'tiktok', 'facebook', 'youtube', 'instagram'].includes(item);
  }

  private getFreeTextHeading(item: string): string {
    if (item === 'other') return 'Please enter how you heard about us. *';
    if (item === 'podcast') return 'Which podcast?';
    return 'If you heard about us from someone you follow, enter their name here.';
  }
}
