import { PipeTransform, Pipe } from '@angular/core';
import { IBasicFormItem } from '@sondermind/utilities/models-flows';

@Pipe({
  name: 'requiredField'
})
export class RequiredFieldPipe implements PipeTransform {
  /**
   * Need to to check explicitly for false, as undefined/null defaults to required=true.
   */
  transform(item: IBasicFormItem): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    return item?.required !== false;
  }
}
