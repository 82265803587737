import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { HttpService } from '@sondermind/http';
import {
  IAPIListRequestOptions,
  IAPIListResponse,
  IAPIServiceRequestOptions
} from '@sondermind/utilities/models-http';
import {
  ITherapistTemplate,
  ITherapistTemplateApiResponse,
  parseTherapistTemplate
} from '@sondermind/utilities/models-therapist';
import { ConfigurationService } from '@sondermind/configuration';

@Injectable({
  providedIn: 'root'
})
export class TherapistHttpService {
  urlBase: string = `${this.configurationService.env.svcBase}/referrals/providers`;

  allTherapists$?: Observable<ITherapistTemplate[]>;

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpService
  ) {}

  /**
   * Retrieve all therapists associated with a client user.
   */
  listAll(opts: Partial<IAPIListRequestOptions> = {}): Observable<ITherapistTemplate[]> {
    const params = this.http.generateListParams(opts);

    this.allTherapists$ ??= this.http
      .get<IAPIListResponse<ITherapistTemplateApiResponse>>(this.urlBase, params)
      .pipe(
        map((resp) => resp.data.map((t) => parseTherapistTemplate(t))),
        this.http.handleHttpError(opts.error),
        share()
      );

    return this.allTherapists$;
  }

  /**
   * Mark that the client has sent an NCC message to a specific provider.
   */
  clientSentNcc(personaProviderId: number, options: Partial<IAPIServiceRequestOptions> = {}): Observable<void> {
    const url = `${this.configurationService.env.svcBase}/referrals/providers/${personaProviderId}/client_sent_ncc`;
    const params = this.http.generateServiceParams(options);

    return this.http
      .put(url, params)
      .pipe(
        this.http.handleHttpError(options.error),
        map(() => undefined)
      );
  }
}
